import axios from "axios";

import {SESSION_STORAGE_KEYS, USER_ROLES} from "../../constants/enums";
import {parseRestApiResponse} from "../../helpers/helpers";

export const SET_APP_FIRST_OPEN = 'SET_APP_FIRST_OPEN';
export const SET_CREDENTIALS = 'SET_CREDENTIALS';
export const SET_USER_DATA = 'SET_USER_DATA';
export const REMEMBER_ME = 'REMEMBER_ME';
export const REMEMBER_ME_CREDENTIALS = 'REMEMBER_ME_CREDENTIALS';
export const SET_USER_TOKEN = 'SET_USER_TOKEN';
export const L10N = 'L10N';

//const URL_HOSTNAME = 'https://domena.si/3001';
const URL_HOSTNAME = '';
const REST_API_ENDPOINT = '/rest'
export const BE_REST_API = URL_HOSTNAME + REST_API_ENDPOINT;


export const setUserToken = (token) => {
    return {type: SET_USER_TOKEN, token: token};
};

export const setUserData = (user) => {
    return {type: SET_USER_DATA, user: user};
};

export const setCredentials = (email, password) => {
    return {type: SET_CREDENTIALS, email: email, password: password};
};

export const setRememberMe = (state) => {
    saveRememberMe(state);
    return {type: REMEMBER_ME, rememberMe: state};
};

export const setSignInCredentials = (email, password, shouldWrite) => {
    if (shouldWrite) {
        saveCredentials(email, password);
    }
    return {type: REMEMBER_ME_CREDENTIALS, email: email, password: password};
};

export const setL10N = (l10n, shouldWrite) => {
    if (shouldWrite) {
        setLocalL10N(l10n);
    }
    return {type: L10N, l10n: l10n};
};

export const setLocalL10N = (l10n) => {
    //console.log(`SET [SESSION STORAGE] ${SESSION_STORAGE_KEYS.L10N}:`, JSON.parse(l10n));

    const onSetSessionAction = () => {
        localStorage.setItem(SESSION_STORAGE_KEYS.L10N, l10n);
        console.log(`SET [LOCAL STORAGE] ${SESSION_STORAGE_KEYS.L10N}:`, l10n);
    };
    setSessionStorageData(onSetSessionAction);
};

export const loadLocalL10N = (useDispatch) => {
    if (useDispatch) {
        console.log("sadasdas");
        return async dispatch => {
            const onLoadSessionAction = (value) => {
                console.log(value);
                if (value) {
                    dispatch(setL10N(value, true));
                }
            };
            loadLocalStorageData(SESSION_STORAGE_KEYS.L10N, (value) => onLoadSessionAction(JSON.parse(JSON.stringify(value))));
        };
    } else {
        return loadLocalStorageData(SESSION_STORAGE_KEYS.L10N);
    }
};


export const loadCredentials = () => {
    return async dispatch => {
        try {
            const email = localStorage.getItem(SESSION_STORAGE_KEYS.REMEMBER_EMAIL);
            const pass = localStorage.getItem(SESSION_STORAGE_KEYS.REMEMBER_PASSWORD);
            console.log("LOAD [STORAGE] REMEMBER ME CREDENTIALS:", email, pass);
            if (email && pass) {
                dispatch(setSignInCredentials(email, pass));
            }
        } catch (error) {
            console.log("Error getting data! Data key does not yet exist" + error);
        }
    };
};

export const saveCredentials = (email, password) => {
    try {
        localStorage.setItem(SESSION_STORAGE_KEYS.REMEMBER_EMAIL, email);
        localStorage.setItem(SESSION_STORAGE_KEYS.REMEMBER_PASSWORD, password);
        console.log("SAVE [STORAGE] REMEMBER ME CREDENTIALS:", email, password);
    } catch (error) {
        throw Error("Error saving data!" + error);
    }
};

export const isRememberMeEnabled = () => {
    return async dispatch => {
        try {
            const value = await localStorage.getItem(SESSION_STORAGE_KEYS.REMEMBER);
            console.log("LOAD [STORAGE] REMEMBER ME:", value);
            if (!value || Number(value) === 0) {
                dispatch(setRememberMe(false));
            } else {
                dispatch(setRememberMe(true));
            }
        } catch (error) {
            console.log("Error getting data! Data key does not yet exist" + error);
        }
    };
};

export const saveRememberMe = (state) => {
    try {
        localStorage.setItem(SESSION_STORAGE_KEYS.REMEMBER, state ? '1' : '0');
        console.log("SAVE [STORAGE]  REMEMBER ME:", state);
    } catch (error) {
        throw Error("Error saving data!" + error);
    }
};

export const setSessionStorageData = (onSetSessionAction) => {
    try {
        onSetSessionAction();
    } catch (error) {
        console.error("Error saving data!" + error);
    }
};

export const loadSessionStorageData = (key, onLoadSessionAction) => {
    try {
        const value = sessionStorage.getItem(key);
        console.log(`GET [SESSION STORAGE] ${key}:`, value);
        if (onLoadSessionAction) {
            onLoadSessionAction(value);
        }
        try {
            return JSON.parse(value);
        } catch (e) {
            return value
        }
    } catch (error) {
        console.error("Error saving data!" + error);
        return null;
    }
};

export const loadLocalStorageData = (key, onLoadSessionAction) => {
    try {
        const value = localStorage.getItem(key);
        console.log(`GET [LOCAL STORAGE] ${key}:`, value);
        if (onLoadSessionAction) {
            onLoadSessionAction(value);
        }
        try {
            return JSON.parse(value);
        } catch (e) {
            return value
        }
    } catch (error) {
        console.error("Error saving data!" + error);
        return null;
    }
};

export const setSessionUser = (user) => {
    const onSetSessionAction = () => {
        sessionStorage.setItem(SESSION_STORAGE_KEYS.USER, user);
        console.log(`SET [SESSION STORAGE] ${SESSION_STORAGE_KEYS.USER}:`, JSON.parse(user));
    };
    setSessionStorageData(onSetSessionAction);
};

export const loadSessionUser = (key, useDispatch) => {
    if (useDispatch) {
        return async dispatch => {
            const onLoadSessionAction = (value) => {
                if (value) {
                    dispatch(setUserData(value));
                }
            };
            loadSessionStorageData(key, (value) => onLoadSessionAction(JSON.parse(JSON.stringify(value))));
        };
    } else {
        return loadSessionStorageData(key);
    }
};

export const setSessionUserToken = (token) => {
    const onSetSessionAction = () => {
        sessionStorage.setItem(SESSION_STORAGE_KEYS.USER_TOKEN, token);
        console.log(`SET [SESSION STORAGE] ${SESSION_STORAGE_KEYS.USER_TOKEN}:`, token);
    };
    setSessionStorageData(onSetSessionAction);
};

export const loadSessionUserToken = (key, useDispatch) => {
    if (useDispatch) {
        return async dispatch => {
            const onLoadSessionAction = (value) => {
                if (value) {
                    dispatch(setUserToken(value));
                }
            };
            loadSessionStorageData(key, (value) => onLoadSessionAction(JSON.parse(JSON.stringify(value))));
        };
    } else {
        return loadSessionStorageData(key);
    }
};


export const userLogin = (email, password, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            email: email,
            password: password,
        }
        console.log(postdata);
        postdata = JSON.stringify(postdata);
        console.log(postdata);
        try {
            let uri = BE_REST_API + '/users/login';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                    postData: postdata
                },
            });
            const resData = await response.json();
            console.log("GET USER LOGIN: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                setSessionUser(JSON.stringify(resData));
                setSessionUserToken(resData["user_id"]);

                dispatch(setCredentials(email, password));
                dispatch(setUserData(resData));
                dispatch(setUserToken(resData["user_id"]));
            }
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), () => onErrorErrorFetchingData(resData), onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed loading user..', e);
        }
    };
};


export const userRegister = (email, name, l10n, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            email: email,
            name: name,
            l10n: l10n
        }
        postdata = JSON.stringify(postdata);
        console.log(postdata);
        try {
            let uri = BE_REST_API + '/users/create';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            console.log("REGISTER NEW USER: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                setSessionUser(JSON.stringify(resData));
                setSessionUserToken(resData["user_id"]);

                //  dispatch(setCredentials(email, password));
                dispatch(setUserData(resData));
                dispatch(setUserToken(resData["user_id"]));

            }
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), () => onErrorErrorFetchingData(resData), onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed registering new user..', e);
        }
    };
};


export const updateUserData = (user_id, name, username, referral_id, store_locale, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            user_id: user_id,
            name: name,
            username: username,
            referral_id: referral_id,
            store_locale: store_locale
        }
        postdata = JSON.stringify(postdata);
        console.log(postdata);
        try {
            let uri = BE_REST_API + '/users/update/data';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            console.log("UPDATE USER DATA: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                setSessionUser(JSON.stringify(resData));
                setSessionUserToken(resData["user_id"]);

                dispatch(setUserData(resData));
                dispatch(setUserToken(resData["user_id"]));

            }
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), () => onErrorErrorFetchingData(resData), onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed registering new user..', e);
        }
    };
};

export const updateUserPassword = (user_id, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            user_id: user_id,
        }
        postdata = JSON.stringify(postdata);
        console.log(postdata);
        try {
            let uri = BE_REST_API + '/users/update/password';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            console.log("UPDATE USER PASSWORD: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                setSessionUser(JSON.stringify(resData));
                setSessionUserToken(resData["user_id"]);

                dispatch(setUserData(resData));
                dispatch(setUserToken(resData["user_id"]));

            }
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), () => onErrorErrorFetchingData(resData), onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed updating users password..', e);
        }
    };
};

export const updateUserPasswordByEmail = (email, l10n, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            username: email,
            l10n: l10n
        }
        postdata = JSON.stringify(postdata);
        console.log(postdata);
        try {
            let uri = BE_REST_API + '/users/reset/password/';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            console.log("RESET USER PASSWORD: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                setSessionUser(JSON.stringify(resData));
                setSessionUserToken(resData["user_id"]);

                dispatch(setUserData(resData));
                dispatch(setUserToken(resData["user_id"]));

            }
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), () => onErrorErrorFetchingData(resData), onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed updating users password..', e);
        }
    };
};

export const createNewSurveyCompleted = (referral_id, l10n, shared_back, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            referral_id: referral_id,
            l10n: l10n,
            shared_back: shared_back
        }
        postdata = JSON.stringify(postdata);
        console.log(postdata);
        try {
            let uri = BE_REST_API + '/stats/create/';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            console.log("COLLECT SURVEY COMPLETED (STAT): " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
            }
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), () => onErrorErrorFetchingData(resData), onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed creating new survey completed stat..', e);
        }
    };
};


export const getUserByReferralId = (referral_id, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            referral_id: referral_id,
        }
        postdata = JSON.stringify(postdata);

        try {
            let uri = BE_REST_API + '/users/user/';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            console.log("GET USER BY REFERRAL ID: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
            }
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), () => onErrorErrorFetchingData(resData), onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed getting user..', e);
        }
    };
};

export const getStatsForUser = (user_id, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            user_id: user_id,
        }
        postdata = JSON.stringify(postdata);

        try {
            let uri = BE_REST_API + '/stats/user/';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            console.log("GET STATS FOR USER: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
            }
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), () => onErrorErrorFetchingData(resData), onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed creating new survey completed stat..', e);
        }
    };
};

export const sendEmailToNewPublicUser = (email, send_to_admin, referral_id, result_url, critical_system, l10n, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            email: email,
            send_to_admin: send_to_admin,
            referral_id: referral_id,
            result_url: result_url,
            critical_system: critical_system,
            l10n: l10n
        }
        postdata = JSON.stringify(postdata);
        console.log(postdata);
        try {
            let uri = BE_REST_API + '/users/result/send';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            console.log("SEND PUBLIC USER RESULT: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
            }
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), () => onErrorErrorFetchingData(resData), onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed loading user..', e);
        }
    };
};
