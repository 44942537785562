import {
    LOCAL_STORAGE_KEYS,
    USER_ROLES
} from "../constants/enums";
import React from "react";

export const encapsulateStoreReferralIdToUrl = (url, referral) => {
    return url + '?referralID=' + referral;
};

export const validateUserInput = (params) => {
    for (let i = 0; i < params.length; i++) {
        console.log(JSON.stringify(params[i], null, 4))
        if (params[i] === null || params[i] === '') {
            return false;
        }
    }
    return true;
};

export const validateUserInputNumerical = (params) => {
    for (let i = 0; i < params.length; i++) {
        let param = parsePostDecimalValue(params[i])
        console.log(JSON.stringify(param, null, 4))
        if (isNaN(param)) {
            return false;
        }
    }
    return true;
};

export const parseRestApiResponse = (response, resData, dispatchAction, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    if (Number(response.status) >= 500) {
        console.log("GET DATA ERROR", response.status, resData.error);
        if (onErrorErrorFetchingData) {
            onErrorErrorFetchingData();
        }
    } else if (Number(response.status) >= 400) {
        console.log("GET DATA ERROR", response.status, resData.error);
        if (onErrorUnauthorized) {
            onErrorUnauthorized();
        } else if (onErrorErrorFetchingData) {
            onErrorErrorFetchingData();
        }
    } else if (Number(response.status) >= 200) {
        //console.log("GET DATA SUCCESS", response.status, resData.error);
        if (dispatchAction) {
            dispatchAction().then(() => {
                if (onSuccessAction) {
                    onSuccessAction();
                }
            });
        }
    }
};

export const displayAlert = (message) => {
    return alert(message);
};

export const hasItem = (field, returnField, value, array) => {
    if (!array) {
        return false
    }

    for (let i = 0; i < array.length; i++) {
        if (array[i][field]?.toString() === value?.toString()) {
            return array[i][returnField];
        }
    }
    return null;
};

export const findItem = (field, value, array) => {
    if (!array) {
        return null
    }
    for (let i = 0; i < array.length; i++) {
        if (array[i][field] === value) {
            return array[i];
        }
    }
    return null;
};

export const getIndexOf = (field, value, array) => {
    if (!array) {
        return -1
    }
    for (let i = 0; i < array.length; i++) {
        if (array[i][field] === value) {
            return i;
        }
    }
    return -1;
};

export const getIndexForNthCharOccurrence = (string, subString, index) => {
    if (!string) {
        return -1
    }
    return string.split(subString, index).join(subString).length;
}

export const parseSelectedObjectValues = (list, searchField) => {
    if (!list || list.length === 0) {
        return null;
    }
    let arr = []
    for (let i = 0; i < list.length; i++) {
        if (list[i][searchField]) {
            arr.push(list[i][searchField]);
        }
    }
    return arr;
}

export const parseUserRole = (userRole) => {
    switch (userRole) {
        case 'ADMIN':
            return USER_ROLES.ADMIN;
        default:
            console.warn(`Error parsing user role... User role '${userRole}' is Ineligible!`);
            return null;
    }
};

export const isSessionValid = (token) => {
    if (!token) {
        console.log('redirect');
        return false;
    }
    return true;
}

export const mapCaretChars = (string) => {
    if (!string) {
        return null;
    }
    let res = string.replace(/[ŽžČčŠšĆćđĐ]/gi, function (c) {
        switch (c) {
            case 'Ž':
                return '_Z';
            case 'ž':
                return '_z';
            case 'Č':
            case 'Ć':
                return '_C';
            case 'č':
            case 'ć':
                return '_c';
            case 'đ':
                return 'd';
            case 'Đ':
                return 'D';
            case 'Š':
                return '_S';
            case 'š':
                return '_s';
        }
    });
    return res;
};

export const parseMapCaretChars = (string) => {
    if (!string) {
        return null;
    }
    let res = string.replace(/_Z|_z|_C|_c|_S|_s/gi, function (c) {
        switch (c) {
            case '_Z':
                return 'Ž';
            case '_z':
                return 'ž';
            case '_C':
                return 'Č';
            case '_c':
                return 'č';
            case '_S':
                return 'Š';
            case '_s':
                return 'š';
        }
    });
    return res;
};


export const displayDecimalValue = (price) => {
    if (price === null || price === undefined) {
        return '';
    }
    return price.toString().replace(".", ",");
}

export const parsePostDecimalValue = (price) => {
    return price.toString().replace(",", ".");
}

export const displayPrice = (price) => {
    return displayDecimalValue(Number(price).toFixed(2));
}

export const isUserAdmin = (user) => {
    return user && parseUserRole(user.type) === USER_ROLES.ADMIN;
}

export const formatDate = (date) => {
    let d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [day, month, year].join('.');
}

export const displayValidFromMenuFormat = (date) => {
    let d = new Date(date);
    let day = d.getDate();
    let month = d.getMonth() + 1;
    let year = d.getFullYear();
    console.log("DATE:", date, `${day}. ${month}. ${year}`)
    return `${day}. ${month}. ${year}`;
};

export const validateEmail = (email) => {
    return email.match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};
