import {
    SET_APP_FIRST_OPEN,
    SET_USER_DATA,
    REMEMBER_ME,
    REMEMBER_ME_CREDENTIALS,
    loadSessionUser,
    SET_USER_TOKEN,
    L10N,
    loadSessionUserToken, SET_CREDENTIALS, loadLocalStorageData,
} from '../actions/actions';
import {SESSION_STORAGE_KEYS} from "../../constants/enums";

const initialState = {
    appFirstOpen: true,
    savedCredentials: {
        email: "",
        password: ""
    },
    loginCredentials: {
        email: "",
        password: ""
    },
    rememberMe: false,
    userToken: loadSessionUserToken(SESSION_STORAGE_KEYS.USER_TOKEN),
    user: loadSessionUser(SESSION_STORAGE_KEYS.USER),
    l10n: loadLocalStorageData(SESSION_STORAGE_KEYS.L10N),
};

const reducer = (state = initialState, action) => {

    switch (action.type) {
        case SET_APP_FIRST_OPEN:
            console.log(`Action ${JSON.stringify(action.type, null, 4)}, value: ${action.appFirstOpen}`);
            return {...state, appFirstOpen: action.appFirstOpen};
        case L10N:
            console.log(`Action ${JSON.stringify(action.type, null, 4)}, value: ${action.l10n}`);
            return {...state, l10n: action.l10n};
        case REMEMBER_ME:
            console.log(`Action ${JSON.stringify(action.type, null, 4)}, value: ${action.rememberMe}`);
            return {...state, rememberMe: action.rememberMe};
        case REMEMBER_ME_CREDENTIALS:
            console.log(`Action ${JSON.stringify(action.type, null, 4)}, saved email: ${JSON.stringify(action.email, null, 4)}, saved password: ${JSON.stringify(action.password, null, 4)}`);
            return {...state, savedCredentials: {email: action.email, password: action.password}};
        case SET_USER_TOKEN:
            console.log(`Action ${JSON.stringify(action.type, null, 4)}, token: ${JSON.stringify(action.token, null, 4)}`);
            return {...state, userToken: action.token};
        case SET_USER_DATA:
            console.log(`Action ${JSON.stringify(action.type, null, 4)}, user: ${JSON.stringify(action.user, null, 4)}`);
            return {...state, user: action.user};
        case SET_CREDENTIALS:
            console.log(`Action ${JSON.stringify(action.type, null, 4)}, email: ${JSON.stringify(action.email, null, 4)},  pass: ${JSON.stringify(action.password, null, 4)}`);
            return {...state, loginCredentials: {email: action.email, password: action.password}};

        default:
            console.log(`Undefined fire action: ${JSON.stringify(action.type, null, 4)}, value: ${JSON.stringify(action, null, 4)}`);
            return state;
    }
};

export default reducer;