import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import {combineReducers, createStore, applyMiddleware} from "redux";
import {Provider, useSelector} from "react-redux";
import ReduxThunk from 'redux-thunk';
import MainReducer from './store/reducers/reducer';

import './App.css';

import Analysis from "./pages/Analysis";
import Results from "./pages/Results";
import Register from "./pages/Register";
import Login from "./pages/Login";
import Home from "./pages/Home";
import ResetPasswordLogin from "./pages/ResetPasswordLogin";

export const PAGES = {
    ANALYSIS: '/analysis',
    RESULT: '/result',
    REGISTER: '/register',
    LOGIN: '/login',
    HOME: '/home',
    RESET_PASSWORD: '/password-reset'
}

function App() {
    const rootReducer = combineReducers({
        reducer: MainReducer
    });
    const store = createStore(rootReducer, applyMiddleware(ReduxThunk));

    return (
        <>
            <Provider store={store}>
                <Router>
                    <Switch>
                        <Route path={'/'} exact component={Login}/>
                        <Route path={PAGES.LOGIN} exact component={Login}/>
                        <Route path={PAGES.ANALYSIS + '/:referralID/:store'} component={Analysis}/>
                        <Route path={PAGES.RESULT + '/:result/:referralID/:store'} component={Results}/>
                        <Route path={PAGES.REGISTER} component={Register}/>
                        <Route path={PAGES.RESET_PASSWORD} component={ResetPasswordLogin}/>
                        <Route path={PAGES.HOME} component={Home}/>
                    </Switch>
                </Router>
            </Provider>
        </>
    );
}

export default App;
