import React, {createRef, useCallback, useEffect, useState} from "react";
import {useSpring, animated} from 'react-spring'
import {useHistory, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

import logo from '../assets/logo.png';

import '../App.css';
import {
    ANSWERS, BIO_SYSTEM_DE_ROOT, BIO_SYSTEM_EN_ROOT,
    BIO_SYSTEM_HR, BIO_SYSTEM_HR_ROOT, BIO_SYSTEM_IT_ROOT,
    getStateHeaderColor,
    L10N_APP_TEXTS,
    PRODUCTS,
    QUESTIONS,
    RESULTS,
    STATE
} from "../data/data";
import {PAGES} from "../App";
import Input from "../components/js/Input";
import {displayAlert, validateEmail} from "../helpers/helpers";
import {createNewSurveyCompleted, sendEmailToNewPublicUser} from "../store/actions/actions";
import Message, {ErrorType} from "../components/js/Message";
import {calculateResultPoints} from "./Results";
import {L10N} from "../constants/enums";

const base64 = require('base-64');

/* NOTE TO MYSELF: USING REACT SPRING ANIMATION LIB FOR FIRST TIME. GLITCHING EFFECT CAN OCCUR ON SETTING ANIMATION TO TOGGLE onRest() => {...}
*
* ALL SOLUTIONS REGARDING THE GLITCH EFFECT WERE NOT SUCCESSFUL: SPRINGS ANIMATION SAVED AS STATE CONSTANTS OR CALLBACKS OR OTHER MEMOIZED SOLUTIONS OR USAGE OF REFS WERE USELESS.
* */

function Analysis() {
    let dispatch = useDispatch();
    const {referralID} = useParams('referralID');
    const {store} = useParams('store');

    const [flipAnimation, setFlipAnimation] = useState(false);
    const [option, setOption] = useState(null);
    const [transitionToListing, setTransitionToListing] = useState(false);

    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);

    const [transitionToResult, setTransitionToResult] = useState(false);
    const [transitionToRenderResultsConditions, setTransitionToRenderResultsConditions] = useState(false);

    const [getReferralId, setReferralId] = useState(referralID ? referralID.split('referralID=')[1] : null);
    const [selectedLanguage, setSelectedLanguage] = useState(store ? store.split('store=')[1] : null);

    const [questions, setQuestions] = useState(selectedLanguage ? QUESTIONS[selectedLanguage] : []);
    const [checkbox, setCheckbox] = useState(false);

    const [email, setEmail] = useState(null);
    const [submit, setSubmit] = useState(false);

    const [error, setError] = useState(null);

    const ulRef = createRef();

    console.log("referralID=", getReferralId);
    console.log("store=", selectedLanguage);

    const scrollToTop = () => {
        let el = document.getElementById('products-list');
        console.log(window.pageYOffset, el);
        if (!el) {
            return;
        }

        window.scrollTo(0, 0);
        /*
                window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                });

         */
    };

    const toggleTransition = (state) => {
        setOption(state);
        setTransitionToListing(state)
        setOption(state);

        setTimeout(() => {
            setTransitionToListing(state)
        }, 200)

        scrollToTop();
    }
    const toggleTransitionChange = (state) => {
        setOption(state);
        scrollToTop();
    }
    const memoizedCallback = useCallback(toggleTransition, []);
    const memoizedCallbackTransition = useCallback(toggleTransitionChange, []);

    const [fadeOutAnimationPage, setFadeOutAnim] = useState(useSpring({
        from: {opacity: 1},
        to: {
            opacity: option ? 0 : 1,
        },
        config: {duration: 200},
    }))

    const [fadeInAnimationPage, setFadeInAnim] = useState(useSpring({
        from: {opacity: 0},
        to: {
            opacity: transitionToListing ? 1 : 0,
        },
        config: {duration: 250},
    }))

    const [slideTopDown, setSlideTopDown] = useState(useSpring(
        {
            from: {transform: 'translateY(-90%)', opacity: 0},
            to: {transform: 'translateY(0)', opacity: 1},
            config: {duration: 700},
            delay: 250,
        }))

    const [fadeIn, setFadeIn] = useState(useSpring(
        {
            from: {opacity: 0},
            to: {opacity: 1},
            config: {duration: 1000},
            delay: 500,
        }))

    const [slideBotUp, setSlideBotUp] = useState(useSpring(
        {
            from: {transform: 'translateY(90%)', opacity: 0},
            to: {transform: 'translateY(0)', opacity: 1},
            config: {duration: 1000},
            delay: 1500,
        }))

    const [rotateAnimationSun, setRoatetSun] = useState(useSpring(
        {
            from: {transform: 'rotateZ(-90deg)'},
            to: {transform: 'rotateZ(10deg)'},
            delay: 1000,
            reset: true,
            reverse: flipAnimation,
            onRest: () => !option && setFlipAnimation(!flipAnimation),
        }))

    const [rotateAnimationUmbrella, setAnimUmbrella] = useState(useSpring(
        {
            from: {transform: 'rotateZ(0deg)'},
            to: {transform: 'rotateZ(-8deg)'},
            delay: 1000,
            reset: true,
            reverse: flipAnimation,
            onRest: () => {/*setFlipAnimation(!flipAnimation)*/
            },
        }))

    const [rotateAnimationTropicDrink, setAnimDrinkTropic] = useState(useSpring(
        {
            from: {transform: 'rotateZ(20deg)'},
            to: {transform: 'rotateZ(0deg)'},
            delay: 1000,
            reset: true,
            reverse: flipAnimation,
            onRest: () => {/*setFlipAnimation(!flipAnimation)*/
            },
        }))

    const [rotateAnimationShell, setAnimShell] = useState(useSpring(
        {
            from: {transform: 'rotateZ(-20deg)'},
            to: {transform: 'rotateZ(0deg)'},
            delay: 1000,
            reset: true,
            reverse: flipAnimation,
            onRest: () => {/*setFlipAnimation(!flipAnimation)*/
            },
        }))
    const [rotateAnimationDrink, setAnimDrink] = useState(useSpring(
        {
            from: {transform: 'rotateZ(10deg)'},
            to: {transform: 'rotateZ(0deg)'},
            delay: 1000,
            reset: true,
            reverse: flipAnimation,
            onRest: () => {/*setFlipAnimation(!flipAnimation)*/
            },
        }))
    const [translateAnimationSail, setAnimSail] = useState(useSpring(
        {
            from: {transform: 'translate(35%, 5%)',},
            to: {transform: 'translate(0, 0)'},
            delay: 1000,
            reset: true,
            reverse: flipAnimation,
            onRest: () => {/*setFlipAnimation(!flipAnimation)*/
            },
        }))
    const [flickerArrow, setFlickerArrow] = useState(useSpring(
        {
            from: {opacity: 1},
            to: {opacity: 0},
            delay: 1000,
            reset: true,
            reverse: flipAnimation,
            onRest: () => {/*setFlipAnimation(!flipAnimation)*/
            },
        }))
    const [flickerArrowDown, setFlickerArrowDown] = useState(useSpring(
        {
            from: {opacity: 0},
            to: {opacity: 1},
            delay: 1000,
            reset: true,
            reverse: flipAnimation,
            onRest: () => {/*setFlipAnimation(!flipAnimation)*/
            },
        }))
    const [arrowsContainer, setArrowsContainer] = useState(useSpring(
        {
            from: {opacity: 0},
            to: {opacity: 1},
            delay: 2000,
            config: {duration: 1000},
        }))


    let history = useHistory();
    const onNavigateTo = useCallback(((path) => {
        history.push(path);
    }), [history]);


    useEffect(() => {
        if (currentQuestionIndex && currentQuestionIndex >= questions.length) {
            setTransitionToRenderResultsConditions(true);
        }
    }, [currentQuestionIndex]);

    useEffect(() => {
        if (transitionToRenderResultsConditions && submit) {
            if (email && email !== '') {

                // email verification, if not, return error message below input
                let emailVerified = validateEmail(email);
                if (!emailVerified) {
                    setSubmit(false);
                    onErrorEmailVerification();
                    return;
                }

                let resUrl = generateEncodedUrl();

                const decodedUrl = base64.decode(resUrl);
                let res = decodedUrl.split('-');

                let resultUrl = generateEncodedResultUrl()

                calculateResultPoints(res, selectedLanguage, (worst, worstSystems, results) => {
                    console.log(worst);

                    let l10nCriticalSystem = worst;
                    if (selectedLanguage === L10N.si.value) {
                        l10nCriticalSystem = worst;
                    } else if (selectedLanguage === L10N.en.value || selectedLanguage === L10N.us.value) {
                        l10nCriticalSystem = BIO_SYSTEM_EN_ROOT[worst];
                    } else if (selectedLanguage === L10N.it.value) {
                        l10nCriticalSystem = BIO_SYSTEM_IT_ROOT[worst];
                    } else if (selectedLanguage === L10N.de.value || selectedLanguage === L10N.at.value) {
                        l10nCriticalSystem = BIO_SYSTEM_DE_ROOT[worst];
                    } else if (selectedLanguage === L10N.hr.value) {
                        l10nCriticalSystem = BIO_SYSTEM_HR_ROOT[worst];
                    }

                    //console.log(PAGES.RESULT + "/" + generateEncodedUrl() + "/" + "referralID=" + getReferralId  + "/" + "store=" + selectedLanguage);
                    //console.log(resUrl);

                    dispatch(sendEmailToNewPublicUser(email, checkbox, getReferralId, resultUrl, l10nCriticalSystem, selectedLanguage, async (res) => {
                        console.log("Email sent!", res);

                        await dispatch(createNewSurveyCompleted(getReferralId, selectedLanguage, checkbox));

                        setCurrentQuestionIndex(0);
                        setTransitionToRenderResultsConditions(false);

                        setTransitionToResult(true);

                    }, () => {
                        console.warn("Error generating user result..")
                        displayAlert(L10N_APP_TEXTS[selectedLanguage].GENERATE_RESULT_ERROR)
                    }));
                });


            } else {
                setSubmit(false);
                onErrorEmailEmpty();
                console.log("empty email..");
            }
        }
    }, [transitionToRenderResultsConditions, submit]);


    useEffect(() => {
        if (transitionToResult) {
            //console.log(PAGES.RESULT + "/" + generateEncodedUrl() + "/" + "referralID=" + getReferralId  + "/" + "store=" + selectedLanguage);
            onNavigateTo(PAGES.RESULT + "/" + generateEncodedUrl() + "/" + "referralID=" + getReferralId + "/" + "store=" + selectedLanguage)
        }
    }, [transitionToResult]);

    const generateEncodedResultUrl = () => {
        return window.location?.host.toString() + PAGES.RESULT + "/" + generateEncodedUrl() + "/" + "referralID=" + getReferralId + "/" + "store=" + selectedLanguage;
    };

    const generateEncodedUrl = () => {
        let a = "";
        for (let i = 0; i < questions.length; i++) {
            a += `${i}${questions[i].answer}`
            if (i < questions.length - 1) {
                a += '-';
            }
        }
        console.log(a);

        //  const encodedUrl = Buffer.from(a).toString('base64');
        const encodedUrl = base64.encode(a);
        const decodedUrl = base64.decode(encodedUrl);

        console.log(encodedUrl, ' | ', decodedUrl);

        return encodedUrl;
    };

    const renderQuestionCounter = () => {
        return (
            <div style={{position: 'absolute', bottom: 10, width: '100%', textAlign: 'center'}}>
                <p className={'p-small'} style={{margin: 0}} onClick={() => {
                }}>{`${currentQuestionIndex + 1} /${questions.length}`}</p>
            </div>
        );
    };

    const renderTop = () => {
        return (
            <animated.div style={slideTopDown} className={'landing-page-container top'}>
                <p className={'landing-page-text title'}
                   style={{color: '#000000'}}>{L10N_APP_TEXTS[selectedLanguage].APP_TITLE_1}</p>
                <p className={'landing-page-text title'}
                   style={{
                       color: "#0090CD",
                       fontWeight: 'bold',
                       fontSize: '18pt',
                       marginBottom: '0.5em'
                   }}>{L10N_APP_TEXTS[selectedLanguage].APP_TITLE_2}</p>
                <p className={'landing-page-text subtitle'}>{L10N_APP_TEXTS[selectedLanguage].APP_PROMO}</p>

            </animated.div>
        )
    }

    const renderBody = () => {
        return (
            <div className={'landing-page-container mid'} style={{width: 'inherit'}}>

                <animated.div className={'mid-container'} style={{fadeIn, marginTop: 0, marginBottom: '12px'}}>
                    <img src={logo} alt="logo" className={'header-logo animated-logo'} style={{maxHeight: '12vh'}}/>
                    <p className={'small-text'}
                       style={{
                           color: '#fff',
                           fontSize: '11pt',
                           paddingLeft: '12pt',
                           paddingRight: '12pt',
                           paddingTop: '24px',
                           fontWeight: '300',
                           maxWidth: '500px'
                       }}>{L10N_APP_TEXTS[selectedLanguage].ANALYSIS_INFO}</p>

                </animated.div>
                <animated.div className={'mid-container'} style={{fadeIn, marginTop: 0}}>
                    <p className={'small-text'}
                       style={{
                           color: '#fff',
                           fontSize: '10pt',
                           paddingLeft: '12pt',
                           paddingRight: '12pt',
                           maxWidth: '500px'
                       }}>{L10N_APP_TEXTS[selectedLanguage].ANALYSIS_INSTRUCTIONS}</p>
                </animated.div>
            </div>
        )
    }

    const renderFooterLandingPage = () => {
        return (
            <animated.div style={slideBotUp} className={'footer'}>
                <div className={'button'} onClick={() => memoizedCallback(STATE.mid)}>
                    {L10N_APP_TEXTS[selectedLanguage].ANALYSIS_START}
                </div>
            </animated.div>
        )
    }

    const renderLandingPage = () => {
        if (transitionToListing || option) {
            return <></>
        }
        return <animated.div style={fadeOutAnimationPage} className={'landing-page'}>
            {renderTop()}
            {renderBody()}
            {renderFooterLandingPage()}
        </animated.div>
    }

    const renderListingsPage = () => {
        if (!option || transitionToResult) {
            return <></>
        }

        const renderHeader = () => {
            return <div className={'header'} style={{backgroundColor: getStateHeaderColor(option)}}>
                <div className={'header-container-padding'}>
                    <p className={'header-text'}
                       style={{fontWeight: 'normal'}}>{L10N_APP_TEXTS[selectedLanguage].APP_TITLE_1}</p>
                    <p className={'header-text'}>{L10N_APP_TEXTS[selectedLanguage].APP_TITLE_2}</p>
                </div>
                <img src={logo} alt="logo" className={'header-logo small'}/>
            </div>
        }

        const onSubmitAnswer = (answer) => {
            let q = [...questions]
            let currentQuestion = q[currentQuestionIndex];

            let answerMapped = answer;
            if (answer === ANSWERS.yes) {
                answerMapped = 'y';
            } else if (answer === ANSWERS.no) {
                answerMapped = 'n'
            }
            currentQuestion.answer = answerMapped;
            setQuestions(q);

            setCurrentQuestionIndex(currentQuestionIndex + 1);
        };
        console.log(questions);


        const renderFooter = () => {

            return <div className={'footer'}>
                {
                    transitionToRenderResultsConditions ?
                        <>
                            <div className={'footer'}>
                                <div className={'button'} onClick={() => {
                                    setSubmit(true);
                                }}>
                                    {L10N_APP_TEXTS[selectedLanguage].ANALYSIS_GENERATE_RESULT}
                                </div>
                            </div>
                        </> : (
                            <>
                                <div className={'buttons-container'}>
                                    <div className={'button alt'} onClick={() => {
                                        onSubmitAnswer(ANSWERS.yes);
                                    }}>
                                        {L10N_APP_TEXTS[selectedLanguage].YES}
                                    </div>
                                    <div className={'button alt'} onClick={() => {
                                        onSubmitAnswer(ANSWERS.no);
                                    }}>
                                        {L10N_APP_TEXTS[selectedLanguage].NO}
                                    </div>
                                </div>
                                {renderQuestionCounter()}
                            </>

                        )
                }


            </div>
        }

        return <animated.div ref={ulRef} style={fadeInAnimationPage} className={'listings-page'}>
            {renderHeader()}
            {renderTable(questions)}
            {renderFooter()}
        </animated.div>
    }


    function Item(props) {
        if (props.index !== currentQuestionIndex) {
            return <></>;
        }
        const item = props.value;
        return (
            <li className={'row'}>
                <p className={'question'}>
                    {item.name}
                </p>
                <p className={'question description'}>
                    {item.description}
                </p>
            </li>
        );
    }

    function renderTable(items) {
        const list = (items) => {
            if (!items) {
                return;
            }
            return (
                <ul id={'products-list'} className={'products list'}>
                    {items.map((item, index) => <Item key={index} index={index} value={item}/>)}
                </ul>
            );
        }
        return (
            <>
                {list(items)}
            </>
        );
    }

    const onErrorEmailVerification = () => {
        console.log("Unauthorized");
        setError(ErrorType.EMAIL_VERIFICATION_FAIL);
    };
    const onErrorEmailEmpty = () => {
        console.log("Unauthorized");
        setError(ErrorType.EMAIL_VERIFICATION_EMPTY);
    };

    const renderErrorMessage = () => {
        switch (error) {
            case ErrorType.EMAIL_VERIFICATION_FAIL:
                return <Message error l10n={selectedLanguage} type={ErrorType.EMAIL_VERIFICATION_FAIL}/>
            case ErrorType.EMAIL_VERIFICATION_EMPTY:
                return <Message error l10n={selectedLanguage} type={ErrorType.EMAIL_VERIFICATION_EMPTY}/>
            default:
                return null;
        }
    };

    const renderGenerateResultsConditions = () => {
        if (!transitionToRenderResultsConditions) {
            return <></>
        }
        return (
            <div className={'results-conditions'}>
                <p className={"p-text"}>
                    {L10N_APP_TEXTS[selectedLanguage].ANALYSIS_RESULT}
                </p>

                <Input white shadow loadProduct name onChange={(value) => {
                    setError(null);
                    setEmail(value);
                }} placeholder={'janez.novak@e-mail.com'}
                       label={L10N_APP_TEXTS[selectedLanguage].ENTER_EMAIL}
                       onBlur={(e) => {
                           console.log('Triggered because this input lost focus', e.target.value);
                       }}
                />
                {renderErrorMessage()}
                {renderShareResultsCheckbox()}
            </div>
        );
    };

    const renderShareResultsCheckbox = () => {
        return (
            <div className={'checkbox-container'} style={{marginBottom: '12px', marginTop: 8}}>
                <label>
                    <input type="checkbox"
                           checked={checkbox}
                           onChange={() => {
                               setCheckbox(!checkbox)
                           }}
                    />
                    <p className={'p-info'} style={{fontSize: '0.85em'}}>
                        {L10N_APP_TEXTS[selectedLanguage].ANALYSIS_SHARE_RESULTS}
                    </p>
                </label>
            </div>
        );
    };


    return (
        <div className={'container-results-conditions'}>
            {renderLandingPage()}
            {renderListingsPage()}
            {renderGenerateResultsConditions()}
        </div>
    );
}

export default Analysis;
