import React, {createRef, useCallback, useEffect, useState} from "react";
import {useSpring, animated} from 'react-spring'
import {useHistory, useParams} from "react-router-dom";
import {
    VictoryTheme,
    VictoryAxis,
    VictoryLabel,
    VictoryLabelProps,
    VictoryAnimation,
    VictoryChart,
    VictoryLine
} from 'victory';

import logo from '../assets/logo.png';
import resultsSideLegend from '../assets/results_bcg_side.png';
import resultsSideLegendHR from '../assets/results_bcg_side_HR.png';
import resultsSideLegendDE from '../assets/results_bcg_side_DE.png';
import resultsSideLegendIT from '../assets/results_bcg_side_IT.png';
import resultsSideLegendEN from '../assets/results_bcg_side_EN.png';

import {ReactComponent as IconCartEmpty} from '../assets/iconcartempty.svg';

import '../App.css';
import {
    BIO_SYSTEM_DE_ROOT, BIO_SYSTEM_DE_ROOT_REVERSED, BIO_SYSTEM_EN,
    BIO_SYSTEM_EN_ROOT, BIO_SYSTEM_EN_ROOT_REVERSED,
    BIO_SYSTEM_HR, BIO_SYSTEM_HR_ROOT, BIO_SYSTEM_HR_ROOT_REVERSED, BIO_SYSTEM_IT_ROOT, BIO_SYSTEM_IT_ROOT_REVERSED,
    BIO_SYSTEM_NORMAL_LIMITS, BIO_SYSTEM_SI, BIO_SYSTEMS,
    BIO_SYSTEMS_ARRAY, BIO_SYSTEMS_ARRAY_SORT_BY_IMPORTANCE,
    getStateHeaderColor, L10N_APP_TEXTS,
    PRODUCTS,
    QUESTIONS, RESULTS,
    SCORE_MATRIX,
    STATE
} from "../data/data";

import {PAGES} from "../App";
import {displayAlert, encapsulateStoreReferralIdToUrl, hasItem} from "../helpers/helpers";
import {L10N} from "../constants/enums";
import {getUserByReferralId} from "../store/actions/actions";
import {useDispatch} from "react-redux";

const base64 = require('base-64');

const calculateExtrasProducts = (res, setExtraProducts) => {
    let t = [];
    for (let i = 0; i < res.length; i++) {
        const q = res[i].charAt(res[i].length - 1);

        if (q === 'y' && i === 0) {
            t.push("energija")
        } else if (q === 'y' && i === 16) {
            t.push("koza")
        } else if (q === 'n' && i === 16) {
            t.push("skelet")
        } else if (q === 'y' && i === 20) {
            t.push("spanec")
        }
    }
    console.log("EXTRAS: ", t);
    setExtraProducts(t);
    return t;

};

export const calculateResultPoints = (res, selectedLanguage, onCompleteAction) => {
    let SCORE_MATRIX_RESULT = {
        'Prebavni': 0,
        'Črevesni': 0,
        'Krvožilni': 0,
        'Živčni': 0,
        'Imunski': 0,
        'Respiratorni': 0,
        'Urinarni': 0,
        'Hormonski': 0,
        'Skelet/koža': 0
    };

    const calculatePointsPerBioSystem = (bioSystem, selectedLanguage) => {
        for (let j = 0; j < res.length; j++) {
            console.log();
            for (let i = 0; i < SCORE_MATRIX[bioSystem].length; i++) {
                const q = res[j].charAt(res[j].length - 1);

                if (q === 'y' && SCORE_MATRIX[bioSystem][i] === j) {
                    SCORE_MATRIX_RESULT[bioSystem]++;
                    break;
                }
            }
        }
    };
    for (let i = 0; i < BIO_SYSTEMS_ARRAY.length; i++) {
        calculatePointsPerBioSystem(BIO_SYSTEMS_ARRAY[i]);
    }

    let worstSystems = [];
    let worstBioSystemScore = 0;
    for (let i = BIO_SYSTEMS_ARRAY_SORT_BY_IMPORTANCE.length - 1; i >= 0; i--) {
        let bioSystem = BIO_SYSTEMS_ARRAY_SORT_BY_IMPORTANCE[i];

        if (SCORE_MATRIX_RESULT[bioSystem] > BIO_SYSTEM_NORMAL_LIMITS[bioSystem] && SCORE_MATRIX_RESULT[bioSystem] >= worstBioSystemScore) {
            worstBioSystemScore = SCORE_MATRIX_RESULT[bioSystem];
            worstSystems.push(bioSystem);
        }
    }

    let r = [];
    for (let i = 0; i < BIO_SYSTEMS_ARRAY.length; i++) {
        let score = SCORE_MATRIX_RESULT[BIO_SYSTEMS_ARRAY[i]];
        let scoreBeforeCorrection = score;
        // correct values on graph to reflect paper blueprint
        if (BIO_SYSTEMS_ARRAY[i] === BIO_SYSTEMS.A || BIO_SYSTEMS_ARRAY[i] === BIO_SYSTEMS.B) {
            if (score >= 4 && score <= 6) {
                SCORE_MATRIX_RESULT[BIO_SYSTEMS_ARRAY[i]] += 1;
            } else if (score >= 7) {
                SCORE_MATRIX_RESULT[BIO_SYSTEMS_ARRAY[i]] += 2;
            }
        } else if (BIO_SYSTEMS_ARRAY[i] === BIO_SYSTEMS.C) {
            if (score >= 4 && score <= 4) {
                SCORE_MATRIX_RESULT[BIO_SYSTEMS_ARRAY[i]] += 2;
            } else if (score >= 5 && score <= 5) {
                SCORE_MATRIX_RESULT[BIO_SYSTEMS_ARRAY[i]] += 3;
            } else if (score >= 6) {
                SCORE_MATRIX_RESULT[BIO_SYSTEMS_ARRAY[i]] += 4;
            }
        } else if (BIO_SYSTEMS_ARRAY[i] === BIO_SYSTEMS.D) {
            if (score >= 7 && score <= 7) {
                SCORE_MATRIX_RESULT[BIO_SYSTEMS_ARRAY[i]] += 1;
            } else if (score >= 8) {
                SCORE_MATRIX_RESULT[BIO_SYSTEMS_ARRAY[i]] += 2;
            }
        } else if (BIO_SYSTEMS_ARRAY[i] === BIO_SYSTEMS.E) {
            if (score >= 4 && score <= 5) {
                SCORE_MATRIX_RESULT[BIO_SYSTEMS_ARRAY[i]] += 1;
            } else if (score >= 6 && score <= 6) {
                SCORE_MATRIX_RESULT[BIO_SYSTEMS_ARRAY[i]] += 4;
            } else if (score >= 7) {
                SCORE_MATRIX_RESULT[BIO_SYSTEMS_ARRAY[i]] += 5;
            }
        } else if (BIO_SYSTEMS_ARRAY[i] === BIO_SYSTEMS.F) {
            if (score >= 1 && score <= 4) {
                SCORE_MATRIX_RESULT[BIO_SYSTEMS_ARRAY[i]] += 2;
            } else if (score >= 5) {
                SCORE_MATRIX_RESULT[BIO_SYSTEMS_ARRAY[i]] += 6;
            }
        } else if (BIO_SYSTEMS_ARRAY[i] === BIO_SYSTEMS.G) {
            if (score >= 1 && score <= 1) {
                SCORE_MATRIX_RESULT[BIO_SYSTEMS_ARRAY[i]] += 2;
            } else if (score >= 2 && score <= 2) {
                SCORE_MATRIX_RESULT[BIO_SYSTEMS_ARRAY[i]] += 4;
            } else if (score >= 3 && score <= 3) {
                SCORE_MATRIX_RESULT[BIO_SYSTEMS_ARRAY[i]] += 6;
            } else if (score >= 4) {
                SCORE_MATRIX_RESULT[BIO_SYSTEMS_ARRAY[i]] += 7;
            }
        } else if (BIO_SYSTEMS_ARRAY[i] === BIO_SYSTEMS.H) {
            if (score >= 4) {
                SCORE_MATRIX_RESULT[BIO_SYSTEMS_ARRAY[i]] += 2;
            }
        } else if (BIO_SYSTEMS_ARRAY[i] === BIO_SYSTEMS.I) {
            if (score >= 1 && score <= 2) {
                SCORE_MATRIX_RESULT[BIO_SYSTEMS_ARRAY[i]] += 1;
            } else if (score >= 3 && score <= 5) {
                SCORE_MATRIX_RESULT[BIO_SYSTEMS_ARRAY[i]] += 2;
            } else if (score >= 6) {
                SCORE_MATRIX_RESULT[BIO_SYSTEMS_ARRAY[i]] += 3;
            }
        }

        r.push({
//            x: BIO_SYSTEMS_ARRAY[i],
            x: parseL10NBodySystemTickValues(selectedLanguage)[i],
            y: -1 * SCORE_MATRIX_RESULT[BIO_SYSTEMS_ARRAY[i]],
            label: scoreBeforeCorrection
        });
    }

    worstBioSystemScore = 0;
    let worst = null;
    for (let i = BIO_SYSTEMS_ARRAY_SORT_BY_IMPORTANCE.length - 1; i >= 0; i--) {
        let bioSystem = BIO_SYSTEMS_ARRAY_SORT_BY_IMPORTANCE[i];

        if (SCORE_MATRIX_RESULT[bioSystem] > BIO_SYSTEM_NORMAL_LIMITS[bioSystem] && SCORE_MATRIX_RESULT[bioSystem] >= worstBioSystemScore) {
            worstBioSystemScore = SCORE_MATRIX_RESULT[bioSystem];
            worst = bioSystem;
        }
    }
    console.log("CALUCLATED RESULT POINTS:", worstSystems, worst, SCORE_MATRIX_RESULT);

    if (onCompleteAction) {
        onCompleteAction(worst, worstSystems && worstSystems.length > 0 ? worstSystems : [], r)
    }
    return worst;
}

export const parseL10NSideLegend = (selectedLanguage) => {
    switch (selectedLanguage) {
        case L10N.hr.value:
            return resultsSideLegendHR;
        case L10N.en.value:
        case L10N.us.value:
            return resultsSideLegendEN;
        case L10N.it.value:
            return resultsSideLegendIT;
        case L10N.de.value:
        case L10N.at.value:
            return resultsSideLegendDE;
        case L10N.si.value:
        default:
            return resultsSideLegend;
    }
};

export const parseL10NBodySystemTickValues = (selectedLanguage) => {
    switch (selectedLanguage) {
        case L10N.hr.value:
            return [
                BIO_SYSTEM_HR_ROOT[BIO_SYSTEMS_ARRAY[0]],
                BIO_SYSTEM_HR_ROOT[BIO_SYSTEMS_ARRAY[1]],
                BIO_SYSTEM_HR_ROOT[BIO_SYSTEMS_ARRAY[2]],
                BIO_SYSTEM_HR_ROOT[BIO_SYSTEMS_ARRAY[3]],
                BIO_SYSTEM_HR_ROOT[BIO_SYSTEMS_ARRAY[4]],
                BIO_SYSTEM_HR_ROOT[BIO_SYSTEMS_ARRAY[5]],
                BIO_SYSTEM_HR_ROOT[BIO_SYSTEMS_ARRAY[6]],
                BIO_SYSTEM_HR_ROOT[BIO_SYSTEMS_ARRAY[7]],
                BIO_SYSTEM_HR_ROOT[BIO_SYSTEMS_ARRAY[8]]];
        case L10N.de.value:
        case L10N.at.value:
            return [
                BIO_SYSTEM_DE_ROOT[BIO_SYSTEMS_ARRAY[0]],
                BIO_SYSTEM_DE_ROOT[BIO_SYSTEMS_ARRAY[1]],
                BIO_SYSTEM_DE_ROOT[BIO_SYSTEMS_ARRAY[2]],
                BIO_SYSTEM_DE_ROOT[BIO_SYSTEMS_ARRAY[3]],
                BIO_SYSTEM_DE_ROOT[BIO_SYSTEMS_ARRAY[4]],
                BIO_SYSTEM_DE_ROOT[BIO_SYSTEMS_ARRAY[5]],
                BIO_SYSTEM_DE_ROOT[BIO_SYSTEMS_ARRAY[6]],
                BIO_SYSTEM_DE_ROOT[BIO_SYSTEMS_ARRAY[7]],
                BIO_SYSTEM_DE_ROOT[BIO_SYSTEMS_ARRAY[8]]];
        case L10N.it.value:
            return [
                BIO_SYSTEM_IT_ROOT[BIO_SYSTEMS_ARRAY[0]],
                BIO_SYSTEM_IT_ROOT[BIO_SYSTEMS_ARRAY[1]],
                BIO_SYSTEM_IT_ROOT[BIO_SYSTEMS_ARRAY[2]],
                BIO_SYSTEM_IT_ROOT[BIO_SYSTEMS_ARRAY[3]],
                BIO_SYSTEM_IT_ROOT[BIO_SYSTEMS_ARRAY[4]],
                BIO_SYSTEM_IT_ROOT[BIO_SYSTEMS_ARRAY[5]],
                BIO_SYSTEM_IT_ROOT[BIO_SYSTEMS_ARRAY[6]],
                BIO_SYSTEM_IT_ROOT[BIO_SYSTEMS_ARRAY[7]],
                BIO_SYSTEM_IT_ROOT[BIO_SYSTEMS_ARRAY[8]]];
        case L10N.en.value:
        case L10N.us.value:
            return [
                BIO_SYSTEM_EN_ROOT[BIO_SYSTEMS_ARRAY[0]],
                BIO_SYSTEM_EN_ROOT[BIO_SYSTEMS_ARRAY[1]],
                BIO_SYSTEM_EN_ROOT[BIO_SYSTEMS_ARRAY[2]],
                BIO_SYSTEM_EN_ROOT[BIO_SYSTEMS_ARRAY[3]],
                BIO_SYSTEM_EN_ROOT[BIO_SYSTEMS_ARRAY[4]],
                BIO_SYSTEM_EN_ROOT[BIO_SYSTEMS_ARRAY[5]],
                BIO_SYSTEM_EN_ROOT[BIO_SYSTEMS_ARRAY[6]],
                BIO_SYSTEM_EN_ROOT[BIO_SYSTEMS_ARRAY[7]],
                BIO_SYSTEM_EN_ROOT[BIO_SYSTEMS_ARRAY[8]]];
        case L10N.si.value:
        default:
            return [BIO_SYSTEMS_ARRAY[0],
                BIO_SYSTEMS_ARRAY[1],
                BIO_SYSTEMS_ARRAY[2],
                BIO_SYSTEMS_ARRAY[3],
                BIO_SYSTEMS_ARRAY[4],
                BIO_SYSTEMS_ARRAY[5],
                BIO_SYSTEMS_ARRAY[6],
                BIO_SYSTEMS_ARRAY[7],
                BIO_SYSTEMS_ARRAY[8]]
    }
};

function Results() {
    let dispatch = useDispatch();

    const {result} = useParams();
    const {referralID} = useParams('referralID');
    const {store} = useParams('store');

    const [option, setOption] = useState(null);
    const [transitionToListing, setTransitionToListing] = useState(false);

    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [init, setInit] = useState(false);
    const [overallResultGood, setOverallResult] = useState(false);
    const [worstBioSystems, setWorstBioSystems] = useState([]);

    const [worstBioSystem, setWorstBioSystem] = useState(null);

    const [results, setResults] = useState(null);

    const [recommendedProductsBasic, setRecommendedProductsBasic] = useState([]);
    const [recommendedProductsOptimal, setRecommendedProductsOptimal] = useState([]);

    const [getReferralId, setReferralId] = useState(referralID ? referralID.split('referralID=')[1] : null);
    const [selectedLanguage, setSelectedLanguage] = useState(store ? store.split('store=')[1] : null);
    window.referralID = getReferralId;

    const [referralUserEmail, setReferralUserEmail] = useState(null);

    const [extraProducts, setExtraProducts] = useState([]);

    console.log("referralID=", getReferralId, 'result=', result, window.referralID, selectedLanguage);

    const ulRef1 = createRef();

    const [fadeIn, setFadeIn] = useState(useSpring(
        {
            from: {opacity: 0},
            to: {opacity: 1},
            config: {duration: 600},
            delay: 1800,
        }))

    const scrollToTop = () => {
        let el = document.getElementById('products-list');
        console.log(window.pageYOffset, el);
        if (!el) {
            return;
        }

        window.scrollTo(0, 0);
        /*
                window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                });

         */
    };

    function scrollToDiv(div) {
        let id = div;

        const yOffset = 50;
        const element = document.getElementById(id);
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

        let el = document.getElementById(id);
        if (el) {
            el.scrollIntoView({behavior: 'smooth'});
        }
    }


    const toggleTransition = (state) => {
        setOption(state);
        setTransitionToListing(state)
        setOption(state);

        setTimeout(() => {
            setTransitionToListing(state)
        }, 200)

        scrollToTop();
    }
    const toggleTransitionChange = (state) => {
        setOption(state);
        scrollToTop();
    }
    const memoizedCallback = useCallback(toggleTransition, []);
    const memoizedCallbackTransition = useCallback(toggleTransitionChange, []);


    useEffect(() => {
        if (memoizedCallback) {
            memoizedCallback(STATE.mid)
        }

        if (!init) {
            const decodedUrl = base64.decode(result);
            let res = decodedUrl.split('-');

            let extras = calculateExtrasProducts(res, setExtraProducts);

            calculateResultPoints(res, selectedLanguage, (worst, worstSystems, results) => {
                console.log("RES: ", worst, results, worstBioSystem);
                setWorstBioSystem(worst);
                setOverallResult(!worst);

                setWorstBioSystems([...worstSystems]);
                setResults(results);

                let pB = [];
                let pO = [];
                if (!worstSystems || worstSystems.length <= 0) {
                    pB = [PRODUCTS.vital_3, PRODUCTS.detox, PRODUCTS.purify_kit, PRODUCTS.dnk];

                    if (extras.includes("energija")) {
                        pB.unshift(PRODUCTS.e9);
                    }
                } else {
                    pB = [...RESULTS[worst].basic];
                    pO = [...RESULTS[worst].optimal];

                    if (worst === "Skelet/koža") {
                        if (extras.includes("koza")) {
                            pB.push(PRODUCTS.collagen);
                            pB.push(PRODUCTS.silverShield);
                        }
                        if (extras.includes("skelet")) {
                            pB.push(PRODUCTS.flx3);
                            pB.push(PRODUCTS.d3);
                        }
                    }
                }

                if (extras.includes("spanec")) {
                    if (pO.length >= 0) {
                        pO.push(PRODUCTS.sleepWell);
                    } else {
                        pB.push(PRODUCTS.sleepWell);
                    }
                }


                setRecommendedProductsBasic(pB)
                setRecommendedProductsOptimal(pO)
            });

            setTimeout(() => {
                setTimeout(() => {
                    scrollToDiv('results-head');

                    if (getReferralId) {
                        dispatch(getUserByReferralId(getReferralId, (res) => {
                            setReferralUserEmail(res.username);
                            console.log(res)

                        }, () => {
                        }, () => {
                        }));
                    }
                }, 2400)
                setInit(true);
            }, 2600)
        }
    }, [])

    const [fadeOutAnimationPage, setFadeOutAnim] = useState(useSpring({
        from: {opacity: 1},
        to: {
            opacity: option ? 0 : 1,
        },
        config: {duration: 200},
    }))

    const [fadeInAnimationPage, setFadeInAnim] = useState(useSpring({
        from: {opacity: 0},
        to: {
            opacity: transitionToListing ? 1 : 0,
        },
        config: {duration: 450},
    }))


    const renderResultsPage = () => {

        const renderHeader = () => {
            return <div className={'header'} style={{backgroundColor: getStateHeaderColor(STATE.mid)}}>
                <div className={'header-container-padding'}>
                    <p className={'header-text'}
                       style={{fontWeight: 'normal'}}>{L10N_APP_TEXTS[selectedLanguage].APP_TITLE_1}</p>
                    <p className={'header-text'}>{L10N_APP_TEXTS[selectedLanguage].APP_TITLE_2}</p>
                </div>
                <img src={logo} alt="logo" className={'header-logo small'}/>
            </div>
        }

        const renderResultsText = () => {
            let p;

            if (!selectedLanguage || selectedLanguage === L10N.si.value) {
                if (overallResultGood) {
                    p = <div className={'results-text intro'}>
                        <p style={{color: '#0090CD', fontWeight: 'bold'}}>Čestitamo, vaš rezultat je odličen!</p>
                        <p>Dobro zdravje je potrebno vzdrževati. Skrb zanj je danes vedno bolj v rokah posameznika, zato
                            je pomembno, da na področju zdravja delujemo preventivno in poznamo načine kako ga ohraniti.
                            Synergy ponuja izdelke in programe, s katerimi naslavljamo največja tveganja za izgubo
                            zdravja, kot so: slaba hrana osiromašena hranil, onesnaženo okolje in zrak, slaba kvaliteta
                            vode, vsakodnevni kronični stres, porušen črevesni mikrobiom, oslabljen imunski sistem,
                            kronična utrujenost, izgorelost in še mnoge druge.</p>
                        <p>Za ohranjanje dolgoročnega zdravja priporočamo naslednje programe:</p>
                    </div>
                } else {
                    p = <div className={'results-text intro'}>
                        <p style={{color: '#0090CD', fontWeight: 'bold'}}>Glede na vaš rezultat, je sedaj pravi čas, da
                            poskrbite za svoj <span
                                className={'p-url'} style={{
                                color: '#c43a31',
                                textTransform: 'lowercase'
                            }}>{worstBioSystem} sistem</span>, ki
                            trenutno potrebuje največ vaše pozornosti in podpore za zdravje.
                            Lahko izbirate med dvema pristopoma: med <span
                                className={'p-bold'}>osnovnim</span> in bolj <span
                                className={'p-bold'}>celostnim</span>.</p>

                    </div>
                }
            } else if (selectedLanguage === L10N.it.value) {
                if (overallResultGood) {
                    p = <div className={'results-text intro'}>
                        <p style={{color: '#0090CD', fontWeight: 'bold'}}>Congratulazioni, il tuo risultato è eccellente!</p>
                        <p>Mantenere una buona salute è fondamentale. Prendersene cura è sempre più nelle mani degli individui, quindi
                            è importante agire in modo preventivo nel campo della salute e conoscere i modi per preservarla.
                            Synergy offre prodotti e programmi che affrontano i maggiori rischi per la perdita di
                            salute, come: cattiva alimentazione povera di nutrienti, ambiente e aria inquinati, scarsa qualità dell'acqua,
                            stress cronico quotidiano, squilibrio del microbioma intestinale, sistema immunitario indebolito,
                            affaticamento cronico, burnout e molti altri.</p>
                        <p>Per mantenere una salute a lungo termine, ti consigliamo i seguenti programmi:</p>
                    </div>
                } else {
                    p = <div className={'results-text intro'}>
                        <p style={{color: '#0090CD', fontWeight: 'bold'}}>In base al tuo risultato, è il momento giusto per
                            prenderti cura del tuo <span
                                className={'p-url'} style={{
                                color: '#c43a31',
                                textTransform: 'lowercase'
                            }}>{worstBioSystems.length > 0 ? BIO_SYSTEM_IT_ROOT[worstBioSystem] : " "} sistema</span>, che
                            al momento richiede la massima attenzione e supporto per la salute.
                            Puoi scegliere tra due approcci: un <span
                                className={'p-bold'}>programma di base</span> o uno più <span
                                className={'p-bold'}>completo</span>.</p>

                    </div>
                }
            } else if (selectedLanguage === L10N.en.value || selectedLanguage === L10N.us.value) {
                if (overallResultGood) {
                    p = <div className={'results-text intro'}>
                        <p style={{color: '#0090CD', fontWeight: 'bold'}}>Congratulations, your result is excellent!</p>
                        <p>Good health needs to be maintained. Taking care of it is increasingly in the hands of
                            individuals, so
                            it is important to act preventively in the field of health and know the ways to preserve it.
                            Synergy offers products and programs that address the greatest risks to health loss, such
                            as: poor nutrition depleted of nutrients, polluted environment and air, poor water quality,
                            daily chronic stress, disrupted gut microbiome, weakened immune system,
                            chronic fatigue, burnout, and many others.</p>
                        <p>To maintain long-term health, we recommend the following programs:</p>
                    </div>
                } else {
                    p = <div className={'results-text intro'}>
                        <p style={{color: '#0090CD', fontWeight: 'bold'}}>Depending on your result, it's time to take
                            care of your health.</p>
                        <p>If you want to solve your problems as effectively and long-term as possible, we recommend
                            starting your health improvement journey with the <span className={'p-bold'}>Purify program for balancing gut microbiome</span> and
                            then continue with solutions suitable for maintaining the health of your <span
                                className={'p-url'} style={{
                                color: '#c43a31',
                                textTransform: 'lowercase'
                            }}>{worstBioSystems.length > 0 ? BIO_SYSTEM_EN[worstBioSystem] : " "} system</span> for
                            which the analysis showed it to be imbalanced.</p>
                        <p>Since our overall health comes from the gut and depends on it for the health of all our other
                            bodily systems, our general well-being, vitality, energy level, and body weight, the <span
                                className={'p-bold'}>Purify program for balancing gut microbiome</span> is the best
                            first step to improve health. The program strengthens the gut microbiome, improves the
                            living and reproductive conditions of beneficial bacteria, which is essential for a strong
                            immune system and overall health. After completing the program, you will feel lighter, full
                            of energy, and lose excess weight, as the program will help you eliminate the desire for
                            unhealthy food and improve your eating habits.</p>
                        <p>After completing the gut microbiome cleansing program, we suggest that you continue to use
                            products that are suitable for improving and maintaining the health of your <span
                                className={'p-url'} style={{
                                color: '#c43a31',
                                textTransform: 'lowercase'
                            }}>{worstBioSystems.length > 0 && BIO_SYSTEM_EN[worstBioSystem]} system.</span></p>
                        <p>In any case, we suggest taking care of your own: <span className={'p-url'} style={{
                            color: '#c43a31',
                            textTransform: 'lowercase'
                        }}>{BIO_SYSTEM_EN[worstBioSystem]} system</span>. To support and improve the health of
                            your <span className={'p-url'} style={{
                                color: '#c43a31',
                                textTransform: 'lowercase'
                            }}>{worstBioSystems.length > 0 && BIO_SYSTEM_EN[worstBioSystem]} system</span>, you can
                            choose between two options: a basic program for initially introducing one to two products
                            into your diet or an optimal program that represents a more systematic solution with several
                            complementary products</p>
                    </div>
                }
            } else if (selectedLanguage === L10N.de.value || selectedLanguage === L10N.at.value) {
                if (overallResultGood) {
                    p = <div className={'results-text intro'}>
                        <p style={{color: '#0090CD', fontWeight: 'bold'}}>Herzlichen Glückwunsch, Ihr Ergebnis ist
                            hervorragend!</p>
                        <p>Gesundheit muss erhalten bleiben. Es liegt immer mehr in der Hand der Einzelnen, sich darum
                            zu kümmern,
                            daher ist es wichtig, im Gesundheitsbereich präventiv zu handeln und die Möglichkeiten zur
                            Erhaltung zu kennen.
                            Synergy bietet Produkte und Programme, die die größten Risiken für den Verlust von
                            Gesundheit angehen, wie z. B.:
                            schlechte, nährstoffarme Ernährung, verschmutzte Umwelt und Luft, schlechte Wasserqualität,
                            täglicher chronischer Stress, gestörtes Darmmikrobiom, geschwächtes Immunsystem,
                            chronische Müdigkeit, Burnout und viele andere.</p>
                        <p>Um die Gesundheit langfristig zu erhalten, empfehlen wir die folgenden Programme:</p>
                    </div>
                } else {
                    p = <div className={'results-text intro'}>
                        <p style={{color: '#0090CD', fontWeight: 'bold'}}>Abhängig von Ihrem Ergebnis ist es an der
                            Zeit, sich um Ihre Gesundheit zu kümmern.</p>
                        <p>Wenn Sie Ihre Probleme so effektiv und langfristig wie möglich lösen möchten, empfehlen wir
                            den Einstieg in die Verbesserung Ihrer Gesundheit mit dem <span className={'p-bold'}>Purify-Programm zur Balance des Darmmikrobioms</span> und
                            anschließend mit Lösungen, die zur Aufrechterhaltung der Gesundheit Ihres <span
                                className={'p-url'} style={{
                                color: '#c43a31',
                                textTransform: 'lowercase'
                            }}>{worstBioSystems.length > 0 ? BIO_SYSTEM_DE_ROOT[worstBioSystem] : " "} Systems</span>
                            geeignet sind, für das die Analyse eine Unausgewogenheit zeigte.</p>
                        <p>Da unsere allgemeine Gesundheit aus dem Darm stammt und von ihm für die Gesundheit aller
                            unserer anderen
                            Körpersysteme, unser allgemeines Wohlbefinden, unsere Vitalität, unser Energielevel und
                            unser Körpergewicht abhängig ist, ist das <span
                                className={'p-bold'}>Purify-Programm zur Balance des Darmmikrobioms</span> der beste
                            erste Schritt zur Verbesserung der Gesundheit. Das Programm stärkt das Darmmikrobiom,
                            verbessert die
                            Lebens- und Fortpflanzungsbedingungen der nützlichen Bakterien, was für ein starkes
                            Immunsystem und die allgemeine Gesundheit unerlässlich ist. Nach Abschluss des Programms
                            werden Sie sich leichter fühlen, voll
                            Energie sein und überschüssiges Gewicht verlieren, da das Programm Ihnen helfen wird, das
                            Verlangen nach
                            ungesunden Lebensmitteln zu beseitigen und Ihre Essgewohnheiten zu verbessern.</p>
                        <p>Nach Abschluss des Darmmikrobiom-Reinigungsprogramms empfehlen wir Ihnen, weiterhin
                            Produkte zu verwenden, die für die Verbesserung und Erhaltung der Gesundheit Ihres <span
                                className={'p-url'} style={{
                                color: '#c43a31',
                                textTransform: 'lowercase'
                            }}>{worstBioSystems.length > 0 && BIO_SYSTEM_DE_ROOT[worstBioSystem]} Systems</span> geeignet
                            sind.</p>
                        <p>In jedem Fall empfehlen wir Ihnen, sich um Ihr eigenes: <span className={'p-url'} style={{
                            color: '#c43a31',
                            textTransform: 'lowercase'
                        }}>{BIO_SYSTEM_DE_ROOT[worstBioSystem]} System</span> zu kümmern. Um die Gesundheit Ihres
                            <span className={'p-url'} style={{
                                color: '#c43a31',
                                textTransform: 'lowercase'
                            }}>{worstBioSystems.length > 0 && BIO_SYSTEM_DE_ROOT[worstBioSystem]} Systems</span> zu
                            unterstützen und zu verbessern, können Sie
                            zwischen zwei Optionen wählen: einem Basisprogramm, um zunächst ein bis zwei Produkte
                            in Ihre Ernährung einzuführen, oder einem optimalen Programm, das eine systematischere
                            Lösung mit mehreren
                            ergänzenden Produkten darstellt.</p>
                    </div>
                }
            } else if (selectedLanguage === L10N.hr.value) {
                if (overallResultGood) {
                    p = <div className={'results-text intro'}>
                        <p style={{color: '#0090CD', fontWeight: 'bold'}}>Čestitamo, vaše zdravje je odlično. Održite
                            ga!</p>
                        <p>Važno je održavati i brinuti se o dobrom zdravlju.
                            Okruženi sve većim negativnim utjecajima kao što su: zagađeni okoliš, hrana,
                            osiromašene hranjive tvari i kronični stress, te održavanje dobrog stanja
                            organizma postaje svakodnevna potreba.</p>

                        <p>Da biste dugoročno osigurali svoje zdravlje, preporučujemo sljedeće
                            programe:</p>
                    </div>
                } else {
                    p = <div className={'results-text intro'}>
                        <p style={{color: '#0090CD', fontWeight: 'bold'}}>Ovisno o vašem rezultatu, vrijeme je da se
                            pobrinete za svoje zdravlje.</p>
                        <p>Ako želite riješiti svoje probleme što učinkovitije i dugoročnije, preporučujemo
                            da prvo započnete svoj put poboljšanja zdravlja s <span className={'p-bold'}>Purify programom uravnoteženje mikrobioma crijeva</span> a
                            zatim nastavite s rješenjima pogodnim
                            za održavanje zdravlja vašeg <span
                                className={'p-url'} style={{
                                color: '#c43a31',
                                textTransform: 'lowercase'
                            }}>{worstBioSystems.length > 0 ? BIO_SYSTEM_HR[worstBioSystem] : " "} sustava</span> za
                            kojeg je
                            analiza pokazala kako je u neravnoteži.
                        </p>

                        <p>Budući da naše cjelokupno zdravlje dolazi iz crijeva i ovisi o njemu zdravlje
                            svih naših drugih tjelesnih sustava, naša opća dobrobit, vitalnost, razina
                            energije, a također i tjelesna težina <span className={'p-bold'}>Purify programom uravnoteženje mikrobioma crijeva</span>
                            najbolji je prvi korak poboljšanja zdravlja. Program jača mikrobiom crijeva, poboljšava
                            uvjete za život i reprodukciju zdravstveno korisnih bakterija, što je ključno za dobar
                            imunološki sustav i cjelokupno zdravlje. Nakon završetka programa osjećat ćete se laganije,
                            puno energije, ali i lakše za mnoge suvišne kilograme, jer će vam program pomoći da uklonite
                            želju za nezdravom hranom i poboljšate svoje prehrambene navike.
                        </p>

                        <p> Nakon završetka programa čišćenja mikrobioma crijeva predlažemo da
                            nastavite koristiti proizvode koji su prikladni za poboljšanje i održavanje
                            zdravlja vašeg <span
                                className={'p-url'} style={{
                                color: '#c43a31',
                                textTransform: 'lowercase'
                            }}>{worstBioSystems.length > 0 && BIO_SYSTEM_HR[worstBioSystem]} sustava.</span>
                        </p>

                        <p> U svakom slučaju, predlažemo da se pobrinete za vlastiti: <span
                            className={'p-url'} style={{
                            color: '#c43a31',
                            textTransform: 'lowercase'
                        }}>{BIO_SYSTEM_HR[worstBioSystem]} sustav</span>.
                            Da biste podržali i poboljšali zdravlje vašeg <span
                                className={'p-url'} style={{
                                color: '#c43a31',
                                textTransform: 'lowercase'
                            }}>{worstBioSystems.length > 0 && BIO_SYSTEM_HR[worstBioSystem]} sustava</span>, možete
                            birati između dvije opcije: osnovnog programa za prvo uvođenje jednog do dva proizvoda u
                            vašu prehranu ili optimalnog programa koji predstavlja sustavnije rješenje s nekoliko
                            proizvoda koji se međusobno nadopunjuju</p>
                    </div>
                }
            }


            return (
                <animated.div id={'results-head'} className={'results-container'} style={fadeIn}>
                    {p}
                </animated.div>
            );
        };

        const renderResultsAfterListings = () => {
            return (
                !overallResultGood ?
                    <div className={'results-container'} style={{paddingBottom: '42vh'}}>
                        <div className={'results-text'}>

                            {selectedLanguage === L10N.si.value &&
                                <>
                                    <p> Več vsebin o Synergy programih in izdelkih lahko najdete <span>na: </span>
                                        {<a className={'p-url'}
                                            href={'https://www.vitalway.si/synergy/'}
                                            target="_blank">vitalway.si/synergy</a>}
                                    </p>
                                    <p>Za bolj podrobno razlago vaše Lifestyle analize ter informacije o Synergy
                                        rešitvah, kontaktirajte osebo, ki vam je analizo poslala.</p>

                                    <a className={'p-url'}
                                       style={{display: referralUserEmail ? 'flex' : 'none'}}
                                       href={`mailto:${referralUserEmail}`}
                                       target="_blank">Za kontakt preko e-mail sporočila, kliknite tu.</a>
                                </>
                            }
                            {selectedLanguage === L10N.hr.value &&
                                <>
                                    <p> Za službenu Synergy online trgovinu kliknite
                                        {<a className={'p-url'}
                                            href={encapsulateStoreReferralIdToUrl('https://new.synergyworldwide.com/SI/en-SI', getReferralId)}
                                            target="_blank"> https://new.synergyworldwide.com</a>}</p>
                                    <p>Za detaljnije objašnjenje vaše Lifestyle analize i informacija o Synergy
                                        rješenjima obratite se osobi koja vam je poslala analizu.</p>

                                    <a className={'p-url'}
                                       style={{display: referralUserEmail ? 'flex' : 'none'}}
                                       href={`mailto:${referralUserEmail}`}
                                       target="_blank">Za kontakt putem e-mail poruke kliknite ovdje.</a>
                                </>
                            }
                            {selectedLanguage === L10N.de.value || selectedLanguage === L10N.at.value &&
                                <>
                                    <p>Finden Sie mehr Inhalte über Synergy-Programme und -Produkte <span>auf: </span>
                                        {<a className={'p-url'}
                                            href={'https://new.synergyworldwide.com'}
                                            target="_blank">https://new.synergyworldwide.com</a>}
                                    </p>
                                    <p>Für eine detailliertere Erklärung Ihrer Lifestyle-Analyse und Informationen über
                                        Synergy-Lösungen wenden Sie sich an die Person, die Ihnen die Analyse geschickt hat.</p>

                                    <a className={'p-url'}
                                       style={{display: referralUserEmail ? 'flex' : 'none'}}
                                       href={`mailto:${referralUserEmail}`}
                                       target="_blank">Hier kontaktieren.</a>
                                </>
                            }
                            {selectedLanguage === L10N.it.value &&
                                <>
                                    <p>Trova più contenuti sui programmi e prodotti Synergy: <span>su: </span>
                                        {<a className={'p-url'}
                                            href={'https://new.synergyworldwide.com'}
                                            target="_blank">https://new.synergyworldwide.com</a>}
                                    </p>
                                    <p>Per una spiegazione più dettagliata della tua analisi dello stile di vita e informazioni sulle
                                        soluzioni Synergy, contatta la persona che ti ha inviato l'analisi. </p>

                                    <a className={'p-url'}
                                       style={{display: referralUserEmail ? 'flex' : 'none'}}
                                       href={`mailto:${referralUserEmail}`}
                                       target="_blank">Contatta qui.</a>
                                </>
                            }
                            {!selectedLanguage || selectedLanguage === L10N.en.value || selectedLanguage === L10N.us.value &&
                                <>
                                    <p>Find more content about Synergy programs and products <span>at: </span>
                                        {<a className={'p-url'}
                                            href={'https://new.synergyworldwide.com'}
                                            target="_blank">https://new.synergyworldwide.com</a>}
                                    </p>
                                    <p>For a more detailed explanation of your Lifestyle analysis and information about
                                        Synergy solutions, contact the person who sent you the analysis. </p>

                                    <a className={'p-url'}
                                       style={{display: referralUserEmail ? 'flex' : 'none'}}
                                       href={`mailto:${referralUserEmail}`}
                                       target="_blank">Contact here.</a>
                                </>
                            }

                        </div>
                    </div> :
                    <div className={'results-container'} style={{paddingBottom: '42vh'}}>
                        <div className={'results-text'}>
                            {selectedLanguage === L10N.si.value &&
                                <>
                                    <p> Več vsebin o Synergy programih in izdelkih lahko najdete <span>na: </span>
                                        {<a className={'p-url'}
                                            href={'https://www.vitalway.si/synergy/'}
                                            target="_blank"> vitalway.si/synergy</a>}</p>
                                    <p>Za bolj podrobno razlago vaše Lifestyle analize ter informacije o Synergy
                                        rešitvah, kontaktirajte osebo, ki vam je analizo poslala.</p>
                                    <a className={'p-url'}
                                       style={{display: referralUserEmail ? 'flex' : 'none'}}
                                       href={`mailto:${referralUserEmail}`}
                                       target="_blank">Za kontakt preko e-mail sporočila, kliknite tu.</a>
                                </>
                            }
                            {selectedLanguage === L10N.hr.value &&
                                <>
                                    <p> Za službenu Synergy online trgovinu kliknite
                                        {<a className={'p-url'}
                                            href={encapsulateStoreReferralIdToUrl('https://new.synergyworldwide.com/SI/en-SI', getReferralId)}
                                            target="_blank"> https://new.synergyworldwide.com</a>}</p>
                                    <p>Za detaljnije objašnjenje vaše Lifestyle analize i informacija o Synergy
                                        rješenjima obratite se osobi koja vam je poslala analizu.</p>
                                    <a className={'p-url'}
                                       style={{display: referralUserEmail ? 'flex' : 'none'}}
                                       href={`mailto:${referralUserEmail}`}
                                       target="_blank">Za kontakt putem e-mail poruke kliknite ovdje.</a>
                                </>
                            }
                            {!selectedLanguage || selectedLanguage === L10N.en.value || selectedLanguage === L10N.us.value &&
                                <>
                                    <p> You can find more content about Synergy programs and products <span>at: </span>
                                        {<a className={'p-url'}
                                            href={'https://new.synergyworldwide.com/SI/en-SI'}
                                            target="_blank">https://new.synergyworldwide.com</a>}
                                    </p>
                                    <p>For a more detailed explanation of your Lifestyle analysis and information on
                                        Synergy solutions, contact the person who sent you the analysis.</p>

                                    <a className={'p-url'}
                                       style={{display: referralUserEmail ? 'flex' : 'none'}}
                                       href={`mailto:${referralUserEmail}`}
                                       target="_blank">For contact via e-mail, click here.</a>
                                </>
                            }
                        </div>
                    </div>
            );
        };

        const l10nStoreText = (selectedLanguage) => {
            let p = "Visit store";
            if (!selectedLanguage) {
                return p;
            }

            switch (selectedLanguage) {
                case L10N.si.value:
                    p = 'Obišči trgovino';
                    break;
                case L10N.hr.value:
                    p = 'Posjeti trgovinu';
                    break;
                case L10N.de.value:
                    p = 'Besuche den Laden';
                    break;
                case L10N.it.value:
                    p = 'Visita il negozio';
                    break;
                case L10N.en.value:
                case L10N.us.value:
                    p = 'Visit store';
                    break;
                default:
                    return p;
            }
            return p;
        }

        const l10nOrderItFromText = (selectedLanguage) => {
            let p = 'You can order it from our official';
            if (!selectedLanguage) {
                return p;
            }

            switch (selectedLanguage) {
                case L10N.si.value:
                    p = 'Naročite ga pa preko uradne';
                    break;
                case L10N.hr.value:
                    p = 'Možete ga naručiti iz naše službene';
                    break;
                case L10N.de.value:
                    p = 'Sie können es in unserem offiziellen';
                    break;
                case L10N.it.value:
                    p = 'Puoi ordinarlo dal nostro ufficiale';
                    break;
                case L10N.en.value:
                case L10N.us.value:
                    p = 'You can order it from our official';
                    break;
                default:
                    return p;
            }
            return p;
        }

        const l10nOrderItFromStore = (selectedLanguage) => {
            let p = ' Synergy online store';
            if (!selectedLanguage) {
                return p;
            }

            switch (selectedLanguage) {
                case L10N.si.value:
                    p = ' Synergy spletne trgovine';
                    break;
                case L10N.hr.value:
                    p = ' Synergy internetske trgovine';
                    break;
                case L10N.de.value:
                    p = ' Synergy Online-Shop';
                    break;
                case L10N.it.value:
                    p = ' Synergy negozio online';
                    break;
                case L10N.en.value:
                case L10N.us.value:
                    p = ' Synergy online store';
                    break;
                default:
                    return p;
            }
            return p;
        }


        const renderProductRow = (item) => {
            if (overallResultGood) {
                return (
                    <div className={'results-container-inner'}>
                        <p className={'p-title extra-title'}>{selectedLanguage ? item.extra_title[selectedLanguage] : item.extra_title[L10N.si.value]}</p>
                        <p className={'p-title'}>{selectedLanguage ? item.name[selectedLanguage] : item.name[L10N.si.value]}</p>
                        <div className={'product left'}>
                            <img src={item.image} alt="side" className={'product-img'}/>
                        </div>
                        <div className={'product right'}>
                            <div className={'p-store'}>
                                <IconCartEmpty width={30}/>
                                <a href={encapsulateStoreReferralIdToUrl(selectedLanguage ? item.url[selectedLanguage] : item.url[L10N.si.value], getReferralId)}
                                   target="_blank"
                                   className={'p-url'}>{
                                    l10nStoreText(selectedLanguage)
                                }</a>
                            </div>
                            <p className={'p-description'}>
                                {selectedLanguage ? item.html_desc_good[selectedLanguage] : item.html_desc_good[L10N.si.value]}
                                <p>{selectedLanguage ? item.html_p[selectedLanguage] + " " : item.html_p[L10N.si.value] + " "}
                                    <a href={selectedLanguage ? item.video_l10n[selectedLanguage] : item.video_l10n[L10N.si.value]}
                                       target="_blank"
                                       className={'p-url'}
                                       style={{color: '#000E52'}}>{selectedLanguage ? L10N_APP_TEXTS[selectedLanguage].HERE : L10N_APP_TEXTS[L10N.si.value].HERE}</a>.
                                </p>
                            </p>

                        </div>
                    </div>
                );
            } else {
                return (
                    <div className={'results-container-inner'}>
                        <p className={'p-title extra-title'}>{selectedLanguage ? item.extra_title[selectedLanguage] : item.extra_title[L10N.si.value]}</p>
                        <p className={'p-title'}>{selectedLanguage ? item.name[selectedLanguage] : item.name[L10N.si.value]}</p>
                        <div className={'product left'}>
                            <img src={item.image} alt="side" className={'product-img'}/>
                        </div>
                        <div className={'product right'}>
                            <div className={'p-store'}>
                                <IconCartEmpty width={30}/>
                                <a href={encapsulateStoreReferralIdToUrl(selectedLanguage ? item.url[selectedLanguage] : item.url[L10N.si.value], getReferralId)}
                                   target="_blank"
                                   className={'p-url'}>{
                                    l10nStoreText(selectedLanguage)
                                }</a>
                            </div>
                            <p className={'p-description'}>
                                {selectedLanguage ? item.html_desc[selectedLanguage] : item.html_desc[L10N.si.value]}
                                <p>{selectedLanguage ? item.html_p[selectedLanguage] + " " : item.html_p[L10N.si.value] + " "}
                                    <a href={selectedLanguage ? item.video_l10n[selectedLanguage] : item.video_l10n[L10N.si.value]}
                                       target="_blank"
                                       className={'p-url'}
                                       style={{color: '#000E52'}}>{selectedLanguage ? L10N_APP_TEXTS[selectedLanguage].HERE : L10N_APP_TEXTS[L10N.si.value].HERE}</a>.
                                    <span> {l10nOrderItFromText(selectedLanguage)}</span> <a className={'p-url'}
                                                                                             style={{color: '#000E52'}}
                                                                                             href={encapsulateStoreReferralIdToUrl(selectedLanguage ? item.url[selectedLanguage] : item.url[L10N.si.value], window.referralID)}
                                                                                             target="_blank">{l10nOrderItFromStore(selectedLanguage)}.</a>

                                </p>
                            </p>

                        </div>
                    </div>
                );
            }
        };


        const renderLineChart = () => {

            const getL10nWorseBioSystem = (selectedLanguage) => {
                switch (selectedLanguage) {
                    case L10N.si.value:
                        return worstBioSystem;
                    case L10N.hr.value:
                        return BIO_SYSTEM_HR_ROOT[worstBioSystem];
                    case L10N.de.value:
                    case L10N.at.value:
                        return BIO_SYSTEM_DE_ROOT[worstBioSystem];
                    case L10N.it.value:
                        return BIO_SYSTEM_IT_ROOT[worstBioSystem];
                    case L10N.en.value:
                    case L10N.us.value:
                        return BIO_SYSTEM_EN_ROOT[worstBioSystem];
                    default:
                        return worstBioSystem;
                }
            };

            const getL10nLabelValueLimits = (datum) => {
                switch (selectedLanguage) {
                    case L10N.si.value:
                        return BIO_SYSTEM_NORMAL_LIMITS[datum.x];
                    case L10N.hr.value:
                        return BIO_SYSTEM_NORMAL_LIMITS[BIO_SYSTEM_HR_ROOT_REVERSED[datum.x]];
                    case L10N.de.value:
                    case L10N.at.value:
                        return BIO_SYSTEM_NORMAL_LIMITS[BIO_SYSTEM_DE_ROOT_REVERSED[datum.x]];
                    case L10N.it.value:
                        return BIO_SYSTEM_NORMAL_LIMITS[BIO_SYSTEM_IT_ROOT_REVERSED[datum.x]];
                    case L10N.en.value:
                    case L10N.us.value:
                        return BIO_SYSTEM_NORMAL_LIMITS[BIO_SYSTEM_EN_ROOT_REVERSED[datum.x]];
                }
            };

            return (
                <div className={'chart-container'}>
                    <img src={parseL10NSideLegend(selectedLanguage)} alt="side" className={'results-side-legend'}/>
                    <VictoryChart
                        height={430}
                        theme={VictoryTheme.material}
                    >
                        <VictoryLine
                            interpolation="monotoneX"
                            labels={({datum}) =>
                                Math.abs(datum.label)
                            }
                            style={{
                                data: {stroke: "#c43a31", strokeWidth: '3px', strokeLinecap: "round"},
                                parent: {border: "2px solid #ccc"},
                                labels: {
                                    fontSize: 16,
                                    fontWeight: 'bold',
                                    opacity: !init ? 0 : 1,
                                    fill: ({datum}) => Math.abs(datum.y) <= getL10nLabelValueLimits(datum) ? "#0090cd" : "#c43a31"
                                }
                            }}
                            data={results}
                            animate={{
                                duration: 300,
                                onLoad: {duration: 2600}
                            }}
                        />
                        <VictoryAxis dependentAxis
                                     domain={[0, -11]}
                                     tickValues={[0]}
                        />
                        <VictoryAxis
                            tickValues={parseL10NBodySystemTickValues(selectedLanguage)}
                            style={{
                                tickLabels: {
                                    angle: -28,
                                    padding: 18,
                                    fill: ({text}) => text === getL10nWorseBioSystem(selectedLanguage) ? "#c43a31" : '#222'
                                }
                            }}
                            margin={{top: 90, bottom: 60}}
                        />
                    </VictoryChart>
                </div>
            );
        }

        function Item(props) {
            return (

                <li key={selectedLanguage ? props.value.name[selectedLanguage] : props.value.name[L10N.si.value]}
                    onClick={() => {
                    }} className={'row product'}>
                    {renderProductRow(props.value)}
                </li>


            );
        }

        const renderRecommendedProducts = (products, title) => {
            if (!products || products?.length <= 0) {
                return <></>;
            }
            return (
                <>
                    <p className={'header-text'}
                       style={{color: "#0090CD", paddingTop: "30pt", paddingBottom: '10pt'}}>{title}</p>
                    <ul>
                        {products.map((item, index) => <Item key={index} value={item}/>)}
                    </ul>
                </>
            );
        };

        const renderBody = () => {
            return <div className={'body results'} style={{
                overflowY: 'scroll',
                overflowX: 'hidden'
            }}>
                <p className={'header-text'}
                   style={{color: "#0090CD", paddingTop: "20pt"}}>{L10N_APP_TEXTS[selectedLanguage].RESULT_TITLE}</p>
                {renderLineChart()}
                {renderResultsText()}

                {renderRecommendedProducts(recommendedProductsBasic, L10N_APP_TEXTS[selectedLanguage].BASIC_PROGRAM)}
                {renderRecommendedProducts(recommendedProductsOptimal, L10N_APP_TEXTS[selectedLanguage].OPTIMAL_PROGRAM)}

                {renderResultsAfterListings()}
            </div>
        }

        const renderFooter = () => {

            return <div className={'footer'}>
            </div>
        }

        return <animated.div ref={ulRef1} style={fadeInAnimationPage} className={'listings-page'}>
            {renderHeader()}
            {renderBody()}
            {renderFooter()}
        </animated.div>
    }


    return (
        <div>
            {renderResultsPage()}
        </div>
    );
}

export default Results;
