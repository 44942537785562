import photoLyfeImg from '../assets/products/si-img-sl93218.png';
import biomeCoreImg from '../assets/products/si-img-sl95178.png';
import bodyPrimeImg from '../assets/products/si-img-sl74915.png';
import proArgiBoosterImg from '../assets/products/si-img-sl96129.png';
import v3Img from '../assets/products/sl93962-main-13026.png';
import detoxImg from '../assets/products/sl96389-img-100000641.png';
import omega3Img from '../assets/products/sl96147-main-12525.png';
import e9Img from '../assets/products/si-img-sl93422.png';
import fl3xImg from '../assets/products/si-img-sl95588.png';
import collagenImg from '../assets/products/si-img-sl95308.png';
import d3Img from '../assets/products/sl96145-main-12549.png';
import proArgiImg from '../assets/products/si-img-sl93953.png';
import synergyImg from '../assets/products/si-img-sl74915.png';
import dnkImg from '../assets/products/si-img-sl95564.png';
import purifyImg from '../assets/products/si-img-sl95203.png';
import healthCareImg from '../assets/products/si-img-sl9516622.png';
import mistifyImg from '../assets/products/si-img-sl9516624.png';
import silverImg from '../assets/products/si-img-sl95945.png';
import sleepWellImg from '../assets/products/sl96562-img-100000954.png';

export const GENDER = {
    female: 'f',
    male: 'm'
}
export const ANSWERS = {
    yes: 'Da',
    no: 'Ne',
    male: "Moški",
    female: 'Ženska'
}

export const STATE = {
    home: 'start',
    mid: 'mid',
    end: 'end'
};


export const getStateHeaderColor = (option) => {
    if (!option) {
        return 'transparent'
    }
    switch (option) {
        case STATE.start:
            return '#e9e6e6';
        case STATE.mid:
        case STATE.end:
            return '#0090CD';
    }
}

export const L10N_APP_TEXTS = {

    EN: {
        HOME_WELCOME: 'Hello',
        YOUR_USERNAME_TITLE: 'Your username',
        YOUR_EMAIL_TITLE: 'Your e-mail',
        YOUR_REFERRAL_ID: 'Enter your referral (Synergy) ID',
        YOUR_ANALYSIS_URL: 'Your referral lifestyle analysis link',
        FORGOT_PASSWORD: 'Forgot password? Reset it',
        APP_TITLE_1: 'SYNERGY',
        APP_TITLE_2: 'LIFESTYLE ANALYSIS',
        APP_PROMO: 'Your path to optimal health',
        LOGIN_WELCOME_BACK: 'Welcome back',
        LOGIN_EMAIL: 'Enter your username',
        LOGIN_PASSWORD: 'Enter password',
        LOGIN_REMEMBER: 'Remember me',
        LOGIN_CREATE_ACCOUNT: 'Don\'t have an account yet? Register here',
        LOGIN_BUTTON: 'SIGN IN',
        REGISTER_TITLE: 'Join us!',
        REGISTER_NAME: 'Enter your name',
        REGISTER_BUTTON: 'REGISTER',
        REGISTER_ALREADY_HAVE_ACCOUNT: 'Already have an account? Sign in',
        RESULT_TITLE: 'YOUR RESULTS',
        PLACEHOLDER_EMAIL: 'E-mail',
        PLACEHOLDER_PASSWORD: 'Password',
        SAVE: 'SAVE',
        COPIED: 'Link copied',
        ANALYSIS_INSTRUCTIONS: 'Answer the questions with YES or NO',
        ANALYSIS_INFO: 'Synergy Lifestyle Analysis assesses the state of your body systems and makes appropriate recommendations based on your answers.',
        ANALYSIS_START: ' START THE QUESTIONNAIRE',
        YES: 'YES',
        NO: 'NO',
        ANALYSIS_GENERATE_RESULT: 'GENERATE RESULT',
        ANALYSIS_SHARE_RESULTS: 'For a more detailed explanation of your analysis results and more information about Synergy products and programs, you can share the analysis results with the person who sent you the analysis. Click on the left square for this option.',
        SUBSCRIBE: 'SUBSCRIBE',
        SUBSCRIBE_JOIN: 'Enroll today!',
        RESET_PASSWORD: 'RESET PASSWORD',
        RESET_PASSWORD_TITLE: 'Reset forgotten password',
        GENERATE_RESULT_ERROR: 'Error generating result. Please check and try entering your email again.',
        DATA_UPDATED: 'Data successfully updated.',
        PASSWORD_RESET_SUCCESS: 'Password updated successfully. Please login again.',
        NAME: 'Name',
        SURNAME: 'Surname',
        NEED_SUPPORT: 'Need support?',
        STATS: 'Your Stats',
        COMPLETED: 'COMPLETED',
        SHARED_BACK: 'SHARED BACK',
        SHARED_PERCENTAGE: 'SHARED',
        BASIC_PROGRAM: 'BASIC PROGRAM',
        OPTIMAL_PROGRAM: 'OPTIMAL PROGRAM',
        ENTER_EMAIL: 'Enter your e-mail',
        HERE: 'here',
        ANALYSIS_RESULT: 'Congratulations, you have answered all the questions!\n' +
            '\n' +
            '\n' +
            '\n' +
            'We respect your privacy. The results of the analysis are not saved and are accessible only to you through the web connection, or with your consent, to the person who sent you the link to the analysis. Your answers (personal data) are used only for the purpose of preparing the analysis results, they are not collected, processed, or forwarded anywhere.',
        SUBSCRIPTION_INACTIVE: <p>Your subscription is currently <span className="p-red">inactive</span>.</p>,
        SUBSCRIPTION_BENEFITS: <p>Activate membership to gain benefits of the lifestyle analysistool.</p>,
        JOIN_NOW: 'Join now',
        CANCEL_SUBSCRIPTION: 'Cancel monthly subscription',
        CANCEL_SUBSCRIPTION_CONFIRM: 'Are you sure? Click to confirm.',
        SUBSCRIPTION_JOIN: 'Join now.',
        SUBSCRIPTION_CANCELED: 'Your monthly subscription and its benefits expire on:',
    },
    US: {
        HOME_WELCOME: 'Hello',
        YOUR_USERNAME_TITLE: 'Your username',
        YOUR_EMAIL_TITLE: 'Your e-mail',
        YOUR_REFERRAL_ID: 'Enter your referral (Synergy) ID',
        YOUR_ANALYSIS_URL: 'Your referral lifestyle analysis link',
        FORGOT_PASSWORD: 'Forgot password? Reset it',
        APP_TITLE_1: 'SYNERGY',
        APP_TITLE_2: 'LIFESTYLE ANALYSIS',
        APP_PROMO: 'Your path to optimal health',
        LOGIN_WELCOME_BACK: 'Welcome back',
        LOGIN_EMAIL: 'Enter your username',
        LOGIN_PASSWORD: 'Enter password',
        LOGIN_REMEMBER: 'Remember me',
        LOGIN_CREATE_ACCOUNT: 'Don\'t have an account yet? Register here',
        LOGIN_BUTTON: 'SIGN IN',
        REGISTER_TITLE: 'Join us!',
        REGISTER_NAME: 'Enter your name',
        REGISTER_BUTTON: 'REGISTER',
        REGISTER_ALREADY_HAVE_ACCOUNT: 'Already have an account? Sign in',
        RESULT_TITLE: 'YOUR RESULTS',
        PLACEHOLDER_EMAIL: 'E-mail',
        PLACEHOLDER_PASSWORD: 'Password',
        SAVE: 'SAVE',
        COPIED: 'Link copied',
        ANALYSIS_INSTRUCTIONS: 'Answer the questions with YES or NO',
        ANALYSIS_START: ' START THE QUESTIONNAIRE',
        YES: 'YES',
        NO: 'NO',
        ANALYSIS_GENERATE_RESULT: 'GENERATE RESULT',
        ANALYSIS_SHARE_RESULTS: 'For a more detailed explanation of your analysis results and more information about Synergy products and programs, you can share the analysis results with the person who sent you the analysis. Click on the left square for this option.',
        SUBSCRIBE: 'SUBSCRIBE',
        SUBSCRIBE_JOIN: 'Enroll today!',
        RESET_PASSWORD: 'RESET PASSWORD',
        RESET_PASSWORD_TITLE: 'Reset forgotten password',
        GENERATE_RESULT_ERROR: 'Error generating result. Please check and try entering your email again.',
        DATA_UPDATED: 'Data successfully updated.',
        PASSWORD_RESET_SUCCESS: 'Password updated successfully. Please login again.',
        NAME: 'Name',
        SURNAME: 'Surname',
        NEED_SUPPORT: 'Need support?',
        STATS: 'Your Stats',
        COMPLETED: 'COMPLETED',
        SHARED_BACK: 'SHARED BACK',
        SHARED_PERCENTAGE: 'SHARED',
        BASIC_PROGRAM: 'BASIC PROGRAM',
        OPTIMAL_PROGRAM: 'OPTIMAL PROGRAM',
        ENTER_EMAIL: 'Enter your e-mail',
        HERE: 'here',
        ANALYSIS_RESULT: 'Congratulations, you have answered all the questions!\n' +
            '\n' +
            '\n' +
            '\n' +
            'We respect your privacy. The results of the analysis are not saved and are accessible only to you through the web connection, or with your consent, to the person who sent you the link to the analysis. Your answers (personal data) are used only for the purpose of preparing the analysis results, they are not collected, processed, or forwarded anywhere.',
        ANALYSIS_INFO: 'The Synergy lifestyle analysis, based on the given answers, evaluates the condition of your bodily systems and provides appropriate recommendations. \n' +
            '\n' +
            'The analysis was prepared by doctors, scientists, and other medical professionals at the Hughes Center for Research and Development with the aim of giving everyone the opportunity to choose the dietary supplements that, based on their condition, actually support them and enable them to feel better.',
        SUBSCRIPTION_INACTIVE: <p>Your subscription is currently <span className="p-red">inactive</span>.</p>,
        SUBSCRIPTION_BENEFITS: <p>Activate membership to gain benefits of the lifestyle checklist tool.</p>,
        JOIN_NOW: 'Join now',
        CANCEL_SUBSCRIPTION: 'Cancel monthly subscription',
        CANCEL_SUBSCRIPTION_CONFIRM: 'Are you sure? Press to confirm.',
        SUBSCRIPTION_JOIN: 'Join now.',
        SUBSCRIPTION_CANCELED: 'Your canceled monthly subscription and its benefits expire on:',
    },
    IT: {
        HOME_WELCOME: 'Ciao',
        YOUR_USERNAME_TITLE: 'Il tuo nome utente',
        YOUR_EMAIL_TITLE: 'La tua email',
        YOUR_REFERRAL_ID: 'Inserisci l\'ID di riferimento',
        YOUR_ANALYSIS_URL: 'Il tuo link di analisi dello stile di vita di riferimento',
        FORGOT_PASSWORD: 'Ha dimenticato la password? Reimpostalo',
        APP_TITLE_1: 'ANALISI',
        APP_TITLE_2: 'DELLO STILE DI VITA',
        APP_PROMO: 'Il tuo percorso verso una salute ottimale',
        LOGIN_WELCOME_BACK: 'Ben tornato',
        LOGIN_EMAIL: 'Inserisci il tuo nome utente',
        LOGIN_PASSWORD: 'Inserire la password',
        LOGIN_REMEMBER: 'Ricordati di me',
        LOGIN_CREATE_ACCOUNT: 'Non hai ancora un conto? Registrati qui',
        LOGIN_BUTTON: 'ACCEDERE',
        REGISTER_TITLE: 'Unisciti a noi',
        REGISTER_NAME: 'Inserisci il tuo nome',
        REGISTER_BUTTON: 'REGISTRATI',
        REGISTER_ALREADY_HAVE_ACCOUNT: 'Hai già un account? Iscrizione',
        RESULT_TITLE: 'IL TUO RISULTATO',
        PLACEHOLDER_EMAIL: 'E-mail',
        PLACEHOLDER_PASSWORD: 'Parola d\'ordine',
        SAVE: 'SALVA',
        COPIED: 'Link copiato',
        ANALYSIS_INSTRUCTIONS: 'Rispondi alle domande con SI o NO',
        ANALYSIS_START: 'INIZIA CON UN QUESTIONARIO',
        YES: 'SI',
        NO: 'NO',
        ANALYSIS_GENERATE_RESULT: 'GENERARE UN RISULTATO',
        ANALYSIS_SHARE_RESULTS: 'Per una spiegazione più dettagliata della tua analisi sullo stile di vita e informazioni sulle soluzioni Synergy, puoi condividere i tuoi risultati con la persona che ti ha inviato l\'analisi.',
        SUBSCRIBE_JOIN: 'Diventare membro!',
        SUBSCRIBE: 'ACQUISTO ABBONAMENTO',
        RESET_PASSWORD: 'RESETTA LA PASSWORD',
        RESET_PASSWORD_TITLE: 'Reimposta password dimenticata',
        GENERATE_RESULT_ERROR: 'Errore durante la generazione del risultato. Controlla e prova a inserire di nuovo la tua email.',
        DATA_UPDATED: 'Dati aggiornati con successo.',
        PASSWORD_RESET_SUCCESS: 'Password aggiornata correttamente. Per favore esegui l\'accesso di nuovo.',
        NAME: 'Nome',
        SURNAME: 'Surname',
        NEED_SUPPORT: 'Hai bisogno di supporto?',
        STATS: 'Le tue statistiche',
        COMPLETED: 'COMPLETATO',
        SHARED_BACK: 'CONDIVISO INDIETRO',
        SHARED_PERCENTAGE: 'CONDIVISO',
        BASIC_PROGRAM: 'PROGRAMMA BASE',
        OPTIMAL_PROGRAM: 'PROGRAMMA OTTIMALE',
        ENTER_EMAIL: 'Inserisci la tua email',
        HERE: 'qui',
        ANALYSIS_RESULT: 'Congratulazioni, hai risposto a tutte le domande.\n' +
            '\n' +
            '\n' +
            '\n' +
            'Rispettiamo la tua privacy. I risultati dell\'analisi non vengono salvati e sono accessibili solo a te tramite la connessione web, o con il tuo consenso, alla persona che ti ha inviato il link all\'analisi. Le tue risposte (dati personali) vengono utilizzate solo allo scopo di preparare i risultati dell\'analisi, non vengono raccolti, elaborati o inoltrati da nessuna parte.',
        ANALYSIS_INFO: 'L\'analisi dello stile di vita Synergy, in base alle risposte fornite, valuta lo stato dei tuoi sistemi corporei e fornisce raccomandazioni appropriate. \n' +
            '\n' +
            'L\'analisi è stata preparata da medici, scienziati e altri professionisti del settore medico presso il Centro Hughes per la Ricerca e lo Sviluppo con l\'obiettivo di dare a tutti la possibilità di scegliere gli integratori alimentari che, in base alla loro condizione, li supportano effettivamente e consentono loro di sentirsi meglio.',
        SUBSCRIPTION_INACTIVE: <p>Il tuo abbonamento è attualmente <span className="p-red">inattivo</span>.</p>,
        SUBSCRIPTION_BENEFITS: <p>Attiva l'iscrizione per usufruire dei vantaggi dello strumento della lista di controllo dello stile di vita.</p>,
        JOIN_NOW: 'Iscriviti adesso',
        CANCEL_SUBSCRIPTION: 'Cancella il tuo abbonamento mensile',
        CANCEL_SUBSCRIPTION_CONFIRM: 'Sei sicuro? Premere per confermare.',
        SUBSCRIPTION_JOIN: 'Giuntura.',
        SUBSCRIPTION_CANCELED: 'Hai annullato il tuo abbonamento mensile e i suoi vantaggi, che scade il:',
    },
    DE: {
        HOME_WELCOME: 'Hallo',
        YOUR_USERNAME_TITLE: 'Dein Benutzername',
        YOUR_EMAIL_TITLE: 'Deine E-Mail',
        YOUR_REFERRAL_ID: 'Geben Sie Ihre Empfehlungs-ID (Synergy) ein',
        YOUR_ANALYSIS_URL: 'Ihr Empfehlungs-Lifestyle-Analyse-Link',
        FORGOT_PASSWORD: 'Passwort vergessen? Setzen Sie es zurück',
        APP_TITLE_1: 'LIFESTYLE',
        APP_TITLE_2: 'ANALYSE',
        APP_PROMO: 'Ihr Weg zu optimaler Gesundheit',
        LOGIN_WELCOME_BACK: 'Willkommen zurück',
        LOGIN_EMAIL: 'Geben Sie Ihren Benutzernamen ein',
        LOGIN_PASSWORD: 'Passwort eingeben',
        LOGIN_REMEMBER: 'Erinnere dich an mich',
        LOGIN_CREATE_ACCOUNT: 'Sie haben noch kein Konto? Hier registrieren',
        LOGIN_BUTTON: 'ANWENDUNG',
        REGISTER_TITLE: 'Begleiten Sie uns',
        REGISTER_NAME: 'Gib deinen Namen ein',
        REGISTER_BUTTON: 'REGISTRIEREN',
        REGISTER_ALREADY_HAVE_ACCOUNT: 'Sie haben bereits ein Konto? Anmelden',
        RESULT_TITLE: 'DEIN ERGEBNIS',
        PLACEHOLDER_EMAIL: 'E-mail',
        PLACEHOLDER_PASSWORD: 'Passwort',
        SAVE: 'SPEICHERN',
        COPIED: 'Link kopiert',
        ANALYSIS_INSTRUCTIONS: 'Beantworten Sie die Fragen mit JA oder NEIN.',
        ANALYSIS_START: 'BEGINNEN SIE MIT EINEM FRAGEBOGEN',
        YES: 'JA',
        NO: 'NEIN',
        ANALYSIS_GENERATE_RESULT: 'EIN ERGEBNIS ERZEUGEN',
        ANALYSIS_SHARE_RESULTS: 'Für eine ausführlichere Erläuterung Ihrer Analyseergebnisse und weitere Informationen über Synergy-Produkte und -Programme können Sie die Analyseergebnisse mit der Person teilen, die Ihnen die Analyse gesendet hat. Klicken Sie für diese Option auf das linke Quadrat.',
        SUBSCRIBE_JOIN: 'Mitglied werden!',
        SUBSCRIBE: 'ABO-KAUF',
        RESET_PASSWORD: 'PASSWORT ZURÜCKSETZEN',
        RESET_PASSWORD_TITLE: 'Vergessenes Passwort zurücksetzen',
        GENERATE_RESULT_ERROR: 'Fehler beim Generieren des Ergebnisses. Bitte überprüfen Sie Ihre E-Mail-Adresse und versuchen Sie es erneut.',
        DATA_UPDATED: 'Daten erfolgreich aktualisiert.',
        PASSWORD_RESET_SUCCESS: 'Passwort erfolgreich aktualisiert. Bitte melden Sie sich erneut an.',
        NAME: 'Name',
        SURNAME: 'Surname',
        NEED_SUPPORT: 'Brauche Unterstützung?',
        STATS: 'Ihre Statistiken',
        COMPLETED: 'ABGESCHLOSSEN',
        SHARED_BACK: 'ZURÜCK GETEILT',
        SHARED_PERCENTAGE: 'GETEILT',
        BASIC_PROGRAM: 'GRUNDPROGRAMM',
        OPTIMAL_PROGRAM: 'OPTIMALES PROGRAMM',
        ENTER_EMAIL: 'Geben sie ihre E-Mail Adresse ein',
        HERE: 'hier',
        ANALYSIS_RESULT: 'Herzlichen Glückwunsch, Sie haben alle Fragen beantwortet.\n' +
            '\n' +
            '\n' +
            '\n' +
            'Wir respektieren Ihre Privatsphäre. Die Ergebnisse der Analyse werden nicht gespeichert und sind nur für Sie über die Webverbindung zugänglich, oder mit Ihrer Zustimmung für die Person, die Ihnen den Link zur Analyse geschickt hat. Ihre Antworten (personenbezogene Daten) werden nur zum Zweck der Erstellung der Analyseergebnisse verwendet, sie werden nicht gesammelt, verarbeitet oder weitergeleitet.',
        ANALYSIS_INFO: 'Die Synergy Lebensstil-Analyse bewertet auf Basis der gegebenen Antworten den Zustand Ihrer Körpersysteme und gibt entsprechende Empfehlungen ab. \n' +
            '\n' +
            'Die Analyse wurde von Ärzten, Wissenschaftlern und anderen medizinischen Fachleuten am Hughes-Zentrum für Forschung und Entwicklung erstellt, mit dem Ziel, jedem die Möglichkeit zu geben, die Nahrungsergänzungsmittel auszuwählen, die ihn, abhängig von seinem Zustand, tatsächlich unterstützen und ihm ermöglichen, sich besser zu fühlen.',
        SUBSCRIPTION_INACTIVE: <p>Ihr Abonnement ist derzeit <span className="p-red">inaktiv</span>.</p>,
        SUBSCRIPTION_BENEFITS: <p>Aktivieren Sie die Mitgliedschaft, um die Vorteile des Lifestyle-Checklisten-Tools zu
            nutzen.</p>,
        JOIN_NOW: 'Jetzt beitreten',
        CANCEL_SUBSCRIPTION: 'Kündigen Sie Ihr monatliches Abonnement',
        CANCEL_SUBSCRIPTION_CONFIRM: 'Bist du dir sicher? Drücken Sie zur Bestätigung.',
        SUBSCRIPTION_JOIN: 'Verbinden.',
        SUBSCRIPTION_CANCELED: 'Ihr monatliches Abonnement und seine Vorteile laufen ab am:',
    },
    AT: {
        HOME_WELCOME: 'Hallo',
        YOUR_USERNAME_TITLE: 'Dein Benutzername',
        YOUR_EMAIL_TITLE: 'Deine E-Mail',
        YOUR_REFERRAL_ID: 'Geben Sie Ihre Empfehlungs-ID (Synergy) ein',
        YOUR_ANALYSIS_URL: 'Ihr Empfehlungs-Lifestyle-Analyse-Link',
        FORGOT_PASSWORD: 'Passwort vergessen? Setzen Sie es zurück',
        APP_TITLE_1: 'LIFESTYLE',
        APP_TITLE_2: 'ANALYSE',
        APP_PROMO: 'Ihr Weg zu optimaler Gesundheit',
        LOGIN_WELCOME_BACK: 'Willkommen zurück',
        LOGIN_EMAIL: 'Geben Sie Ihren Benutzernamen ein',
        LOGIN_PASSWORD: 'Passwort eingeben',
        LOGIN_REMEMBER: 'Erinnere dich an mich',
        LOGIN_CREATE_ACCOUNT: 'Sie haben noch kein Konto? Hier registrieren',
        LOGIN_BUTTON: 'ANWENDUNG',
        REGISTER_TITLE: 'Begleiten Sie uns',
        REGISTER_NAME: 'Gib deinen Namen ein',
        REGISTER_BUTTON: 'REGISTRIEREN',
        REGISTER_ALREADY_HAVE_ACCOUNT: 'Sie haben bereits ein Konto? Anmelden',
        RESULT_TITLE: 'DEIN ERGEBNIS',
        PLACEHOLDER_EMAIL: 'E-mail',
        PLACEHOLDER_PASSWORD: 'Passwort',
        SAVE: 'SPEICHERN',
        COPIED: 'Link kopiert',
        ANALYSIS_INSTRUCTIONS: 'Beantworten Sie die Fragen mit JA oder NEIN.',
        ANALYSIS_START: 'BEGINNEN SIE MIT EINEM FRAGEBOGEN',
        YES: 'JA',
        NO: 'NEIN',
        ANALYSIS_GENERATE_RESULT: 'EIN ERGEBNIS ERZEUGEN',
        ANALYSIS_SHARE_RESULTS: 'Für eine detailliertere Erläuterung Ihrer Lebensstilanalyse und Informationen zu Synergy-Lösungen können Sie Ihre Ergebnisse mit der Person teilen, die Ihnen die Analyse gesendet hat.',
        SUBSCRIBE_JOIN: 'Mitglied werden!',
        SUBSCRIBE: 'ABO-KAUF',
        RESET_PASSWORD: 'PASSWORT ZURÜCKSETZEN',
        RESET_PASSWORD_TITLE: 'Vergessenes Passwort zurücksetzen',
        GENERATE_RESULT_ERROR: 'Fehler beim Generieren des Ergebnisses. Bitte überprüfen Sie Ihre E-Mail-Adresse und versuchen Sie es erneut.',
        DATA_UPDATED: 'Daten erfolgreich aktualisiert.',
        PASSWORD_RESET_SUCCESS: 'Passwort erfolgreich aktualisiert. Bitte melden Sie sich erneut an.',
        NAME: 'Nome',
        SURNAME: 'Surname',
        NEED_SUPPORT: 'Brauche Unterstützung?',
        STATS: 'Ihre Statistiken',
        COMPLETED: 'ABGESCHLOSSEN',
        SHARED_BACK: 'ZURÜCK GETEILT',
        SHARED_PERCENTAGE: 'GETEILT',
        BASIC_PROGRAM: 'GRUNDPROGRAMM',
        OPTIMAL_PROGRAM: 'OPTIMALES PROGRAMM',
        ENTER_EMAIL: 'Geben sie ihre E-Mail Adresse ein',
        HERE: 'hier',
        ANALYSIS_RESULT: 'Herzlichen Glückwunsch, Sie haben alle Fragen beantwortet.\n' +
            '\n' +
            '\n' +
            '\n' +
            'Wir respektieren Ihre Privatsphäre. Die Ergebnisse der Analyse werden nicht gespeichert und sind nur für Sie über die Webverbindung zugänglich, oder mit Ihrer Zustimmung für die Person, die Ihnen den Link zur Analyse geschickt hat. Ihre Antworten (personenbezogene Daten) werden nur zum Zweck der Erstellung der Analyseergebnisse verwendet, sie werden nicht gesammelt, verarbeitet oder weitergeleitet.',
        ANALYSIS_INFO: 'Die Synergy Lebensstil-Analyse bewertet auf Basis der gegebenen Antworten den Zustand Ihrer Körpersysteme und gibt entsprechende Empfehlungen ab. \n' +
            '\n' +
            'Die Analyse wurde von Ärzten, Wissenschaftlern und anderen medizinischen Fachleuten am Hughes-Zentrum für Forschung und Entwicklung erstellt, mit dem Ziel, jedem die Möglichkeit zu geben, die Nahrungsergänzungsmittel auszuwählen, die ihn, abhängig von seinem Zustand, tatsächlich unterstützen und ihm ermöglichen, sich besser zu fühlen.',
        SUBSCRIPTION_INACTIVE: <p>Ihr Abonnement ist derzeit <span className="p-red">inaktiv</span>.</p>,
        SUBSCRIPTION_BENEFITS: <p>Aktivieren Sie die Mitgliedschaft, um die Vorteile des Lifestyle-Checklisten-Tools zu
            nutzen.</p>,
        JOIN_NOW: 'Jetzt beitreten',
        CANCEL_SUBSCRIPTION: 'Kündigen Sie Ihr monatliches Abonnement',
        CANCEL_SUBSCRIPTION_CONFIRM: 'Bist du dir sicher? Drücken Sie zur Bestätigung.',
        SUBSCRIPTION_JOIN: 'Verbinden.',
        SUBSCRIPTION_CANCELED: 'Ihr monatliches Abonnement und seine Vorteile laufen ab am:',
    },
    SI: {
        HOME_WELCOME: 'Pozdravljeni',
        YOUR_USERNAME_TITLE: 'Vaše uporabniško ime',
        YOUR_EMAIL_TITLE: 'Vaš e-mail',
        YOUR_REFERRAL_ID: 'Vpišite referral (Synergy) ID',
        YOUR_ANALYSIS_URL: 'Vaš referral link za lifestyle analizo',
        FORGOT_PASSWORD: 'Pozabljeno geslo? Ponastavite ga',
        APP_TITLE_1: 'ANALIZA',
        APP_TITLE_2: 'ŽIVLJENJSKEGA STILA',
        APP_PROMO: 'Vaša pot do optimalnega zdravja',
        LOGIN_WELCOME_BACK: 'Dobrodošel nazaj',
        LOGIN_EMAIL: 'Vpišite uporabniško ime',
        LOGIN_PASSWORD: 'Vpišite geslo',
        LOGIN_REMEMBER: 'Zapomni si me',
        LOGIN_CREATE_ACCOUNT: 'Še nimate računa? Registrirajte se',
        LOGIN_BUTTON: 'PRIJAVA',
        REGISTER_TITLE: 'Pridruži se!',
        REGISTER_NAME: 'Vpišite vaše ime',
        REGISTER_BUTTON: 'REGISTRACIJA',
        REGISTER_ALREADY_HAVE_ACCOUNT: 'Že imate račun? Vpišite se',
        RESULT_TITLE: 'VAŠ REZULTAT',
        PLACEHOLDER_EMAIL: 'E-pošta',
        PLACEHOLDER_PASSWORD: 'Geslo',
        SAVE: 'SHRANI',
        COPIED: 'Povezava kopirana',
        ANALYSIS_INSTRUCTIONS: 'Na vprašanja odgovarjajte z DA ali NE.',
        ANALYSIS_START: 'PRIČNI Z VPRAŠALNIKOM',
        YES: 'DA',
        NO: 'NE',
        ANALYSIS_GENERATE_RESULT: 'GENERIRAJ REZULTAT',
        ANALYSIS_SHARE_RESULTS: 'Za bolj podrobno razlago vaših rezultatov analize in več informacij o Synergy izdelkih in programih, lahko rezultate analize delite z osebo, ki vam je analizo posredovala. Za to možnost kliknite na levi kvadratek.',
        SUBSCRIBE_JOIN: 'Postanite član!',
        SUBSCRIBE: 'NAKUP NAROČNINE',
        RESET_PASSWORD: 'PONASTAVI GESLO',
        RESET_PASSWORD_TITLE: 'Ponastavitev gesla',
        GENERATE_RESULT_ERROR: 'Napaka pri generiranju rezultata. Prosimo preverite in poskusite znova vnesti vašo e-pošto.',
        DATA_UPDATED: 'Podatki uspešno posodobljeni.',
        PASSWORD_RESET_SUCCESS: 'Geslo je uspešno posodobljeno. Prosimo, da se ponovno prijavite.',
        NAME: 'Ime',
        SURNAME: 'Priimek',
        NEED_SUPPORT: 'Potrebujete pomoč?',
        STATS: 'Vaša Statistika',
        COMPLETED: 'IZPOLNJENI',
        SHARED_BACK: 'DELJENO NAZAJ',
        SHARED_PERCENTAGE: 'DELJENO',
        BASIC_PROGRAM: 'OSNOVNI PROGRAM',
        OPTIMAL_PROGRAM: 'OPTIMALNI PROGRAM',
        ENTER_EMAIL: 'Vpišite svoj e-mail',
        HERE: 'tukaj',
        ANALYSIS_RESULT: 'Čestitamo, odgovorili ste na vsa vprašanja!\n' +
            '                    \n' +
            '                    Spoštujemo vašo zasebnost. Rezultati analize se ne shranjujejo in so preko\n' +
            '                    spletne povezave dostopni samo vam, oz. z vašim privoljenjem še osebi, ki vam je povezavo do analize\n' +
            '                    poslala. Vaši odgovori (osebni podatki) se uporabijo samo z namenom priprave rezultata analize, se\n' +
            '                    ne zbirajo, ne obdelujejo in tudi ne posredujejo nikamor.',
        ANALYSIS_INFO: 'Synergy analiza življenjskega stila, na podlagi podanih odgovorov, oceni stanje vaših telesnih sistemov in poda ustrezna priporočila.',
        SUBSCRIPTION_INACTIVE: <p>Vaša trenutna naročnina je <span className="p-red">neaktivna</span>.</p>,
        SUBSCRIPTION_BENEFITS: <p>Aktivirajte članstvo, in pridobite vpogled digitalnega prodajnega kataloga ter drugih
            prednosti digitalnega pomočnika analize življenjskega stila.</p>,
        JOIN_NOW: 'Pridruži se.',
        CANCEL_SUBSCRIPTION: 'Prekini mesečno naročnino',
        CANCEL_SUBSCRIPTION_CONFIRM: 'Ste prepričani? Pritisnite za potrditev.',
        SUBSCRIPTION_JOIN: 'Pridruži se.',
        SUBSCRIPTION_CANCELED: 'Prekinili ste mesečno naročnino in njene ugodnosti, ki potečejo dne:',
    },
    HR: {
        HOME_WELCOME: 'Zdravo',
        YOUR_USERNAME_TITLE: 'Vaše korisničko ime',
        YOUR_EMAIL_TITLE: 'Vaš e-mail',
        YOUR_REFERRAL_ID: 'Unesite referral (Synergy) ID',
        YOUR_ANALYSIS_URL: 'Vaš referral link za lifestyle analizu',
        FORGOT_PASSWORD: 'Zaboravili ste lozinku? Resetirajte ju',
        APP_TITLE_1: 'ANALIZA',
        APP_TITLE_2: 'NAČINA ŽIVOTA',
        APP_PROMO: 'Vaš put do optimalnog zdravlja',
        LOGIN_WELCOME_BACK: 'Dobrodošao natrag',
        LOGIN_EMAIL: 'Unesite svoje korisničko ime',
        LOGIN_PASSWORD: 'Unesite lozinku',
        LOGIN_REMEMBER: 'Zapamti me',
        LOGIN_CREATE_ACCOUNT: 'Još nemate račun? Registrirajte se ovdje',
        LOGIN_BUTTON: 'PRIJAVA',
        REGISTER_TITLE: 'Pridružite se',
        REGISTER_NAME: 'Unesite svoje ime',
        REGISTER_BUTTON: 'REGISTRACIJA',
        REGISTER_ALREADY_HAVE_ACCOUNT: 'Već imate račun? Prijavite se',
        RESULT_TITLE: 'VAŠ REZULTAT',
        PLACEHOLDER_EMAIL: 'E-mail',
        PLACEHOLDER_PASSWORD: 'Lozinka',
        SAVE: 'UŠTEDJETI',
        COPIED: 'Veza je kopirana',
        ANALYSIS_INSTRUCTIONS: 'Na pitanja odgovorite s DA ili NE.',
        ANALYSIS_START: 'POČNITE S UPITNIKOM',
        YES: 'DA',
        NO: 'NE',
        ANALYSIS_GENERATE_RESULT: 'GENERIRAJTE REZULTAT',
        ANALYSIS_SHARE_RESULTS: 'Za detaljnije objašnjenje rezultata vaše analize i više informacija o Synergy proizvodima i programima, rezultate analize možete podijeliti s osobom koja vam je poslala analizu. Kliknite na lijevi kvadrat za ovu opciju.',
        SUBSCRIBE: 'KUPNJA PRETPLATE',
        SUBSCRIBE_JOIN: 'Pridružite se klubu!',
        RESET_PASSWORD: ' RESETIRANJE LOZINKE',
        RESET_PASSWORD_TITLE: 'Resetirajte zaboravljenu lozinku',
        GENERATE_RESULT_ERROR: 'Pogreška pri generiranju rezultata. Provjerite i pokušajte ponovno unijeti svoju e-poštu.',
        DATA_UPDATED: 'Podaci su uspješno ažurirani.',
        PASSWORD_RESET_SUCCESS: 'Lozinka je uspješno ažurirana. Molimo prijavite se ponovno.',
        NAME: 'Ime',
        NEED_SUPPORT: 'Trebate podršku?',
        STATS: 'Vaša Statistika',
        COMPLETED: 'DOVRŠENO',
        SHARED_BACK: 'PODIJELJENO NATRAG',
        SHARED_PERCENTAGE: 'PODIJELJENO',
        BASIC_PROGRAM: 'OSNOVNI PROGRAM',
        OPTIMAL_PROGRAM: 'OPTIMALAN PROGRAM',
        ENTER_EMAIL: 'Upišite svoju e-mail adresu',
        HERE: 'ovdje',
        ANALYSIS_RESULT: 'Čestitamo, odgovorili ste na sva pitanja!\n' +
            '\n' +
            'Poštujemo vašu privatnost. Rezultati analize se ne spremaju i dostupni su samo vama, odn uz vaš pristanak osobi koja vam je poslala poveznicu na analizu. Vaši odgovori (osobni podaci) koriste se samo u svrhu pripreme rezultata analize, ne prikupljaju se, obrađuju niti se prosljeđuju bilo gdje.',
        ANALYSIS_INFO: 'Na temelju dobivenih odgovora Synergy lifestyle analiza procjenjuje stanje vaših tjelesnih sustava i daje odgovarajuće preporuke. Analizu su pripremili liječnici, znanstvenici i drugi stručnjaci medicinske struke Hughes Centra za istraživanje i razvoj s ciljem da svatko ima priliku za sebe odabrati one dodatke prehrani koji mu, ovisno o stanju, zapravo pomažu, da se osjećaju bolje.',
        SUBSCRIPTION_INACTIVE: <p>Vaša pretplata trenutno <span className="p-red">nije aktivna</span>.</p>,
        SUBSCRIPTION_BENEFITS: <p>Aktivirajte svoje članstvo i ostvarite uvid u digitalni prodajni katalog i ostale
            pogodnosti digitalnog asistenta za analizu načina života.</p>,
        JOIN_NOW: 'Pridružite se',
        CANCEL_SUBSCRIPTION: 'Otkažite svoju mjesečnu pretplatu',
        CANCEL_SUBSCRIPTION_CONFIRM: 'Jeste li sigurni? Kliknite za potvrdu.',
        SUBSCRIPTION_JOIN: 'Pridružite se sada.',
        SUBSCRIPTION_CANCELED: 'Otkazali ste svoju mjesečnu pretplatu i njezine pogodnosti, koja istječe:',
    },
};

export const PRODUCTS = {
    biomeActives: {
        extra_title: {
            SI: '',
            EN: '',
            HR: '',
            IT: '',
            DE: '',
            AT: '',
            US: '',
        },
        name: {
            SI: 'Biome Actives',
            EN: 'Biome Actives',
            HR: 'Biome Actives',
            IT: 'Biome Actives',
            DE: 'Biome Actives',
            AT: 'Biome Actives',
            US: 'Biome Actives'
        },
        html_desc: {
            SI: <p>Za vzdrževanje dobrega stanja črevesnega sistema priporočamo uporabo probiotika Biome Actives, ki
                vsebuje 1 milijardo bakterije seva Bacillus coagulans, proizvaja mlečno kislino in v črevesju tvori
                spore, kar pripomore k razraščanju dobrih črevesnih bakterij. Biome Actives vsebujejo tudi prebiotike,
                ki so hrana za dobre črevesne bakterije. Lahko pomaga tudi pri zaprtju. </p>,
            EN: <p>Biome Actives combines both prebiotics and probiotics to synergistically provide a favorable environment for beneficial gut bacteria. By providing 1 billion CFU (colony-forming units) of the Bacillus coagulans probiotic per capsule, Biome Actives helps maintain the balance of the microbiome in the digestive system and support healthy detoxification.</p>,
            HR: <p>Biome Actives kombinira prebiotike i probiotike kako bi sinergijski osigurao povoljno okruženje za korisne bakterije u crijevima. Pružajući 1 milijardu CFU (jedinica koje tvore kolonije) probiotika Bacillus coagulans po kapsuli, Biome Actives pomaže održavanju ravnoteže mikrobioma u probavnom sustavu i podržava zdravu detoksikaciju.</p>,
            IT: <p>Biome Actives combina prebiotici e probiotici per fornire sinergicamente un ambiente favorevole per i batteri benefici dell'intestino. Fornendo 1 miliardo di CFU (unità formanti colonie) del probiotico Bacillus coagulans per capsula, Biome Actives aiuta a mantenere l'equilibrio del microbioma nel sistema digestivo e supporta una sana disintossicazione.</p>,
            DE: <p>Biome Actives kombiniert sowohl Präbiotika als auch Probiotika, um synergistisch eine günstige Umgebung für nützliche Darmbakterien zu schaffen. Durch die Bereitstellung von 1 Milliarde CFU (koloniebildenden Einheiten) des Bacillus coagulans Probiotikums pro Kapsel, hilft Biome Actives, das Gleichgewicht des Mikrobioms im Verdauungssystem zu erhalten und eine gesunde Entgiftung zu unterstützen.</p>,
            AT: <p>Biome Actives kombiniert sowohl Präbiotika als auch Probiotika, um synergistisch eine günstige Umgebung für nützliche Darmbakterien zu schaffen. Durch die Bereitstellung von 1 Milliarde CFU (koloniebildenden Einheiten) des Bacillus coagulans Probiotikums pro Kapsel, hilft Biome Actives, das Gleichgewicht des Mikrobioms im Verdauungssystem zu erhalten und eine gesunde Entgiftung zu unterstützen.</p>,
            US: <p>Biome Actives combines both prebiotics and probiotics to synergistically provide a favorable environment for beneficial gut bacteria. By providing 1 billion CFU (colony-forming units) of the Bacillus coagulans probiotic per capsule, Biome Actives helps maintain the balance of the microbiome in the digestive system and support healthy detoxification.</p>
        },
        url: {
            SI: 'https://new.synergyworldwide.com/SI/sl-SI/Shop/Product/biome-actives',
            EN: 'https://new.synergyworldwide.com/GB/en-GB/Shop/Product/biome-actives',
            HR: 'https://new.synergyworldwide.com/SI/en-SI/Shop/Product/biome-actives',
            IT: 'https://new.synergyworldwide.com/IT/it-IT/Shop/Product/biome-actives',
            DE: 'https://new.synergyworldwide.com/DE/de-DE/Shop/Product/biome-actives',
            AT: 'https://new.synergyworldwide.com/AT/de-AT/Shop/Product/biome-actives',
            US: 'https://new.synergyworldwide.com/US/en-US/Shop/Product/biome-actives'
        },
        html_p: {
            SI: 'Za več informacij o programu kliknite',
            EN: 'For more information about the program, click',
            HR: 'Za više informacija o programu kliknite',
            IT: 'Per ulteriori informazioni sul programma, fare clic su',
            DE: 'Für weitere Informationen über das Programm klicken Sie auf',
            AT: 'Für weitere Informationen über das Programm klicken Sie auf',
            US: 'For more information about the program click'
        },
        video: 'https://synergyworldwide.app.box.com/s/du1gaiziqxxjc6jh72nw6kzgwwilx8oe/file/748241436596',
        video_l10n: {
            SI: 'https://synergyworldwide.app.box.com/s/du1gaiziqxxjc6jh72nw6kzgwwilx8oe/file/748241436596',
            EN: 'https://synergyworldwide.app.box.com/s/du1gaiziqxxjc6jh72nw6kzgwwilx8oe/file/142946177571',
            HR: 'https://synergyworldwide.app.box.com/s/du1gaiziqxxjc6jh72nw6kzgwwilx8oe/file/142946177571',
            IT: 'https://synergyworldwide.app.box.com/s/du1gaiziqxxjc6jh72nw6kzgwwilx8oe/file/147863724730',
            DE: 'https://synergyworldwide.app.box.com/s/du1gaiziqxxjc6jh72nw6kzgwwilx8oe/file/143738749369',
            AT: 'https://synergyworldwide.app.box.com/s/du1gaiziqxxjc6jh72nw6kzgwwilx8oe/file/143738749369',
            US: 'https://synergyworldwide.app.box.com/s/du1gaiziqxxjc6jh72nw6kzgwwilx8oe/file/142946177571'
        },
        image: synergyImg
    },
    synergy: {
        extra_title: {
            SI: '',
            EN: '',
            HR: '',
            IT: '',
            DE: '',
            AT: '',
            US: '',
        },
        name: {
            SI: 'Health Care',
            EN: 'Health Care',
            HR: 'Health Care',
            IT: 'Health Care',
            DE: 'Health Care',
            AT: 'Health Care',
            US: 'Heart Health Elite Pack'
        },
        html_desc: {
            SI: <p>Krvožilni sistem je kompleksen sistem, za dobro dolgoročno delovanje katerega je potreben celostni
                pristop. Za njegovo zdravje so ključni uravnotežena prehrana, bogata z vitamini, minerali, antioksidanti
                in Omega3 maščobnimi kislinami, vsakodnevno gibanje in upravljanje s stresom ter skrb za proizvodnjo
                zadostne količine dušikovega oksida. Optimalno podporo krvožilnemu sistemu predstavlja Health Care
                program, ki vsebuje štiri izdelke, ki se, v skrbi za vaš krvožilni sistem, med seboj dopolnjujejo in
                delujejo v sinergiji: Omega3, KoencimQ10, vitamin D3 in ProArgi9+. </p>,
            EN: <p>The circulatory system is a complex system that requires a comprehensive approach for its long-term optimal functioning. Balanced nutrition, rich in vitamins, minerals, antioxidants, and Omega-3 fatty acids, daily exercise, stress management, and ensuring adequate production of nitric oxide are crucial for its health. The Health Care program, which includes four products that complement each other and work in synergy to care for your circulatory system, provides optimal support: Omega-3, Coenzyme Q10, Vitamin D3, and ProArgi9+. </p>,
            HR: <p>Krvožilni sustav zahtijeva sveobuhvatan pristup kako bi dugoročno optimalno funkcionirao. Uravnotežena prehrana bogata vitaminima, mineralima, antioksidansima i Omega-3 masnim kiselinama, svakodnevno vježbanje, upravljanje stresom i osiguravanje dovoljne proizvodnje dušikovog oksida ključni su za njegovo zdravlje. Optimalnu podršku krvotoku pruža Health Care program koji uključuje četiri proizvoda koji se međusobno nadopunjuju i djeluju sinergijski: Omega-3, koenzim Q10, vitamin D3 i ProArgi9+. </p>,
            IT: <p>Il sistema circolatorio è un sistema complesso che richiede un approccio globale per il suo funzionamento ottimale a lungo termine. Una nutrizione equilibrata, ricca di vitamine, minerali, antiossidanti e acidi grassi Omega-3, l'esercizio quotidiano, la gestione dello stress e la produzione adeguata di ossido nitrico sono fondamentali per la sua salute. Il programma Health Care, che comprende quattro prodotti che si integrano tra loro e agiscono in sinergia per prendersi cura del sistema circolatorio, offre un supporto ottimale: Omega-3, Coenzima Q10, Vitamina D3 e ProArgi9+.</p>,
            DE: <p>Das Kreislaufsystem ist ein komplexes System, das für eine optimale langfristige Funktion einen ganzheitlichen Ansatz erfordert. Ausgewogene Ernährung, reich an Vitaminen, Mineralien, Antioxidantien und Omega-3-Fettsäuren, tägliche Bewegung, Stressbewältigung und eine ausreichende Produktion von Stickstoffoxid sind für seine Gesundheit entscheidend. Das Health Care-Programm, das vier Produkte enthält, die sich gegenseitig ergänzen und in Synergie für Ihr Kreislaufsystem arbeiten, bietet optimale Unterstützung: Omega-3, Coenzym Q10, Vitamin D3 und ProArgi9+.</p>,
            AT: <p>Das Kreislaufsystem ist ein komplexes System, das für eine optimale langfristige Funktion einen ganzheitlichen Ansatz erfordert. Ausgewogene Ernährung, reich an Vitaminen, Mineralien, Antioxidantien und Omega-3-Fettsäuren, tägliche Bewegung, Stressbewältigung und eine ausreichende Produktion von Stickstoffoxid sind für seine Gesundheit entscheidend. Das Health Care-Programm, das vier Produkte enthält, die sich gegenseitig ergänzen und in Synergie für Ihr Kreislaufsystem arbeiten, bietet optimale Unterstützung: Omega-3, Coenzym Q10, Vitamin D3 und ProArgi9+.</p>,
            US: <p>The circulatory system is a complex system that requires a comprehensive approach for its long-term optimal functioning. Balanced nutrition, rich in vitamins, minerals, antioxidants, and Omega-3 fatty acids, daily exercise, stress management, and ensuring adequate production of nitric oxide are crucial for its health. The Heart Health Elite program, which includes four products that complement each other and work in synergy to care for your circulatory system, provides optimal support: Omega-3, Coenzyme Q10, Vitamin D3, and ProArgi9+</p>
        },
        url: {
            SI: 'https://new.synergyworldwide.com/SI/sl-SI/Shop/Product/health-care-pack',
            EN: 'https://new.synergyworldwide.com/GB/en-GB/Shop/Product/health-care-pack',
            HR: 'https://new.synergyworldwide.com/SI/en-SI/Shop/Product/health-care-pack',
            IT: 'https://new.synergyworldwide.com/IT/it-IT/Shop/Product/health-care-pack',
            DE: 'https://new.synergyworldwide.com/DE/de-DE/Shop/Product/health-care-pack',
            AT: 'https://new.synergyworldwide.com/AT/de-AT/Shop/Product/health-care-pack',
            US: 'https://new.synergyworldwide.com/US/en-US/Shop/Product/heart-health-elite-pack'
        },
        html_p: {
            SI: 'Za več informacij o programu kliknite',
            EN: 'For more information about the program, click',
            HR: 'Za više informacija o programu kliknite',
            IT: 'Per ulteriori informazioni sul programma, fare clic su',
            DE: 'Für weitere Informationen über das Programm klicken Sie auf',
            AT: 'Für weitere Informationen über das Programm klicken Sie auf',
            US: 'For more information about the program, click'
        },
        video: 'https://youtu.be/xbBVgf0ganQ',
        video_l10n: {
            SI: 'https://youtu.be/xbBVgf0ganQ',
            EN: 'https://youtu.be/xbBVgf0ganQ',
            HR: 'https://youtu.be/xbBVgf0ganQ',
            IT: 'https://youtu.be/xbBVgf0ganQ',
            DE: 'https://youtu.be/xbBVgf0ganQ',
            AT: 'https://youtu.be/xbBVgf0ganQ',
            US: 'https://youtu.be/xbBVgf0ganQ'
        },
        image: healthCareImg
    },
    phytoLife: {
        extra_title: {
            SI: '',
            EN: '',
            HR: '',
            IT: '',
            DE: '',
            AT: '',
            US: '',
        },
        name: {
            SI: 'PhytoLife',
            EN: 'PhytoLife',
            HR: 'PhytoLife',
            IT: 'PhytoLife',
            DE: 'PhytoLife',
            AT: 'PhytoLife',
            US: 'Liquid Chlorophyll'
        },
        url: {
            SI: 'https://new.synergyworldwide.com/SI/sl-SI/Shop/Product/phytolife',
            EN: 'https://new.synergyworldwide.com/SI/en-SI/Shop/Product/phytolife',
            HR: 'https://new.synergyworldwide.com/SI/en-SI/Shop/Product/phytolife',
            IT: 'https://new.synergyworldwide.com/SI/sl-SI/Shop/Product/phytolife',
            DE: 'https://new.synergyworldwide.com/SI/sl-SI/Shop/Product/phytolife',
            AT: 'https://new.synergyworldwide.com/SI/sl-SI/Shop/Product/phytolife',
            US: 'https://new.synergyworldwide.com/SI/sl-SI/Shop/Product/phytolife'
        },
        html_desc: {
            SI: <p></p>,
            EN: <p></p>,
            HR: <p></p>,
            IT: '',
            DE: '',
            AT: '',
            US: ''
        },
        html_p: {
            SI: 'Za več informacij o programu kliknite',
            EN: 'For more information about the program, click',
            HR: 'Za više informacija o programu kliknite',
            IT: 'Per ulteriori informazioni sul programma, fare clic su',
            DE: 'Für weitere Informationen über das Programm klicken Sie auf',
            AT: 'Für weitere Informationen über das Programm klicken Sie auf',
            US: 'For more information about the program, click'
        },
        video: '',
        video_l10n: {
            SI: '',
            EN: '',
            HR: '',
            IT: '',
            DE: '',
            AT: '',
            US: ''
        },
        image: photoLyfeImg
    },
    biomeCore: {
        extra_title: {
            SI: '',
            EN: '',
            HR: '',
            IT: '',
            DE: '',
            AT: '',
            US: '',
        },
        name: {
            SI: 'Biome Core',
            EN: 'Biome Core',
            HR: 'Biome Core',
            IT: 'Biome Core',
            DE: 'Biome Core',
            AT: 'Biome Core',
            US: 'Biome Core'
        },
        url: {
            SI: 'https://new.synergyworldwide.com/SI/sl-SI/Shop/Product/biome-actives',
            EN: 'https://new.synergyworldwide.com/SI/sl-SI/Shop/Product/biome-actives',
            HR: 'https://new.synergyworldwide.com/SI/sl-SI/Shop/Product/biome-actives',
            IT: '',
            DE: '',
            AT: '',
            US: ''
        },
        html_desc: {
            SI: <p> Za vzdrževanje dobrega stanja črevesnega sistema priporočamo uporabo probiotika Biome Actives, ki
                vsebuje 1 milijardo bakterije seva Bacillus coagulans,ki proizvaja mlečno kislino in v črevesju tvori
                spore, kar pripomore k razraščanju dobrih črevesnih bakterij. Biome Actives vsebujejo tudi prebiotike,
                ki so hrana za dobre črevesne bakterije. Lahko pomaga tudi pri zaprtju. </p>,
            EN: <p> DNK analiza medicinskega laboratorija, s katero lahko pridobite informacije, ki so ključne za
                preprečevanje tistih bolezni in obolenj, ki so na podlagi vaših genov najbolj nevarne za vas. Poleg
                tega pa vam natančno predstavi, katera hrana je za vas zdrava oz. katera vam daje moč in katere se
                morate še posebej izogibati, saj vam povzroča zdravstvene težave in bolezenska stanja. Na skoraj 200
                straneh med drugim ponuja tudi informacijo ali ste nagnjeni k poškodbam, kako vas ogroža oksidativni
                stres, kakšno sposobnost samorazstrupljanja ima vaše telo in poda smernice za optimalno telesno
                aktivnost.</p>,
            HR: <p> DNK analiza medicinskog laboratorija, koja vam može pomoći da dobijete ključne informacije za
                sprječavanje bolesti koje su vam najopasnije na temelju vaših gena. Osim toga, točno vam govori koja je
                hrana zdrava za vas ili koja vam daje snagu, a koju biste posebno trebali izbjegavati, jer vam uzrokuje
                zdravstvene probleme i medicinska stanja. Na gotovo 200 stranica, između ostalog, također nudi
                informacije da li ste skloni ozljedama, kako vas ugrožava oksidativni stres, kakvu sposobnost
                samo-detoksikacije vaše tijelo ima i pruža smjernice za optimalnu tjelesnu aktivnost.</p>,
            IT: '',
            DE: '',
            AT: '',
            US: ''
        },
        html_p: {
            SI: 'Za več informacij o programu kliknite',
            EN: 'For more information about the program, click',
            HR: 'Za više informacija o programu kliknite',
            IT: 'Per ulteriori informazioni sul programma, fare clic su',
            DE: 'Für weitere Informationen über das Programm klicken Sie auf',
            AT: 'Für weitere Informationen über das Programm klicken Sie auf',
            US: 'For more information about the program, click'
        },
        video: '',
        video_l10n: {
            SI: '',
            EN: '',
            HR: '',
            IT: '',
            DE: '',
            AT: '',
            US: ''
        },
        image: biomeCoreImg
    },
    bodyPrime: {
        extra_title: {
            SI: '',
            EN: '',
            HR: '',
            IT: '',
            DE: '',
            AT: '',
            US: '',
        },
        name: {
            SI: 'Body Prime',
            EN: 'Body Prime',
            HR: 'Body Prime',
            IT: 'Body Prime',
            DE: 'Body Prime',
            AT: 'Body Prime',
            US: 'Body Prime'
        },
        url: {
            SI: 'https://new.synergyworldwide.com/SI/sl-SI/Shop/Product/body-prime',
            EN: 'https://new.synergyworldwide.com/GB/en-gb/Shop/Product/body-prime',
            HR: 'https://new.synergyworldwide.com/SI/en-SI/Shop/Product/body-prime',
            IT: 'https://new.synergyworldwide.com/IT/it-IT/Shop/Product/body-prime',
            DE: 'https://new.synergyworldwide.com/DE/de-DE/Shop/Product/body-prime',
            AT: 'https://new.synergyworldwide.com/AT/de-AT/Shop/Product/body-prime',
            US: 'https://new.synergyworldwide.com/US/en-US/Shop/Product/body-prime'
        },
        html_desc: {
            SI: <p> Za vzdrževanje dobrega stanja prebavnega sistema in za dobro prebavo priporočamo uporabo
                magnezijevega pripravka - Body Prime, ki so mu dodane suhe slive v prahu in jabolčni pektin. Magnezij je
                mineral, ki je prisoten v več kot 300 telesnih procesih, je ključen tudi za dobro črevesno peristaltiko
                in dobro prebavo, telo pa ga ne proizvaja samo. Magnezij dobimo s prehrano, ki pa je vedno bolj
                osiromašena hranil, zato ga je potrebno dodajati v obliki kvalitetnega prehranskega dopolnila.
                Kombinacija magnezija, suhih sliv in jabolčnega pektina blagodejno učinkuje pri zaprtju, počasni prebavi
                in oteženem izločanju. Magnezij BodyPrime ima visoko biološko dostopnost in je zato zelo
                učinkovit. </p>,
            EN: <p> For maintaining good digestive system health and proper digestion, we recommend using a magnesium supplement
                - Body Prime, which contains added dried plums in powder form and apple pectin. Magnesium is a mineral present in
                more than 300 bodily processes and is crucial for good intestinal peristalsis and proper digestion. Our body does
                not produce magnesium on its own. We obtain magnesium from our diet, which is increasingly depleted of nutrients,
                therefore it is necessary to supplement it in the form of a high-quality dietary supplement. The combination of
                magnesium, dried plums, and apple pectin has a beneficial effect on constipation, slow digestion, and difficult
                elimination. BodyPrime Magnesium has high bioavailability and is therefore highly effective. </p>,
            HR: <p> Za vzdrževanje dobrega stanja prebavnega sistema in za dobro prebavo priporočamo uporabo
                magnezijevega pripravka - Body Prime, ki so mu dodane suhe slive v prahu in jabolčni pektin. Magnezij je
                mineral, ki je prisoten v več kot 300 telesnih procesih, je ključen tudi za dobro črevesno peristaltiko
                in dobro prebavo, telo pa ga ne proizvaja samo. Magnezij dobimo s prehrano, ki pa je vedno bolj
                osiromašena hranil, zato ga je potrebno dodajati v obliki kvalitetnega prehranskega dopolnila.
                Kombinacija magnezija, suhih sliv in jabolčnega pektina blagodejno učinkuje pri zaprtju, počasni prebavi
                in oteženem izločanju. Magnezij BodyPrime ima visoko biološko dostopnost in je zato zelo
                učinkovit. </p>,
            IT: <p> Per mantenere un buono stato del sistema digestivo e per una buona digestione, si consiglia l'uso di un
                integratore di magnesio - Body Prime, al quale sono state aggiunte prugne secche in polvere e pectina di mele.
                Il magnesio è un minerale presente in oltre 300 processi corporei ed è fondamentale anche per una buona
                peristalsi intestinale e una buona digestione. Il corpo non lo produce autonomamente. Il magnesio si ottiene
                attraverso l'alimentazione, che è sempre più povera di nutrienti, quindi è necessario aggiungerlo sotto forma
                di integratore alimentare di alta qualità. La combinazione di magnesio, prugne secche e pectina di mele ha un
                effetto benefico sulla stitichezza, la digestione lenta e l'eliminazione difficile. Il magnesio BodyPrime ha
                un'elevata biodisponibilità ed è quindi molto efficace. </p>,
            DE: <p> Zur Aufrechterhaltung eines guten Zustands des Verdauungssystems und einer guten Verdauung empfehlen wir
                die Verwendung eines Magnesiumpräparats - Body Prime, dem Trockenpflaumenpulver und Apfelpektin hinzugefügt
                wurden. Magnesium ist ein Mineral, das in mehr als 300 Körperprozessen vorhanden ist und auch für eine gute
                Darmperistaltik und eine gute Verdauung entscheidend ist. Der Körper produziert es jedoch nicht selbst.
                Magnesium erhalten wir aus der Nahrung, die jedoch zunehmend an Nährstoffen verarmt ist, weshalb es in Form
                eines qualitativ hochwertigen Nahrungsergänzungsmittels zugeführt werden muss. Die Kombination aus Magnesium,
                getrockneten Pflaumen und Apfelpektin wirkt wohltuend bei Verstopfung, träger Verdauung und erschwertem
                Ausscheiden. Magnesium BodyPrime hat eine hohe Bioverfügbarkeit und ist daher sehr wirksam. </p>,
            AT: <p> Zur Aufrechterhaltung eines guten Zustands des Verdauungssystems und einer guten Verdauung empfehlen wir
                die Verwendung eines Magnesiumpräparats - Body Prime, dem Trockenpflaumenpulver und Apfelpektin hinzugefügt
                wurden. Magnesium ist ein Mineral, das in mehr als 300 Körperprozessen vorhanden ist und auch für eine gute
                Darmperistaltik und eine gute Verdauung entscheidend ist. Der Körper produziert es jedoch nicht selbst.
                Magnesium erhalten wir aus der Nahrung, die jedoch zunehmend an Nährstoffen verarmt ist, weshalb es in Form
                eines qualitativ hochwertigen Nahrungsergänzungsmittels zugeführt werden muss. Die Kombination aus Magnesium,
                getrockneten Pflaumen und Apfelpektin wirkt wohltuend bei Verstopfung, träger Verdauung und erschwertem
                Ausscheiden. Magnesium BodyPrime hat eine hohe Bioverfügbarkeit und ist daher sehr wirksam. </p>,
            US: <p> For maintaining good digestive system health and proper digestion, we recommend using a magnesium supplement
                - Body Prime, which contains added dried plums in powder form and apple pectin. Magnesium is a mineral present in
                more than 300 bodily processes and is crucial for good intestinal peristalsis and proper digestion. Our body does
                not produce magnesium on its own. We obtain magnesium from our diet, which is increasingly depleted of nutrients,
                therefore it is necessary to supplement it in the form of a high-quality dietary supplement. The combination of
                magnesium, dried plums, and apple pectin has a beneficial effect on constipation, slow digestion, and difficult
                elimination. BodyPrime Magnesium has high bioavailability and is therefore highly effective. </p>
        },
        html_p: {
            SI: 'Za več informacij o programu kliknite',
            EN: 'For more information about the program, click',
            HR: 'Za više informacija o programu kliknite',
            IT: 'Per ulteriori informazioni sul programma, fare clic su',
            DE: 'Für weitere Informationen über das Programm klicken Sie auf',
            AT: 'Für weitere Informationen über das Programm klicken Sie auf',
            US: 'For more information about the program, click'
        },
        video: 'https://youtu.be/GwevkvJg4D0',
        video_l10n: {
            SI: 'https://youtu.be/GwevkvJg4D0',
            EN: 'https://youtu.be/NbvsgcVDxdU',
            HR: 'https://youtu.be/NbvsgcVDxdU',
            IT: 'https://youtu.be/NbvsgcVDxdU',
            DE: 'https://youtu.be/NbvsgcVDxdU',
            AT: 'https://youtu.be/NbvsgcVDxdU',
            US: 'https://youtu.be/NbvsgcVDxdU'
        },
        image: bodyPrimeImg
    },
    proArgi: {
        extra_title: {
            SI: '',
            EN: '',
            HR: '',
            IT: '',
            DE: '',
            AT: '',
            US: '',
        },
        name: {
            SI: 'Proargi-9+',
            EN: 'Proargi-9+',
            HR: 'Proargi-9+',
            IT: 'Proargi-9+',
            DE: 'Proargi-9+',
            AT: 'Proargi-9+',
            US: 'Proargi-9+'
        },
        url: {
            SI: 'https://new.synergyworldwide.com/SI/sl-SI/Shop/Product/proargi-9',
            EN: 'https://new.synergyworldwide.com/GB/en-gb/Shop/Product/proargi-9',
            HR: 'https://new.synergyworldwide.com/SI/en-SI/Shop/Product/proargi-9',
            IT: 'https://new.synergyworldwide.com/IT/it-IT/Shop/Product/proargi-9',
            DE: 'https://new.synergyworldwide.com/DE/de-DE/Shop/Product/proargi-9',
            AT: 'https://new.synergyworldwide.com/AT/de-AT/Shop/Product/proargi-9',
            US: 'https://new.synergyworldwide.com/US/en-US/Shop/Product/proargi-9'
        },
        html_p: {
            SI: 'Za več informacij o programu kliknite',
            EN: 'For more information about the program, click',
            HR: 'Za više informacija o programu kliknite',
            IT: 'Per ulteriori informazioni sul programma, fare clic su',
            DE: 'Für weitere Informationen über das Programm klicken Sie auf',
            AT: 'Für weitere Informationen über das Programm klicken Sie auf',
            US: 'For more information about the program, click'
        },
        html_desc: {
            SI: <p> Osnovno podporo za zdravje živčnega sistema predstavlja, po ocenah ameriških zdravnikov najboljši
                napitek z L-argininom na svetu, ProArgi9+. Kombinacija sestavin ProArgi9+ je patentirana formula, ki
                podpira optimalno zdravje vseh telesnih sistemov, zelo ugodno pa vpliva tudi na živčni sistem. Napitek
                vsebuje vitamine C,D,B6,B12 in K ter aminokislini L-arginin in L-citrulin, ki v telesu omogočata 24 urno
                proizvodnjo dušikovega oksida, kar izboljšuje prekrvavitev telesa. Dobra prekrvavitev pomeni, da so
                možgani preskrbljeni s kisikom in hranili, kar pripomore k boljši kognitivni funkciji, izboljšuje spomin
                in koncentracijo ter pripomore k večji umirjenosti in lažjemu obvladovanju stresa.</p>,
            EN: <p> The basic support for the health of the nervous system, according to American doctors, is represented
                by the best L-arginine drink in the world, ProArgi9+. The combination of ingredients in ProArgi9+ is a
                patented formula that supports optimal health for all bodily systems, and also has a very beneficial effect
                on the nervous system. The drink contains vitamins C, D, B6, B12, and K, as well as the amino acids L-arginine
                and L-citrulline, which enable the body to produce nitric oxide for 24 hours, improving blood circulation.
                Good blood circulation means that the brain is supplied with oxygen and nutrients, which contributes to
                better cognitive function, improved memory and concentration, and greater calmness and easier stress
                management. </p>,
            HR: <p> Osnovno podporo za zdravje živčnega sistema predstavlja, po ocenah ameriških zdravnikov najboljši
                napitek z L-argininom na svetu, ProArgi9+. Kombinacija sestavin ProArgi9+ je patentirana formula, ki
                podpira optimalno zdravje vseh telesnih sistemov, zelo ugodno pa vpliva tudi na živčni sistem. Napitek
                vsebuje vitamine C,D,B6,B12 in K ter aminokislini L-arginin in L-citrulin, ki v telesu omogočata 24 urno
                proizvodnjo dušikovega oksida, kar izboljšuje prekrvavitev telesa. Dobra prekrvavitev pomeni, da so
                možgani preskrbljeni s kisikom in hranili, kar pripomore k boljši kognitivni funkciji, izboljšuje spomin
                in koncentracijo ter pripomore k večji umirjenosti in lažjemu obvladovanju stresa.</p>,
            IT: <p> Il sostegno di base per la salute del sistema nervoso, secondo le valutazioni dei medici americani,
                è rappresentato dalla migliore bevanda a base di L-arginina al mondo, ProArgi9+. La combinazione degli
                ingredienti di ProArgi9+ è una formula brevettata che supporta l'ottima salute di tutti i sistemi corporei
                e ha anche un effetto molto benefico sul sistema nervoso. La bevanda contiene vitamine C, D, B6, B12 e K,
                oltre agli aminoacidi L-arginina e L-citrullina, che permettono al corpo di produrre ossido nitrico per 24
                ore, migliorando la circolazione sanguigna. Una buona circolazione sanguigna significa che il cervello è
                fornito di ossigeno e nutrienti, il che contribuisce a una migliore funzione cognitiva, migliora la memoria
                e la concentrazione e favorisce una maggiore calma e una più facile gestione dello stress.</p>,
            DE: <p> Die grundlegende Unterstützung für die Gesundheit des Nervensystems wird von amerikanischen Ärzten als
                das beste L-Arginin-Getränk der Welt, ProArgi9+, bezeichnet. Die Kombination der Inhaltsstoffe von ProArgi9+
                ist eine patentierte Formel, die die optimale Gesundheit aller Körpersysteme unterstützt und sich sehr positiv
                auf das Nervensystem auswirkt. Das Getränk enthält die Vitamine C, D, B6, B12 und K sowie die Aminosäuren
                L-Arginin und L-Citrullin, die im Körper eine 24-stündige Produktion von Stickstoffmonoxid ermöglichen, was
                die Durchblutung verbessert. Eine gute Durchblutung bedeutet, dass das Gehirn mit Sauerstoff und Nährstoffen
                versorgt wird, was zu einer besseren kognitiven Funktion, verbesserter Gedächtnisleistung und Konzentration
                sowie zu größerer Gelassenheit und leichterer Stressbewältigung beiträgt. </p>,
            AT: <p> Die grundlegende Unterstützung für die Gesundheit des Nervensystems wird von amerikanischen Ärzten als
                das beste L-Arginin-Getränk der Welt, ProArgi9+, bezeichnet. Die Kombination der Inhaltsstoffe von ProArgi9+
                ist eine patentierte Formel, die die optimale Gesundheit aller Körpersysteme unterstützt und sich sehr positiv
                auf das Nervensystem auswirkt. Das Getränk enthält die Vitamine C, D, B6, B12 und K sowie die Aminosäuren
                L-Arginin und L-Citrullin, die im Körper eine 24-stündige Produktion von Stickstoffmonoxid ermöglichen, was
                die Durchblutung verbessert. Eine gute Durchblutung bedeutet, dass das Gehirn mit Sauerstoff und Nährstoffen
                versorgt wird, was zu einer besseren kognitiven Funktion, verbesserter Gedächtnisleistung und Konzentration
                sowie zu größerer Gelassenheit und leichterer Stressbewältigung beiträgt. </p>,
            US: <p> The basic support for the health of the nervous system, according to American doctors, is represented
                by the best L-arginine drink in the world, ProArgi9+. The combination of ingredients in ProArgi9+ is a
                patented formula that supports optimal health for all bodily systems, and also has a very beneficial effect
                on the nervous system. The drink contains vitamins C, D, B6, B12, and K, as well as the amino acids L-arginine
                and L-citrulline, which enable the body to produce nitric oxide for 24 hours, improving blood circulation.
                Good blood circulation means that the brain is supplied with oxygen and nutrients, which contributes to
                better cognitive function, improved memory and concentration, and greater calmness and easier stress
                management. </p>
        },
        video: 'https://youtu.be/2OfpoS5zfUQ',
        video_l10n: {
            SI: 'https://youtu.be/2OfpoS5zfUQ',
            EN: 'https://youtu.be/nocUqGYqRHU',
            HR: 'https://youtu.be/RlSZ7OQZik8',
            IT: 'https://youtu.be/J4YIQg9DuI8',
            DE: 'https://youtu.be/EEK9WIK8a0Y',
            AT: 'https://youtu.be/EEK9WIK8a0Y',
            US: 'https://youtu.be/ixqmuypgreg'
        },
        image: proArgiImg
    },
    proArgiImmuneBooster: {
        extra_title: {
            SI: '',
            EN: '',
            HR: '',
            IT: '',
            DE: '',
            AT: '',
            US: '',
        },
        name: {
            SI: 'Proargi-9+ Immune Booster',
            EN: 'Proargi-9+ Immune Booster',
            HR: 'Immune Booster',
            IT: 'Proargi-9+ Immune Booster',
            DE: 'Proargi-9+ Immune Booster',
            AT: 'Proargi-9+ Immune Booster',
            US: 'Proargi-9+ Immune Booster'
        },
        url: {
            SI: 'https://new.synergyworldwide.com/SI/sl-SI/Shop/Product/proargi-9-immune-booster',
            EN: 'https://new.synergyworldwide.com/SI/en-SI/Shop/Product/proargi-9-immune-booster',
            HR: 'https://new.synergyworldwide.com/SI/en-SI/Shop/Product/proargi-9-immune-booster',
            IT: '',
            DE: '',
            AT: '',
            US: ''
        },
        html_desc: {
            SI: <p></p>,
            EN: <p></p>,
            HR: <p></p>,
            IT: '',
            DE: '',
            AT: '',
            US: ''
        },
        html_p: {
            SI: 'Za več informacij o programu kliknite',
            EN: 'For more information about the program, click',
            HR: 'Za više informacija o programu kliknite',
            IT: 'Per ulteriori informazioni sul programma, fare clic su',
            DE: 'Für weitere Informationen über das Programm klicken Sie auf',
            AT: 'Für weitere Informationen über das Programm klicken Sie auf',
            US: 'For more information about the program, click'
        },
        video: '',
        video_l10n: {
            SI: '',
            EN: '',
            HR: '',
            IT: '',
            DE: '',
            AT: '',
            US: ''
        },
        image: proArgiBoosterImg
    },

    v3: {
        extra_title: {
            SI: '',
            EN: '',
            HR: '',
            IT: '',
            DE: '',
            AT: '',
            US: '',
        },
        name: {
            SI: 'SISTEM VITAL 3',
            EN: 'SYSTEM VITAL 3',
            HR: 'SUSTAV VITAL 3',
            IT: 'SISTEMO VITAL 3',
            DE: 'SYSTEM VITAL 3',
            AT: 'SYSTEM VITAL 3',
            US: 'SYSTEM VITAL 3'
        },
        url: {
            SI: 'https://new.synergyworldwide.com/SI/sl-SI/Shop/Product/v3-combination-packs',
            EN: 'https://new.synergyworldwide.com/GB/en-GB/Shop/Product/v3-combination-packs',
            HR: 'https://new.synergyworldwide.com/SI/en-SI/Shop/Product/v3-combination-packs',
            IT: 'https://new.synergyworldwide.com/IT/it-IT/Shop/Product/v3-combination-packs',
            DE: 'https://new.synergyworldwide.com/DE/de-DE/Shop/Product/v3-combination-packs',
            AT: 'https://new.synergyworldwide.com/AT/de-AT/Shop/Product/v3-combination-packs',
            US: 'https://new.synergyworldwide.com/US/en-US/Shop/Product/v3-chlorophyll-edition'
        },
        html_desc: {
            SI: <p> Prava podpora za živčni sistem je program Vital3. Vital3 sestavljajo trije izdelki, ki se, v skrbi
                za vaš živčni sistem, med seboj dopolnjujejo in delujejo v sinergiji: Phytolife, Mistify in ProArgi9+.
                Medtem, ko ProArgi9+, preko izboljšane prekrvavitve, izboljšuje spomin in koncentracijo ter pripomore k
                večji umirjenosti in lažjemu prenašanju stresa, Mistify zmanjšuje utrujenost in izčrpanost ter
                izboljšuje psihološko funkcijo. Phytolife s svojimi razstrupljevalnimi lastnostmi telo alkalizira in
                ustvarja boljše pogoje za absorpcijo esencialnih učinkovin iz ostalih dveh izdelkov, kar poveča njuno
                učinkovitost.</p>,
            EN: <p> The right support for the nervous system is the Vital3 program. Vital3 is composed of three products
                that, while taking care of your nervous system, complement each other and work in synergy: PhytoLife,
                Mistify and ProArgi9+. While ProArgi9+ improves memory and concentration and contributes to greater calmness
                and easier stress management through enhanced blood circulation, Mistify reduces fatigue and exhaustion and
                improves psychological function. Phytolife, with its detoxifying properties, alkalizes the body and creates
                better conditions for the absorption of essential active ingredients from the other two products, increasing
                their effectiveness.</p>,
            HR: <p> Prava podrška za živčani sustav je Vital3 program. Vital3 sastoji se od tri proizvoda koji se,
                brinući se za vaš živčani sustav, međusobno dopunjuju i djeluju u sinergiji: Phytolife, Mistify i
                ProArgi9+. Dok ProArgi9+, kroz poboljšanu cirkulaciju, poboljšava memoriju i koncentraciju te pridonosi
                većem smirenosti i lakšem podnošenju stresa, Mistify smanjuje umor i iscrpljenost te poboljšava psihološku
                funkciju. Phytolife sa svojim detoksikacijskim svojstvima alkalizira tijelo i stvara bolje uvjete za
                apsorpciju esencijalnih aktivnih sastojaka iz ostala dva proizvoda, čime povećava njihovu učinkovitost.</p>,
            IT: <p> Il giusto supporto per il sistema nervoso è il programma Vital3. Vital3 è composto da tre prodotti
                che, nel prendersi cura del tuo sistema nervoso, si integrano e agiscono in sinergia: Phytolife, Mistify
                e ProArgi9+. Mentre ProArgi9+ migliora la memoria e la concentrazione e contribuisce a una maggiore calma
                e una gestione dello stress più facile attraverso una migliore circolazione sanguigna, Mistify riduce
                l'affaticamento e l'esaurimento e migliora la funzione psicologica. Phytolife, con le sue proprietà
                disintossicanti, alcalinizza il corpo e crea condizioni migliori per l'assorbimento degli ingredienti
                attivi essenziali degli altri due prodotti, aumentand </p>,
            DE: <p> Die richtige Unterstützung für das Nervensystem ist das Vital3-Programm. Vital3 besteht aus drei
                Produkten, die sich in der Pflege Ihres Nervensystems gegenseitig ergänzen und in Synergie wirken:
                Phytolife, Mistify und ProArgi9+. Während ProArgi9+ durch verbesserte Durchblutung das Gedächtnis und
                die Konzentration verbessert und zu größerer Gelassenheit und leichterer Stressbewältigung beiträgt,
                reduziert Mistify Müdigkeit und Erschöpfung und verbessert die psychologische Funktion. Phytolife
                alkalisiert den Körper mit seinen entgiftenden Eigenschaften und schafft bessere Bedingungen für die
                Absorption der essentiellen Wirkstoffe aus den anderen beiden Produkten, wodurch deren Wirksamkeit
                erhöht wird. </p>,
            AT: <p> Die richtige Unterstützung für das Nervensystem ist das Vital3-Programm. Vital3 besteht aus drei
                Produkten, die sich in der Pflege Ihres Nervensystems gegenseitig ergänzen und in Synergie wirken:
                Phytolife, Mistify und ProArgi9+. Während ProArgi9+ durch verbesserte Durchblutung das Gedächtnis und
                die Konzentration verbessert und zu größerer Gelassenheit und leichterer Stressbewältigung beiträgt,
                reduziert Mistify Müdigkeit und Erschöpfung und verbessert die psychologische Funktion. Phytolife
                alkalisiert den Körper mit seinen entgiftenden Eigenschaften und schafft bessere Bedingungen für die
                Absorption der essentiellen Wirkstoffe aus den anderen beiden Produkten, wodurch deren Wirksamkeit
                erhöht wird. </p>,
            US: <p> The right support for the nervous system is the Vital3 program. Vital3 is composed of three products
                that, while taking care of your nervous system, complement each other and work in synergy: Liquid Chlorophyll,
                Mistica and ProArgi9+. While ProArgi9+ improves memory and concentration and contributes to greater calmness
                and easier stress management through enhanced blood circulation, Mistify reduces fatigue and exhaustion and
                improves psychological function. Phytolife, with its detoxifying properties, alkalizes the body and creates
                better conditions for the absorption of essential active ingredients from the other two products, increasing
                their effectiveness.</p>
        },
        html_p: {
            SI: 'Za več informacij o programu kliknite',
            EN: 'For more information about the program, click',
            HR: 'Za više informacija o programu kliknite',
            IT: 'Per ulteriori informazioni sul programma, fare clic su',
            DE: 'Für weitere Informationen über das Programm klicken Sie auf',
            AT: 'Für weitere Informationen über das Programm klicken Sie auf',
            US: 'For more information about the program, click'
        },
        video: 'https://youtu.be/qWaOTckT4Jg',
        video_l10n: {
            SI: 'https://youtu.be/qWaOTckT4Jg',
            EN: 'https://youtu.be/AQdiQJ4vxu8',
            HR: 'https://youtu.be/r9VdSJ9neiM',
            IT: 'https://youtu.be/AQdiQJ4vxu8',
            DE: 'https://youtu.be/AQdiQJ4vxu8',
            AT: 'https://youtu.be/AQdiQJ4vxu8',
            US: 'https://youtu.be/AQdiQJ4vxu8'
        },
        image: v3Img
    },
    omega3: {
        extra_title: {
            SI: '',
            EN: '',
            HR: '',
            IT: '',
            DE: '',
            AT: '',
            US: '',
        },
        name: {
            SI: 'Omega-3',
            EN: 'Omega-3',
            HR: 'Omega-3',
            IT: 'Omega-3',
            DE: 'Omega-3',
            AT: 'Omega-3',
            US: 'Omega-3'
        },
        url: {
            SI: 'https://new.synergyworldwide.com/SI/sl-SI/Shop/Product/omega-3',
            EN: 'https://new.synergyworldwide.com/SI/en-SI/Shop/Product/omega-3',
            HR: 'https://new.synergyworldwide.com/SI/en-SI/Shop/Product/omega-3',
            IT: '',
            DE: '',
            AT: '',
            US: ''
        },
        html_desc: {
            SI: <p></p>,
            EN: <p></p>,
            HR: <p></p>,
            IT: '',
            DE: '',
            AT: '',
            US: ''
        },
        html_p: {
            SI: 'Za več informacij o programu kliknite',
            EN: 'For more information about the program, click',
            HR: 'Za više informacija o programu kliknite',
            IT: 'Per ulteriori informazioni sul programma, fare clic su',
            DE: 'Für weitere Informationen über das Programm klicken Sie auf',
            AT: 'Für weitere Informationen über das Programm klicken Sie auf',
            US: 'For more information about the program, click'
        },
        video: '',
        video_l10n: {
            SI: '',
            EN: '',
            HR: '',
            IT: '',
            DE: '',
            AT: '',
            US: ''
        },
        image: omega3Img
    },
    mistify: {
        extra_title: {
            SI: '',
            EN: '',
            HR: '',
            IT: '',
            DE: '',
            AT: '',
            US: '',
        },
        name: {
            SI: 'Mistify',
            EN: 'Mistify',
            HR: 'Mistify',
            IT: 'Mistify',
            DE: 'Mistify',
            AT: 'Mistify',
            US: 'Mistica'
        },
        url: {
            SI: 'https://new.synergyworldwide.com/SI/sl-SI/Shop/Product/mistify',
            EN: 'https://new.synergyworldwide.com/GB/en-GB/Shop/Product/mistify',
            HR: 'https://new.synergyworldwide.com/SI/en-SI/Shop/Product/mistify',
            IT: 'https://new.synergyworldwide.com/IT/it-IT/Shop/Product/mistify',
            DE: 'https://new.synergyworldwide.com/DE/de-DE/Shop/Product/mistify',
            AT: 'https://new.synergyworldwide.com/AT/de-AT/Shop/Product/mistify',
            US: 'https://new.synergyworldwide.com/US/en-US/Shop/Product/mistica'
        },
        html_desc: {
            SI: <p> Osnovno podporo imunskemu sistemu predstavlja odličen napitek iz različnih vrst jagodičevja in
                najboljših super živil, Mistify. Mistify krepi imunski sistem in ščiti organizem pred škodljivimi
                prostimi radikali ter deluje protivnetno in zmanjšuje utrujenost in izčrpanost. </p>,
            EN: <p> The basic support for the immune system is provided by an excellent drink made from various
                types of berries and the best superfoods, Mistify. Mistify strengthens the immune system and protects
                the body from harmful free radicals, has an anti-inflammatory effect, and reduces fatigue and
                exhaustion. </p>,
            HR: <p> Osnovna podrška imunološkom sustavu predstavlja izvrstan napitak od različitih vrsta bobica
                i najboljih superhrana, Mistify. Mistify jača imunološki sustav i štiti organizam od štetnih slobodnih
                radikala te djeluje protuupalno i smanjuje umor i iscrpljenost. </p>,
            IT: <p> Il supporto di base per il sistema immunitario è rappresentato da un'ottima bevanda a base di vari
                tipi di bacche e dei migliori super cibi, Mistify. Mistify rafforza il sistema immunitario e protegge
                l'organismo dai dannosi radicali liberi, agisce come antinfiammatorio e riduce la fatica e
                l'esaurimento.</p>,
            DE: <p> Die grundlegende Unterstützung für das Immunsystem wird durch ein hervorragendes Getränk aus
                verschiedenen Beerenarten und den besten Superfoods, Mistify, gewährleistet. Mistify stärkt das
                Immunsystem und schützt den Organismus vor schädlichen freien Radikalen, wirkt entzündungshemmend
                und reduziert Müdigkeit und Erschöpfung.</p>,
            AT: <p> Die grundlegende Unterstützung für das Immunsystem wird durch ein hervorragendes Getränk aus
                verschiedenen Beerenarten und den besten Superfoods, Mistify, gewährleistet. Mistify stärkt das
                Immunsystem und schützt den Organismus vor schädlichen freien Radikalen, wirkt entzündungshemmend
                und reduziert Müdigkeit und Erschöpfung.</p>,
            US: <p> The basic support for the immune system is provided by an excellent drink made from various
                types of berries and the best superfoods, Mistica. Mistica strengthens the immune system and protects
                the body from harmful free radicals, has an anti-inflammatory effect, and reduces fatigue and
                exhaustion. </p>
        },
        html_p: {
            SI: 'Za več informacij o programu kliknite',
            EN: 'For more information about the program, click',
            HR: 'Za više informacija o programu kliknite',
            IT: 'Per ulteriori informazioni sul programma, fare clic su',
            DE: 'Für weitere Informationen über das Programm klicken Sie auf',
            AT: 'Für weitere Informationen über das Programm klicken Sie auf',
            US: 'For more information about the program, click'
        },
        video: 'https://youtu.be/cM2A9db1HGM',
        video_l10n: {
            SI: 'https://youtu.be/cM2A9db1HGM',
            EN: 'https://youtu.be/X6hoqhgnv2o',
            HR: 'https://youtu.be/X6hoqhgnv2o',
            IT: 'https://youtu.be/X6hoqhgnv2o',
            DE: 'https://youtu.be/X6hoqhgnv2o',
            AT: 'https://youtu.be/X6hoqhgnv2o',
            US: 'https://youtu.be/X6hoqhgnv2o'
        },
        image: mistifyImg
    },
    flx3: {
        extra_title: {
            SI: '',
            EN: '',
            HR: '',
            IT: '',
            DE: '',
            AT: '',
            US: '',
        },
        name: {
            SI: 'FL-3X',
            EN: 'FL-3X',
            HR: 'FL-3X',
            IT: 'FL-3X',
            DE: 'FL-3X',
            AT: 'FL-3X',
            US: 'FL-3X (NOT YET AVAILABLE IN US)'
        },
        url: {
            SI: 'https://new.synergyworldwide.com/SI/sl-SI/Shop/Product/fl-3x',
            EN: 'https://new.synergyworldwide.com/GB/en-GB/Shop/Product/fl-3x',
            HR: 'https://new.synergyworldwide.com/SI/en-SI/Shop/Product/fl-3x',
            IT: 'https://new.synergyworldwide.com/IT/it-IT/Shop/Product/fl-3x',
            DE: 'https://new.synergyworldwide.com/DE/de-DE/Shop/Product/fl-3x',
            AT: 'https://new.synergyworldwide.com/AT/de-AT/Shop/Product/fl-3x',
            US: 'https://new.synergyworldwide.com/GB/en-GB/Shop/Product/fl-3x'
        },
        html_desc: {
            SI: <p>FL-3X je učinkovit izdelek, ki je namenjen podpori sklepov in vezivnega tkiva.
                S svojo gelno strukturo poskrbi za maksimalno absorpcijo, za skelet ključnih snovi,
                kot so glukozamin, MSM in hondroitin. Obogaten z antioksidanti iz izvlečkov oljčnih
                listov, zelenega čaja, grozdnih pešk in posebne vrste jabolka ter vitaminom C spodbuja
                tudi nastajanje kolagena. </p>,
            EN: <p>FL-3X is an effective product designed to support joints and connective tissue. With
                its gel-like structure, it ensures maximum absorption of key substances, such as glucosamine,
                MSM, and chondroitin. Enriched with antioxidants from olive leaf extracts, green tea, grape
                seeds, and a special type of apple, as well as vitamin C, it also promotes collagen
                formation.</p>,
            HR: <p>FL-3X je učinkovit proizvod namijenjen podršci zglobovima i vezivnom tkivu. Sa svojom
                gelastom strukturom osigurava maksimalnu apsorpciju ključnih tvari, kao što su glukozamin,
                MSM i hondroitin. Obogaćen antioksidansima iz ekstrakta maslinovih listova, zelenog čaja,
                sjemenki grožđa i posebne vrste jabuke te vitaminom C, također potiče stvaranje
                kolagena.</p>,
            IT: <p>FL-3X è un prodotto efficace progettato per supportare le articolazioni e il tessuto
                connettivo. Con la sua struttura gelatinosa, garantisce il massimo assorbimento di sostanze
                chiave come glucosamina, MSM e condroitina. Arricchito con antiossidanti da estratti di
                foglie di olivo, tè verde, semi d'uva e una speciale varietà di mela, oltre alla vitamina C,
                promuove anche la formazione di collagene.</p>,
            DE: <p>FL-3X ist ein wirksames Produkt, das zur Unterstützung von Gelenken und Bindegewebe
                entwickelt wurde. Mit seiner Gelstruktur sorgt es für eine maximale Absorption von
                Schlüsselsubstanzen wie Glucosamin, MSM und Chondroitin. Angereichert mit Antioxidantien
                aus Extrakten von Olivenblättern, grünem Tee, Traubenkernen und einer speziellen Apfelsorte
                sowie Vitamin C fördert es auch die Kollagenbildung.</p>,
            AT: <p>FL-3X ist ein wirksames Produkt, das zur Unterstützung von Gelenken und Bindegewebe
                entwickelt wurde. Mit seiner Gelstruktur sorgt es für eine maximale Absorption von
                Schlüsselsubstanzen wie Glucosamin, MSM und Chondroitin. Angereichert mit Antioxidantien
                aus Extrakten von Olivenblättern, grünem Tee, Traubenkernen und einer speziellen Apfelsorte
                sowie Vitamin C fördert es auch die Kollagenbildung.</p>,
            US: <p>FL-3X is an effective product designed to support joints and connective tissue. With
                its gel-like structure, it ensures maximum absorption of key substances, such as glucosamine,
                MSM, and chondroitin. Enriched with antioxidants from olive leaf extracts, green tea, grape
                seeds, and a special type of apple, as well as vitamin C, it also promotes collagen
                formation.</p>
        },
        html_p: {
            SI: 'Za več informacij o programu kliknite',
            EN: 'For more information about the program, click',
            HR: 'Za više informacija o programu kliknite',
            IT: 'Per ulteriori informazioni sul programma, fare clic su',
            DE: 'Für weitere Informationen über das Programm klicken Sie auf',
            AT: 'Für weitere Informationen über das Programm klicken Sie auf',
            US: 'For more information about the program, click'
        },
        video: 'https://youtu.be/PTfNGh4YWZE',
        video_l10n: {
            SI: 'https://youtu.be/PTfNGh4YWZE',
            EN: 'https://youtu.be/EecGwHMM0Wo',
            HR: 'https://youtu.be/EecGwHMM0Wo',
            IT: 'https://youtu.be/EecGwHMM0Wo',
            DE: 'https://youtu.be/EecGwHMM0Wo',
            AT: 'https://youtu.be/EecGwHMM0Wo',
            US: 'https://youtu.be/EecGwHMM0Wo'
        },
        image: fl3xImg
    },
    collagen: {
        extra_title: {
            SI: '',
            EN: '',
            HR: '',
            IT: '',
            DE: '',
            AT: '',
            US: '',
        },
        name: {
            SI: 'Collagen+',
            EN: 'Collagen+',
            HR: 'Collagen+',
            IT: 'Collagen+',
            DE: 'Collagen+',
            AT: 'Collagen+',
            US: 'Collagen+ (NOT YET AVAILABLE IN US)'
        },
        url: {
            SI: 'https://new.synergyworldwide.com/SI/sl-SI/Shop/Product/collagen',
            EN: 'https://new.synergyworldwide.com/GB/en-GB/Shop/Product/collagen',
            HR: 'https://new.synergyworldwide.com/SI/en-SI/Shop/Product/collagen',
            IT: 'https://new.synergyworldwide.com/IT/it-IT/Shop/Product/collagen',
            DE: 'https://new.synergyworldwide.com/DE/de-DE/Shop/Product/collagen',
            AT: 'https://new.synergyworldwide.com/AT/de-AT/Shop/Product/collagen',
            US: 'https://new.synergyworldwide.com/GB/en-GB/Shop/Product/collagen'
        },
        html_desc: {
            SI: <p>Kolagen je beljakovina, ki predstavlja 1/3 vseh beljakovin v telesu in podpira
                kožo, lase, nohte, pa tudi kosti, hrustanec, mišice in vezi. Po 20. letu se začne
                proces staranja, ki je povezan s propadanjem kolagena in s povečevanjem encimov,
                ki razgrajujejo kolagen. To vodi do manj čvrste in bolj zgubane kože, slabše kvalitete
                nohtov in las. Synergy Collagen+ je kakovostno dopolnilo, ki vsebuje oba tipa kolagena
                in je zato koristno za celo telo. Če želimo doseči najboljši možen učinek, je potrebno
                peptidom kolagena dodati snovi, vitamine in minerale, ki podpirajo sintezo kolagena.
                Zato je Synergy ustvaril edinstveno formulacijo Collagen+, ki poleg visokokakovostnih
                peptidov govejega kolagena vsebuje še tri ključne komponente: vitamin C, hialuronsko
                kislino in cink. Vitamin C je ključen za sintezo kolagena, hialuronska kislina zadržuje
                vodo in skrbi za ustrezno hidracijo tkiv, cink pa zavira razvoj encimov, ki razgrajujejo
                kolagen in je nujno potreben za razvoj celic imunskega sistema.</p>,
            EN: <p>Collagen is a protein that makes up 1/3 of all proteins in the body and supports
                skin, hair, nails, as well as bones, cartilage, muscles, and ligaments. After the age of
                20, the aging process begins, which is associated with collagen degradation and an increase
                in enzymes that break down collagen. This leads to less firm and more wrinkled skin, and
                poorer nail and hair quality. Synergy Collagen+ is a high-quality supplement containing
                both types of collagen, making it beneficial for the entire body. To achieve the best
                possible effect, it is necessary to add substances, vitamins, and minerals that support
                collagen synthesis to collagen peptides. That's why Synergy created the unique Collagen+
                formula, which, in addition to high-quality beef collagen peptides, includes three key
                components: vitamin C, hyaluronic acid, and zinc. Vitamin C is essential for collagen
                synthesis, hyaluronic acid retains water and ensures proper tissue hydration, and zinc
                inhibits the development of enzymes that break down collagen and is essential for the
                development of immune system cells.</p>,
            HR: <p>Kolagen je protein koji čini 1/3 svih proteina u tijelu i podržava kožu, kosu,
                nokte, kao i kosti, hrskavicu, mišiće i ligamente. Nakon 20. godine započinje proces
                starenja, koji je povezan s razgradnjom kolagena i povećanjem enzima koji razgrađuju
                kolagen. To dovodi do manje čvrste i više naborane kože te lošije kvalitete noktiju
                i kose. Synergy Collagen+ je kvalitetan dodatak prehrani koji sadrži oba tipa kolagena
                i stoga je koristan za cijelo tijelo. Kako bismo postigli najbolji mogući učinak, potrebno
                je dodati tvari, vitamine i minerale koji podržavaju sintezu kolagena peptidima kolagena.
                Zato je Synergy stvorio jedinstvenu formulu Collagen+, koja osim visokokvalitetnih peptida
                goveđeg kolagena sadrži još tri ključne komponente: vitamin C, hijaluronsku kiselinu i cink.
                Vitamin C je ključan za sintezu kolagena, hijaluronska kiselina zadržava vodu i osigurava
                odgovarajuću hidrataciju tkiva, a cink inhibira razvoj enzima koji razgrađuju kolagen te
                je neophodan za razvoj stanica imunološkog sustava.</p>,
            IT: <p>Il collagene è una proteina che costituisce 1/3 di tutte le proteine del corpo e
                sostiene la pelle, i capelli, le unghie, così come ossa, cartilagini, muscoli e legamenti.
                Dopo i 20 anni, inizia il processo di invecchiamento, che è associato alla degradazione del
                collagene e all'aumento degli enzimi che lo rompono. Ciò porta a una pelle meno soda e più
                rugosa e a una minore qualità delle unghie e dei capelli. Synergy Collagen+ è un integratore
                di alta qualità che contiene entrambi i tipi di collagene, rendendolo benefico per tutto il
                corpo. Per ottenere il miglior effetto possibile, è necessario aggiungere sostanze, vitamine
                e minerali che supportano la sintesi del collagene ai peptidi del collagene. È per questo
                motivo che Synergy ha creato la formula unica Collagen+, che oltre ai peptidi di collagene
                di alta qualità di origine bovina, include tre componenti chiave: vitamina C, acido ialuronico
                e zinco. La vitamina C è essenziale per la sintesi del collagene, l'acido ialuronico trattiene
                l'acqua e garantisce un'idratazione adeguata dei tessuti, mentre lo zinco inibisce lo sviluppo
                degli enzimi che degradano il collagene ed è essenziale per lo sviluppo delle cellule del
                sistema immunitario.</p>,
            DE: <p>Kollagen ist ein Protein, das 1/3 aller Proteine im Körper ausmacht und die Haut, Haare,
                Nägel sowie Knochen, Knorpel, Muskeln und Bänder unterstützt. Nach dem 20. Lebensjahr beginnt
                der Alterungsprozess, der mit dem Abbau von Kollagen und einer Zunahme von Enzymen, die Kollagen
                abbauen, einhergeht. Dies führt zu weniger straffer und stärker faltiger Haut sowie schlechterer
                Nagel- und Haarqualität. Synergy Collagen+ ist ein hochwertiges Nahrungsergänzungsmittel, das
                beide Arten von Kollagen enthält und somit für den gesamten Körper von Vorteil ist. Um den
                bestmöglichen Effekt zu erzielen, ist es notwendig, den Kollagenpeptiden Substanzen, Vitamine
                und Mineralien hinzuzufügen, die die Kollagensynthese unterstützen. Aus diesem Grund hat Synergy
                die einzigartige Collagen+ Formel entwickelt, die neben hochwertigen Rinderkollagenpeptiden auch
                drei Schlüsselkomponenten enthält: Vitamin C, Hyaluronsäure und Zink. Vitamin C ist für die
                Kollagensynthese unerlässlich, Hyaluronsäure speichert Wasser und sorgt für eine angemessene
                Gewebshydratation, und Zink hemmt die Entwicklung von Enzymen, die Kollagen abbauen und ist
                für die Entwicklung von Immunzellen unerlässlich.</p>,
            AT: <p>Kollagen ist ein Protein, das 1/3 aller Proteine im Körper ausmacht und die Haut, Haare,
                Nägel sowie Knochen, Knorpel, Muskeln und Bänder unterstützt. Nach dem 20. Lebensjahr beginnt
                der Alterungsprozess, der mit dem Abbau von Kollagen und einer Zunahme von Enzymen, die Kollagen
                abbauen, einhergeht. Dies führt zu weniger straffer und stärker faltiger Haut sowie schlechterer
                Nagel- und Haarqualität. Synergy Collagen+ ist ein hochwertiges Nahrungsergänzungsmittel, das
                beide Arten von Kollagen enthält und somit für den gesamten Körper von Vorteil ist. Um den
                bestmöglichen Effekt zu erzielen, ist es notwendig, den Kollagenpeptiden Substanzen, Vitamine
                und Mineralien hinzuzufügen, die die Kollagensynthese unterstützen. Aus diesem Grund hat Synergy
                die einzigartige Collagen+ Formel entwickelt, die neben hochwertigen Rinderkollagenpeptiden auch
                drei Schlüsselkomponenten enthält: Vitamin C, Hyaluronsäure und Zink. Vitamin C ist für die
                Kollagensynthese unerlässlich, Hyaluronsäure speichert Wasser und sorgt für eine angemessene
                Gewebshydratation, und Zink hemmt die Entwicklung von Enzymen, die Kollagen abbauen und ist
                für die Entwicklung von Immunzellen unerlässlich.</p>,
            US: <p>Collagen is a protein that makes up 1/3 of all proteins in the body and supports
                skin, hair, nails, as well as bones, cartilage, muscles, and ligaments. After the age of
                20, the aging process begins, which is associated with collagen degradation and an increase
                in enzymes that break down collagen. This leads to less firm and more wrinkled skin, and
                poorer nail and hair quality. Synergy Collagen+ is a high-quality supplement containing
                both types of collagen, making it beneficial for the entire body. To achieve the best
                possible effect, it is necessary to add substances, vitamins, and minerals that support
                collagen synthesis to collagen peptides. That's why Synergy created the unique Collagen+
                formula, which, in addition to high-quality beef collagen peptides, includes three key
                components: vitamin C, hyaluronic acid, and zinc. Vitamin C is essential for collagen
                synthesis, hyaluronic acid retains water and ensures proper tissue hydration, and zinc
                inhibits the development of enzymes that break down collagen and is essential for the
                development of immune system cells.</p>
        },
        html_p: {
            SI: 'Za več informacij o programu kliknite',
            EN: 'For more information about the program, click',
            HR: 'Za više informacija o programu kliknite',
            IT: 'Per ulteriori informazioni sul programma, fare clic su',
            DE: 'Für weitere Informationen über das Programm klicken Sie auf',
            AT: 'Für weitere Informationen über das Programm klicken Sie auf',
            US: 'For more information about the program, click'
        },
        video: 'https://youtu.be/JB7_DfNW_hI',
        video_l10n: {
            SI: 'https://youtu.be/JB7_DfNW_hI',
            EN: 'https://youtu.be/YmXz-yPYufQ',
            HR: 'https://youtu.be/YmXz-yPYufQ',
            IT: 'https://youtu.be/YmXz-yPYufQ',
            DE: 'https://youtu.be/YmXz-yPYufQ',
            AT: 'https://youtu.be/YmXz-yPYufQ',
            US: 'https://youtu.be/YmXz-yPYufQ'
        },
        image: collagenImg
    },
    d3: {
        extra_title: {
            SI: '',
            EN: '',
            HR: '',
            IT: '',
            DE: '',
            AT: '',
            US: '',
        },
        name: {
            SI: 'Vitamin D3',
            EN: 'Vitamin D3',
            HR: 'Vitamin D3',
            IT: 'Vitamin D3',
            DE: 'Vitamin D3',
            AT: 'Vitamin D3',
            US: 'Vitamin D3'
        },
        url: {
            SI: 'https://new.synergyworldwide.com/SI/sl-SI/Shop/Product/vitamin-d3',
            EN: 'https://new.synergyworldwide.com/GB/en-GB/Shop/Product/vitamin-d3',
            HR: 'https://new.synergyworldwide.com/SI/en-SI/Shop/Product/vitamin-d3',
            IT: 'https://new.synergyworldwide.com/IT/it-IT/Shop/Product/vitamin-d3',
            DE: 'https://new.synergyworldwide.com/DE/de-DE/Shop/Product/vitamin-d3',
            AT: 'https://new.synergyworldwide.com/AT/de-AT/Shop/Product/vitamin-d3',
            US: 'https://new.synergyworldwide.com/US/en-US/Shop/Product/vitamin-d3'
        },
        html_desc: {
            SI: <p></p>,
            EN: <p></p>,
            HR: <p></p>,
            IT: '',
            DE: '',
            AT: '',
            US: ''
        },
        html_p: {
            SI: 'Za več informacij o programu kliknite',
            EN: 'For more information about the program, click',
            HR: 'Za više informacija o programu kliknite',
            IT: 'Per ulteriori informazioni sul programma, fare clic su',
            DE: 'Für weitere Informationen über das Programm klicken Sie auf',
            AT: 'Für weitere Informationen über das Programm klicken Sie auf',
            US: 'For more information about the program, click'
        },
        video: 'https://youtu.be/eGM0lI_CGJI',
        video_l10n: {
            SI: 'https://youtu.be/eGM0lI_CGJI',
            EN: 'https://youtu.be/4888aOaQ-Ks',
            HR: 'https://youtu.be/4888aOaQ-Ks',
            IT: 'https://youtu.be/_X4Rq00kays',
            DE: 'https://youtu.be/0-j6ZHF7obE',
            AT: 'https://youtu.be/0-j6ZHF7obE',
            US: 'https://youtu.be/4888aOaQ-Ks'
        },
        image: d3Img
    },
    e9: {
        extra_title: {
            SI: '',
            EN: '',
            HR: '',
            IT: '',
            DE: '',
            AT: '',
            US: '',
        },
        name: {
            SI: 'E9',
            EN: 'E9',
            HR: 'E9',
            IT: 'E9',
            DE: 'E9',
            AT: 'E9',
            US: 'E9'
        },
        url: {
            SI: 'https://new.synergyworldwide.com/SI/sl-SI/Shop/Product/e9',
            EN: 'https://new.synergyworldwide.com/GB/en-GB/Shop/Product/e9',
            HR: 'https://new.synergyworldwide.com/SI/en-SI/Shop/Product/e9',
            IT: 'https://new.synergyworldwide.com/IT/it-IT/Shop/Product/e9',
            DE: 'https://new.synergyworldwide.com/DE/de-DE/Shop/Product/e9',
            AT: 'https://new.synergyworldwide.com/AT/de-AT/Shop/Product/e9',
            US: 'https://new.synergyworldwide.com/US/en-US/Shop/Product/e9'
        },
        html_desc: {
            SI: <p>Zagotovo pa tudi vas življenje kdaj prehiteva in bi vam prav prišlo več energije in boljša zbranost.
                Naravni zeliščni energijski napitek z guarano, 9 aminokislinami, vitamini in minerali - e9 vam lahko pomaga.</p>,
            EN: <p></p>,
            HR: <p></p>,
            IT: '',
            DE: '',
            AT: '',
            US: ''
        },
        html_desc_good: {
            SI: <p>Zagotovo pa tudi vas življenje kdaj prehiteva in bi vam prav prišlo več energije in boljša zbranost.
                Naravni zeliščni energijski napitek z guarano, 9 aminokislinami, vitamini in minerali - e9 vam lahko
                pomaga.</p>,
            EN: <p>Surely, you too sometimes feel overwhelmed by life and could use more energy and better focus. The
                natural herbal energy drink with guarana, 9 amino acids, vitamins, and minerals - e9 can help you.</p>,
            HR: <p>Sigurno se i vi ponekad osjećate preopterećeni životom i trebala bi vam više energije i bolja
                koncentracija. Prirodni biljni energetski napitak s guaranom, 9 aminokiselinama, vitaminima i mineralima
                - e9 može vam pomoći.</p>,
            IT: <p>Sicuramente anche voi vi sentite a volte sopraffatti dalla vita e avreste bisogno di più energia e di
                una maggiore concentrazione. La bevanda energetica naturale a base di erbe con guaranà, 9 amminoacidi,
                vitamine e minerali - e9 può aiutarvi.</p>,
            DE: <p>Sicherlich fühlen auch Sie sich manchmal vom Leben überholt und könnten mehr Energie und eine bessere
                Konzentration gebrauchen. Das natürliche Kräuterenergiegetränk mit Guarana, 9 Aminosäuren, Vitaminen und
                Mineralstoffen - e9 kann Ihnen dabei helfen.</p>,
            AT: <p>Sicherlich fühlen auch Sie sich manchmal vom Leben überholt und könnten mehr Energie und eine bessere
                Konzentration gebrauchen. Das natürliche Kräuterenergiegetränk mit Guarana, 9 Aminosäuren, Vitaminen und
                Mineralstoffen - e9 kann Ihnen dabei helfen.</p>,
            US: <p>Surely, you too sometimes feel overwhelmed by life and could use more energy and better focus. The
                natural herbal energy drink with guarana, 9 amino acids, vitamins, and minerals - e9 can help you.</p>
        },
        html_p: {
            SI: 'Za več informacij o programu kliknite',
            EN: 'For more information about the program, click',
            HR: 'Za više informacija o programu kliknite',
            IT: 'Per ulteriori informazioni sul programma, fare clic su',
            DE: 'Für weitere Informationen über das Programm klicken Sie auf',
            AT: 'Für weitere Informationen über das Programm klicken Sie auf',
            US: 'For more information about the program, click'
        },
        video: 'https://youtu.be/G6vmwWuZMME',
        video_l10n: {
            SI: 'https://youtu.be/G6vmwWuZMME',
            EN: 'https://youtu.be/9EbFKt5geg4',
            HR: 'https://youtu.be/9EbFKt5geg4',
            IT: 'https://youtu.be/9EbFKt5geg4',
            DE: 'https://youtu.be/9EbFKt5geg4',
            AT: 'https://youtu.be/9EbFKt5geg4',
            US: 'https://youtu.be/9EbFKt5geg4'
        },
        image: e9Img
    },
    dnk: {
        extra_title: {
            SI: '',
            EN: '',
            HR: '',
            IT: '',
            DE: '',
            AT: '',
            US: '',
        },
        name: {
            SI: 'My Genetic Body',
            EN: 'My Genetic Body',
            HR: 'My Genetic Body',
            IT: 'My Genetic Body (ANCORA NON DISPONIBILE IN ITALIA)',
            DE: 'My Genetic Body',
            AT: 'My Genetic Body',
            US: 'My Genetic Body (NOT YET AVAILABLE IN US)'
        },
        html_desc: {
            SI: <p> DNK analiza medicinskega laboratorija, s katero lahko pridobite informacije, ki so ključne za
                preprečevanje tistih bolezni in obolenj, ki so na podlagi vaših genov najbolj nevarne za vas. Poleg
                tega pa vam natančno predstavi, katera hrana je za vas zdrava oz. katera vam daje moč in katere se
                morate še posebej izogibati, saj vam povzroča zdravstvene težave in bolezenska stanja. Na skoraj 200
                straneh med drugim ponuja tudi informacijo ali ste nagnjeni k poškodbam, kako vas ogroža oksidativni
                stres, kakšno sposobnost samorazstrupljanja ima vaše telo in poda smernice za optimalno telesno
                aktivnost.</p>,
            EN: <p> DNK analiza medicinskega laboratorija, s katero lahko pridobite informacije, ki so ključne za
                preprečevanje tistih bolezni in obolenj, ki so na podlagi vaših genov najbolj nevarne za vas. Poleg
                tega pa vam natančno predstavi, katera hrana je za vas zdrava oz. katera vam daje moč in katere se
                morate še posebej izogibati, saj vam povzroča zdravstvene težave in bolezenska stanja. Na skoraj 200
                straneh med drugim ponuja tudi informacijo ali ste nagnjeni k poškodbam, kako vas ogroža oksidativni
                stres, kakšno sposobnost samorazstrupljanja ima vaše telo in poda smernice za optimalno telesno
                aktivnost.</p>,
            HR: <p> DNK analiza medicinskog laboratorija, koja vam može pomoći da dobijete ključne informacije za
                sprječavanje bolesti koje su vam najopasnije na temelju vaših gena. Osim toga, točno vam govori koja je
                hrana zdrava za vas ili koja vam daje snagu, a koju biste posebno trebali izbjegavati, jer vam uzrokuje
                zdravstvene probleme i medicinska stanja. Na gotovo 200 stranica, između ostalog, također nudi
                informacije da li ste skloni ozljedama, kako vas ugrožava oksidativni stres, kakvu sposobnost
                samo-detoksikacije vaše tijelo ima i pruža smjernice za optimalnu tjelesnu aktivnost.</p>,
            IT: '',
            DE: '',
            AT: '',
            US: ''
        },
        html_desc_good: {
            SI: <p> DNK analiza medicinskega laboratorija, s katero lahko pridobite informacije, ki so ključne za
                preprečevanje tistih bolezni in obolenj, ki so na podlagi vaših genov najbolj nevarne za vas. Poleg
                tega pa vam natančno predstavi, katera hrana je za vas zdrava oz. katera vam daje moč in katere se
                morate še posebej izogibati, saj vam povzroča zdravstvene težave in bolezenska stanja. Na skoraj 200
                straneh med drugim ponuja tudi informacijo ali ste nagnjeni k poškodbam, kako vas ogroža oksidativni
                stres, kakšno sposobnost samorazstrupljanja ima vaše telo in poda smernice za optimalno telesno
                aktivnost. </p>,
            EN: <p> A DNA analysis from a medical laboratory, which can provide you with information crucial for
                preventing those diseases and conditions that are the most dangerous for you based on your genes.
                In addition, it accurately shows which foods are healthy for you, or which ones give you strength
                and which ones you should especially avoid, as they cause health issues and disease states. Among
                nearly 200 pages, it also offers information on whether you are prone to injuries, how oxidative
                stress threatens you, what self-detoxification ability your body has, and provides guidelines for
                optimal physical activity. </p>,
            HR: <p> DDNK analiza medicinskog laboratorija, s kojom možete dobiti informacije ključne za prevenciju
                onih bolesti i poremećaja koji su na temelju vaših gena najopasniji za vas. Osim toga, točno vam
                pokazuje koja hrana je zdrava za vas, odnosno koja vam daje snagu i koju se morate posebno
                izbjegavati, jer vam uzrokuje zdravstvene probleme i bolesna stanja. Na gotovo 200 stranica, između
                ostalog, nudi informacije o tome jeste li skloni ozljedama, kako vas ugrožava oksidativni stres,
                kakvu sposobnost samoprocjene ima vaše tijelo i daje smjernice za optimalnu tjelesnu
                aktivnost. </p>,
            IT: <p> Un'analisi del DNA effettuata da un laboratorio medico, con la quale è possibile ottenere
                informazioni fondamentali per prevenire quelle malattie e patologie che, sulla base dei vostri geni,
                sono le più pericolose per voi. Inoltre, vi indica con precisione quali alimenti sono sani per voi,
                quali vi danno energia e quali dovete evitare in modo particolare, poiché causano problemi di salute
                e condizioni patologiche. Tra le quasi 200 pagine, offre anche informazioni su se siete inclini a
                infortuni, come lo stress ossidativo vi mette a rischio, quale capacità di autodetossificazione ha
                il vostro corpo e fornisce linee guida per un'attività fisica ottimale. </p>,
            DE: <p> Eine DNA-Analyse eines medizinischen Labors, mit der Sie Informationen erhalten können, die für
                die Verhinderung von Krankheiten und Leiden entscheidend sind, die aufgrund Ihrer Gene für Sie am
                gefährlichsten sind. Außerdem zeigt sie Ihnen genau, welche Lebensmittel für Sie gesund sind bzw.
                welche Ihnen Kraft geben und welche Sie besonders meiden sollten, da sie Ihnen gesundheitliche Probleme
                und Krankheitszustände verursachen. Auf fast 200 Seiten bietet sie unter anderem Informationen darüber,
                ob Sie anfällig für Verletzungen sind, wie oxidativer Stress Sie gefährdet, welche
                Selbstentgiftungsfähigkeit Ihr Körper hat und gibt Richtlinien für optimale körperliche
                Aktivität. </p>,
            AT: <p> Eine DNA-Analyse eines medizinischen Labors, mit der Sie Informationen erhalten können, die für
                die Verhinderung von Krankheiten und Leiden entscheidend sind, die aufgrund Ihrer Gene für Sie am
                gefährlichsten sind. Außerdem zeigt sie Ihnen genau, welche Lebensmittel für Sie gesund sind bzw.
                welche Ihnen Kraft geben und welche Sie besonders meiden sollten, da sie Ihnen gesundheitliche Probleme
                und Krankheitszustände verursachen. Auf fast 200 Seiten bietet sie unter anderem Informationen darüber,
                ob Sie anfällig für Verletzungen sind, wie oxidativer Stress Sie gefährdet, welche
                Selbstentgiftungsfähigkeit Ihr Körper hat und gibt Richtlinien für optimale körperliche
                Aktivität. </p>,
            US: <p> A DNA analysis from a medical laboratory, which can provide you with information crucial for
                preventing those diseases and conditions that are the most dangerous for you based on your genes.
                In addition, it accurately shows which foods are healthy for you, or which ones give you strength
                and which ones you should especially avoid, as they cause health issues and disease states. Among
                nearly 200 pages, it also offers information on whether you are prone to injuries, how oxidative
                stress threatens you, what self-detoxification ability your body has, and provides guidelines for
                optimal physical activity. </p>
        },

        html_p: {
            SI: 'Za več informacij o programu kliknite',
            EN: 'For more information about the program, click',
            HR: 'Za više informacija o programu kliknite',
            IT: 'Per ulteriori informazioni sul programma, fare clic su',
            DE: 'Für weitere Informationen über das Programm klicken Sie auf',
            AT: 'Für weitere Informationen über das Programm klicken Sie auf',
            US: 'For more information about the program, click'
        },

        url: {
            SI: 'https://new.synergyworldwide.com/SI/sl-SI/Shop/Product/dnk-purify-program',
            EN: 'https://new.synergyworldwide.com/GB/en-GB/Shop/Product/dnk-purify-program',
            HR: 'https://new.synergyworldwide.com/SI/en-SI/Shop/Product/dnk-purify-program',
            IT: 'https://new.synergyworldwide.com/SI/en-SI/Shop/Product/dnk-purify-program',
            DE: 'https://new.synergyworldwide.com/DE/de-DE/Shop/Product/dnk-purify-program',
            AT: 'https://new.synergyworldwide.com/AT/de-AT/Shop/Product/dnk-purify-program',
            US: 'https://new.synergyworldwide.com/GB/en-GB/Shop/Product/dnk-purify-program'
        },
        video: 'https://youtu.be/j3VQIXFAuW8',
        video_l10n: {
            SI: 'https://youtu.be/j3VQIXFAuW8',
            EN: 'https://youtu.be/9NwGYq2Kgls',
            HR: 'https://youtu.be/9NwGYq2Kgls',
            IT: 'https://youtu.be/9NwGYq2Kgls',
            DE: 'https://youtu.be/9NwGYq2Kgls',
            AT: 'https://youtu.be/9NwGYq2Kgls',
            US: 'https://youtu.be/9NwGYq2Kgls'
        },
        image: dnkImg
    },
    purify_kit: {
        extra_title: {
            SI: '',
            EN: '',
            HR: '',
            IT: '',
            DE: '',
            AT: '',
            US: '',
        },
        name: {
            SI: 'Purify program',
            EN: 'Purify program',
            HR: 'Purify program',
            IT: 'Purify program',
            DE: 'Purify program',
            AT: 'Purify program',
            US: 'Purify program'
        },
        html_desc: {
            SI: <p> Kot optimalno podporo črevesnemu sistemu so zdravniki in znanstveniki medicinske stroke pri Synergy
                osnovali Purify program za ureditev črevesnega mikrobioma. Program sestavlja pet izdelkov, ki so
                pripravljeni z namenom izboljšanja črevesnega mikrobioma in krepitve črevesja. Sodobne znanstvene
                raziskave ugotavljajo, da so črevesje naši drugi možgani, ki so odgovorni za zdravje vseh ostalih
                telesnih sistemov. Zato je zdravje črevesja izrednega pomena. </p>,
            EN: <p> As an optimal support for the intestinal system, doctors and medical scientists at Synergy have
                developed the Purify program to regulate the intestinal microbiome. The program consists of five products,
                designed with the purpose of improving the intestinal microbiome and strengthening the intestine. Modern
                scientific research finds that the gut is our second brain, responsible for the health of all other body
                systems. Therefore, gut health is of utmost importance.</p>,
            HR: <p> Kao optimalna podrška crijevnom sustavu, liječnici i znanstvenici medicinske struke u Synergyju
                osmislili su program Purify za regulaciju crijevnog mikrobioma. Program se sastoji od pet proizvoda,
                pripremljenih s ciljem poboljšanja crijevnog mikrobioma i jačanja crijeva. Suvremena znanstvena istraživanja
                otkrivaju da su crijeva naš drugi mozak, odgovorni za zdravlje svih ostalih tjelesnih sustava. Stoga je
                zdravlje crijeva iznimno važno. </p>,
            IT: <p> Come supporto ottimale al sistema intestinale, medici e scienziati del settore medico di Synergy
                hanno ideato il programma Purify per regolare il microbioma intestinale. Il programma è composto da
                cinque prodotti, creati con l'obiettivo di migliorare il microbioma intestinale e rafforzare l'intestino.
                La ricerca scientifica moderna riconosce che l'intestino è il nostro secondo cervello, responsabile
                della salute di tutti gli altri sistemi corporei. Pertanto, la salute dell'intestino è di estrema
                importanza. </p>,
            DE: <p> Als optimale Unterstützung für das Darm-System haben Ärzte und Wissenschaftler des medizinischen
                Fachbereichs bei Synergy das Purify-Programm zur Regulierung des Darmmikrobioms entwickelt. Das Programm
                besteht aus fünf Produkten, die mit dem Ziel entwickelt wurden, das Darmmikrobiom zu verbessern und den
                Darm zu stärken. Moderne wissenschaftliche Studien zeigen, dass der Darm unser zweites Gehirn ist, das
                für die Gesundheit aller anderen Körpersysteme verantwortlich ist. Daher ist die Gesundheit des Darms
                von größter Bedeutung. </p>,
            AT: <p> Als optimale Unterstützung für das Darm-System haben Ärzte und Wissenschaftler des medizinischen
                Fachbereichs bei Synergy das Purify-Programm zur Regulierung des Darmmikrobioms entwickelt. Das Programm
                besteht aus fünf Produkten, die mit dem Ziel entwickelt wurden, das Darmmikrobiom zu verbessern und den
                Darm zu stärken. Moderne wissenschaftliche Studien zeigen, dass der Darm unser zweites Gehirn ist, das
                für die Gesundheit aller anderen Körpersysteme verantwortlich ist. Daher ist die Gesundheit des Darms
                von größter Bedeutung. </p>,
            US: <p> As an optimal support for the intestinal system, doctors and medical scientists at Synergy have
                developed the Purify program to regulate the intestinal microbiome. The program consists of five products,
                designed with the purpose of improving the intestinal microbiome and strengthening the intestine. Modern
                scientific research finds that the gut is our second brain, responsible for the health of all other body
                systems. Therefore, gut health is of utmost importance.</p>
        },
        html_desc_good: {
            SI: <p> Kot optimalno podporo črevesnemu sistemu so zdravniki in znanstveniki medicinske stroke pri Synergy
                osnovali Purify program za ureditev črevesnega mikrobioma. Program sestavlja pet izdelkov, ki so
                pripravljeni z namenom izboljšanja črevesnega mikrobioma in krepitve črevesja. Sodobne znanstvene
                raziskave ugotavljajo, da so črevesje naši drugi možgani, ki so odgovorni za zdravje vseh ostalih
                telesnih sistemov. Zato je zdravje črevesja izrednega pomena.</p>,
            EN: <p> As an optimal support for the intestinal system, doctors and medical scientists at Synergy have
                developed the Purify program to regulate the intestinal microbiome. The program consists of five products,
                designed with the purpose of improving the intestinal microbiome and strengthening the intestine. Modern
                scientific research finds that the gut is our second brain, responsible for the health of all other body
                systems. Therefore, gut health is of utmost importance.</p>,
            HR: <p> Kao optimalna podrška crijevnom sustavu, liječnici i znanstvenici medicinske struke u Synergyju
                osmislili su program Purify za regulaciju crijevnog mikrobioma. Program se sastoji od pet proizvoda,
                pripremljenih s ciljem poboljšanja crijevnog mikrobioma i jačanja crijeva. Suvremena znanstvena istraživanja
                otkrivaju da su crijeva naš drugi mozak, odgovorni za zdravlje svih ostalih tjelesnih sustava. Stoga je
                zdravlje crijeva iznimno važno.</p>,
            IT: <p> Come supporto ottimale al sistema intestinale, medici e scienziati del settore medico di Synergy
                hanno ideato il programma Purify per regolare il microbioma intestinale. Il programma è composto da
                cinque prodotti, creati con l'obiettivo di migliorare il microbioma intestinale e rafforzare l'intestino.
                La ricerca scientifica moderna riconosce che l'intestino è il nostro secondo cervello, responsabile
                della salute di tutti gli altri sistemi corporei. Pertanto, la salute dell'intestino è di estrema
                importanza. </p>,
            DE: <p> Als optimale Unterstützung für das Darm-System haben Ärzte und Wissenschaftler des medizinischen
                Fachbereichs bei Synergy das Purify-Programm zur Regulierung des Darmmikrobioms entwickelt. Das Programm
                besteht aus fünf Produkten, die mit dem Ziel entwickelt wurden, das Darmmikrobiom zu verbessern und den
                Darm zu stärken. Moderne wissenschaftliche Studien zeigen, dass der Darm unser zweites Gehirn ist, das
                für die Gesundheit aller anderen Körpersysteme verantwortlich ist. Daher ist die Gesundheit des Darms
                von größter Bedeutung. </p>,
            AT: <p> Als optimale Unterstützung für das Darm-System haben Ärzte und Wissenschaftler des medizinischen
                Fachbereichs bei Synergy das Purify-Programm zur Regulierung des Darmmikrobioms entwickelt. Das Programm
                besteht aus fünf Produkten, die mit dem Ziel entwickelt wurden, das Darmmikrobiom zu verbessern und den
                Darm zu stärken. Moderne wissenschaftliche Studien zeigen, dass der Darm unser zweites Gehirn ist, das
                für die Gesundheit aller anderen Körpersysteme verantwortlich ist. Daher ist die Gesundheit des Darms
                von größter Bedeutung. </p>,
            US: <p> As an optimal support for the intestinal system, doctors and medical scientists at Synergy have
                developed the Purify program to regulate the intestinal microbiome. The program consists of five products,
                designed with the purpose of improving the intestinal microbiome and strengthening the intestine. Modern
                scientific research finds that the gut is our second brain, responsible for the health of all other body
                systems. Therefore, gut health is of utmost importance.</p>
        },
        html_p: {
            SI: 'Za več informacij o programu kliknite',
            EN: 'For more information about the program, click',
            HR: 'Za više informacija o programu kliknite',
            IT: 'Per ulteriori informazioni sul programma, fare clic su',
            DE: 'Für weitere Informationen über das Programm klicken Sie auf',
            AT: 'Für weitere Informationen über das Programm klicken Sie auf',
            US: 'For more information about the program, click'
        },
        url: {
            SI: 'https://new.synergyworldwide.com/SI/sl-SI/Shop/Product/purify-kit',
            EN: 'https://new.synergyworldwide.com/GB/en-GB/Shop/Product/purify-kit',
            HR: 'https://new.synergyworldwide.com/SI/en-SI/Shop/Product/purify-kit',
            IT: 'https://new.synergyworldwide.com/IT/it-IT/Shop/Product/purify-kit',
            DE: 'https://new.synergyworldwide.com/DE/de-DE/Shop/Product/purify-kit',
            AT: 'https://new.synergyworldwide.com/AT/de-AT/Shop/Product/purify-kit',
            US: 'https://new.synergyworldwide.com/US/en-US/Shop/Product/purify-kit'
        },
        video: 'https://youtu.be/jLj8pgKn-64',
        video_l10n: {
            SI: 'https://youtu.be/jLj8pgKn-64',
            EN: 'https://youtu.be/DmkLVzfqPuo',
            HR: 'https://youtu.be/zw2WUNoFzXM',
            IT: 'https://youtu.be/7Umrk5xuCxU',
            DE: 'https://youtu.be/pzBmV8Z-yJo',
            AT: 'https://youtu.be/pzBmV8Z-yJo',
            US: 'https://youtu.be/DmkLVzfqPuo'
        },
        image: purifyImg
    },
    detox: {
        extra_title: {
            SI: '',
            EN: '',
            HR: '',
            IT: '',
            DE: '',
            AT: '',
            US: '',
        },
        name: {
            SI: 'Detox program',
            EN: 'Detox program',
            HR: 'Detox program',
            IT: 'Detox program',
            DE: 'Detox program',
            AT: 'Detox program',
            US: 'Purify program'
        },
        html_desc: {
            SI: <p> Kot optimalno podporo prebavnemu sistemu so zdravniki in znanstveniki medicinske stroke pri Synergy
                osnovali 30 dnevni Detox program za razstrupljanje in ureditev prebave. Vsebuje štiri izdelke, ki se med
                seboj dopolnjujejo in delujejo v sinergiji:

                Body Prime, magnezijev pripravek, ki so mu dodane suhe slive v prahu in jabolčni pektin. Body Prime
                poskrbi za dobro črevesno peristaltiko ter dobro in lahkotno prebavo.

                Biome Actives je kombinacija probiotikov in prebiotikov. Vsebuje 1 milijardo bakterije seva Bacillus
                Coagulans,ki proizvaja mlečno kislino in v črevesju tvori spore, kar pripomore k razraščanju dobrih
                črevesnih bakterij in lahko pomaga pri zaprtju.

                Essential Greens je zdrava in enostavna alternativa za uživanje zelenih hranil brez neprijetnega okusa v
                obliki napitka. Formula v enem odmerku vsebuje mešanico rastlinskih izvlečkov in poskrbi za esencialna
                hranila iz alg, zelišč in zelenjave, ter oskrbi telo s fitonutrienti, antioksidanti in vlakninami.
                Essential Greens podpira zdravo prebavo, alkalizira in razstruplja ter spodbuja gibanje
                gastrointestinalnega trakta.

                ProArgi9+ je patentirana formulacija, ki vsebuje aminokislini L-arginin in L-citrulin ter 6 esencialnih
                vitaminov, ki izboljšajo delovanje celotnega telesa. Spodbuja dobro prekrvavitev in na ta način podpira
                razstrupljanje telesa.</p>,
            EN: <p> As an optimal support for the digestive system, doctors and medical scientists at Synergy have
                developed a 30-day Detox program for detoxification and digestion regulation. It consists of four
                products that complement each other and work in synergy:

                Body Prime, a magnesium preparation with added dried plum powder and apple pectin. Body Prime ensures
                good intestinal peristalsis and easy, light digestion.

                Biome Actives is a combination of probiotics and prebiotics. It contains 1 billion Bacillus Coagulans
                bacteria, which produce lactic acid and form spores in the intestine, promoting the growth of good
                intestinal bacteria and potentially aiding constipation.

                Essential Greens is a healthy and simple alternative to consuming green nutrients without an unpleasant
                taste in a beverage form. The formula in a single dose contains a blend of plant extracts and provides
                essential nutrients from algae, herbs, and vegetables, supplying the body with phytonutrients, antioxidants,
                and fiber. Essential Greens support healthy digestion, alkalize and detoxify, and promote gastrointestinal
                tract movement.

                ProArgi9+ is a patented formulation that contains the amino acids L-arginine and L-citrulline and six
                essential vitamins that improve the functioning of the entire body. It promotes good blood circulation
                and thus supports the detoxification of the body.</p>,
            HR: <p> Kao optimalna podrška probavnom sustavu, liječnici i znanstvenici medicinske struke u Synergyju
                osmislili su 30-dnevni Detox program za detoksikaciju i uređivanje probave. Sadrži četiri proizvoda
                koji se međusobno nadopunjuju i djeluju u sinergiji:

                Body Prime, pripravak magnezija s dodanim prahom suhih šljiva i jabučnim pektinom. Body Prime osigurava
                dobru crijevnu peristaltiku i laganu probavu.

                Biome Actives je kombinacija probiotika i prebiotika. Sadrži 1 milijardu bakterija soja Bacillus Coagulans,
                koje proizvode mliječnu kiselinu i u crijevima stvaraju spore, što pridonosi rastu dobrih crijevnih bakterija
                i može pomoći kod zatvora.

                Essential Greens je zdrava i jednostavna alternativa za konzumaciju zelenih hranjivih tvari bez neugodnog
                okusa u obliku napitka. Formula u jednoj dozi sadrži mješavinu biljnih ekstrakata te osigurava esencijalne
                hranjive tvari iz algi, bilja i povrća, te opskrbljuje tijelo fitonutrijentima, antioksidansima i vlaknima.
                Essential Greens podržava zdravu probavu, alkalizira i detoksificira te potiče pokretanje gastrointestinalnog
                trakta.

                ProArgi9+ je patentirana formulacija koja sadrži aminokiseline L-arginin i L-citrulin te šest esencijalnih
                vitamina koji poboljšavaju rad cijelog tijela. Potiče dobru cirkulaciju krvi i time podržava detoksikaciju
                tijela.</p>,
            IT: <p>Come supporto ottimale per il sistema digestivo, medici e scienziati medici di Synergy hanno sviluppato un
                programma di disintossicazione di 30 giorni per la disintossicazione e la regolazione della digestione.
                È composto da quattro prodotti che si completano a vicenda e lavorano in sinergia:

                Body Prime, un preparato di magnesio con aggiunta di polvere di prugne secche e pectina di mele. Body Prime
                assicura una buona peristalsi intestinale e una digestione facile e leggera.

                Biome Actives è una combinazione di probiotici e prebiotici. Contiene 1 miliardo di batteri del ceppo Bacillus
                Coagulans, che producono acido lattico e formano spore nell'intestino, promuovendo la crescita di buoni batteri
                intestinali e potenzialmente aiutando la stitichezza.

                Essential Greens è un'alternativa sana e semplice per consumare nutrienti verdi senza un gusto sgradevole sotto
                forma di bevanda. La formula in una singola dose contiene una miscela di estratti vegetali e fornisce nutrienti
                essenziali da alghe, erbe e verdure, fornendo al corpo fitonutrienti, antiossidanti e fibre. Essential Greens
                supporta una digestione sana, al calinizza e disintossica e promuove il movimento del tratto gastrointestinale.

                ProArgi9+ è una formulazione brevettata che contiene gli amminoacidi L-arginina e L-citrullina e sei vitamine
                essenziali che migliorano il funzionamento dell'intero corpo. Promuove una buona circolazione sanguigna e quindi
                supporta la disintossicazione del corpo.</p>,
            DE: <p> Als optimale Unterstützung für das Verdauungssystem haben Ärzte und medizinische Wissenschaftler bei
                Synergy ein 30-tägiges Detox-Programm zur Entgiftung und Regulierung der Verdauung entwickelt. Es besteht
                aus vier Produkten, die sich gegenseitig ergänzen und in Synergie wirken:

                Body Prime ist eine Magnesiumpräparation mit zugesetztem Trockenpflaumenpulver und Apfelpektin. Body Prime
                sorgt für eine gute Darmperistaltik und eine einfache, leichte Verdauung.

                Biome Actives ist eine Kombination aus Probiotika und Präbiotika. Es enthält 1 Milliarde Bakterien des Stammes
                Bacillus Coagulans, die Milchsäure produzieren und im Darm Sporen bilden, was das Wachstum guter Darmbakterien
                fördert und möglicherweise bei Verstopfung hilft.

                Essential Greens ist eine gesunde und einfache Alternative zum Verzehr von grünen Nährstoffen ohne unangenehmen
                Geschmack in Form eines Getränks. Die Formel in einer Einzeldosis enthält eine Mischung aus Pflanzenextrakten
                und liefert essentielle Nährstoffe aus Algen, Kräutern und Gemüse sowie Phytonährstoffe, Antioxidantien und
                Ballaststoffe. Essential Greens unterstützen eine gesunde Verdauung, alkalisieren und entgiften und fördern die
                Bewegung des Magen-Darm-Trakts.

                ProArgi9+ ist eine patentierte Formulierung, die die Aminosäuren L-Arginin und L-Citrullin sowie sechs
                essentielle Vitamine enthält, die die Funktion des gesamten Körpers verbessern. Es fördert eine gute
                Durchblutung und unterstützt so die Entgiftung des Körpers.</p>,
            AT: <p> Als optimale Unterstützung für das Verdauungssystem haben Ärzte und medizinische Wissenschaftler bei
                Synergy ein 30-tägiges Detox-Programm zur Entgiftung und Regulierung der Verdauung entwickelt. Es besteht
                aus vier Produkten, die sich gegenseitig ergänzen und in Synergie wirken:

                Body Prime ist eine Magnesiumpräparation mit zugesetztem Trockenpflaumenpulver und Apfelpektin. Body Prime
                sorgt für eine gute Darmperistaltik und eine einfache, leichte Verdauung.

                Biome Actives ist eine Kombination aus Probiotika und Präbiotika. Es enthält 1 Milliarde Bakterien des Stammes
                Bacillus Coagulans, die Milchsäure produzieren und im Darm Sporen bilden, was das Wachstum guter Darmbakterien
                fördert und möglicherweise bei Verstopfung hilft.

                Essential Greens ist eine gesunde und einfache Alternative zum Verzehr von grünen Nährstoffen ohne unangenehmen
                Geschmack in Form eines Getränks. Die Formel in einer Einzeldosis enthält eine Mischung aus Pflanzenextrakten
                und liefert essentielle Nährstoffe aus Algen, Kräutern und Gemüse sowie Phytonährstoffe, Antioxidantien und
                Ballaststoffe. Essential Greens unterstützen eine gesunde Verdauung, alkalisieren und entgiften und fördern die
                Bewegung des Magen-Darm-Trakts.

                ProArgi9+ ist eine patentierte Formulierung, die die Aminosäuren L-Arginin und L-Citrullin sowie sechs
                essentielle Vitamine enthält, die die Funktion des gesamten Körpers verbessern. Es fördert eine gute
                Durchblutung und unterstützt so die Entgiftung des Körpers.</p>,
            US: <p> As an optimal support for the intestinal system, doctors and medical scientists at Synergy have
                developed the Purify program to regulate the intestinal microbiome. The program consists of five products,
                designed with the purpose of improving the intestinal microbiome and strengthening the intestine. Modern
                scientific research finds that the gut is our second brain, responsible for the health of all other body
                systems. Therefore, gut health is of utmost importance.</p>
        },
        html_p: {
            SI: 'Za več informacij o programu kliknite',
            EN: 'For more information about the program, click',
            HR: 'Za više informacija o programu kliknite',
            IT: 'Per ulteriori informazioni sul programma, fare clic su',
            DE: 'Für weitere Informationen über das Programm klicken Sie auf',
            AT: 'Für weitere Informationen über das Programm klicken Sie auf',
            US: 'For more information about the program, click'
        },
        html_desc_good: {
            SI: <p> Kot optimalno podporo prebavnemu sistemu so zdravniki in znanstveniki medicinske stroke pri Synergy
                osnovali 30 dnevni Detox program za razstrupljanje in ureditev prebave. Vsebuje štiri izdelke, ki se med
                seboj dopolnjujejo in delujejo v sinergiji: </p>,
            EN: <p> As an optimal support for the digestive system, doctors and medical scientists at Synergy have
                developed a 30-day Detox program for detoxification and digestion regulation. It consists of four
                products that complement each other and work in synergy. </p>,
            HR: <p> Kao optimalna podrška probavnom sustavu, liječnici i znanstvenici medicinske struke u Synergyju
                osmislili su 30-dnevni Detox program za detoksikaciju i uređivanje probave. Sadrži četiri proizvoda
                koji se međusobno nadopunjuju i djeluju u sinergiji. </p>,
            IT: <p> Come supporto ottimale per il sistema digestivo, medici e scienziati medici di Synergy hanno sviluppato un
                programma di disintossicazione di 30 giorni per la disintossicazione e la regolazione della digestione.
                È composto da quattro prodotti che si completano a vicenda e lavorano in sinergia. </p>,
            DE: <p> Als optimale Unterstützung für das Verdauungssystem haben Ärzte und medizinische Wissenschaftler bei
                Synergy ein 30-tägiges Detox-Programm zur Entgiftung und Regulierung der Verdauung entwickelt. Es besteht
                aus vier Produkten, die sich gegenseitig ergänzen und in Synergie wirken. </p>,
            AT: <p> Als optimale Unterstützung für das Verdauungssystem haben Ärzte und medizinische Wissenschaftler bei
                Synergy ein 30-tägiges Detox-Programm zur Entgiftung und Regulierung der Verdauung entwickelt. Es besteht
                aus vier Produkten, die sich gegenseitig ergänzen und in Synergie wirken. </p>,
            US: <p> As an optimal support for the intestinal system, doctors and medical scientists at Synergy have
                developed the Purify program to regulate the intestinal microbiome. The program consists of five products,
                designed with the purpose of improving the intestinal microbiome and strengthening the intestine. Modern
                scientific research finds that the gut is our second brain, responsible for the health of all other body
                systems. Therefore, gut health is of utmost importance.</p>
        },
        url: {
            SI: 'https://new.synergyworldwide.com/SI/sl-SI/Shop/Product/detox-pack',
            EN: 'https://new.synergyworldwide.com/GB/en-GB/Shop/Product/detox-pack',
            HR: 'https://new.synergyworldwide.com/SI/en-SI/Shop/Product/detox-pack',
            IT: 'https://new.synergyworldwide.com/IT/it-IT/Shop/Product/detox-pack',
            DE: 'https://new.synergyworldwide.com/DE/de-DE/Shop/Product/detox-pack',
            AT: 'https://new.synergyworldwide.com/AT/de-AT/Shop/Product/detox-pack',
            US: 'https://new.synergyworldwide.com/US/en-US/Shop/Product/purify-kit'
        },
        video: 'https://youtu.be/CsSD4YHtXXA',
        video_l10n: {
            SI: 'https://youtu.be/CsSD4YHtXXA',
            EN: 'https://youtu.be/DmkLVzfqPuo',
            HR: 'https://youtu.be/DmkLVzfqPuo',
            IT: 'https://youtu.be/DmkLVzfqPuo',
            DE: 'https://youtu.be/DmkLVzfqPuo',
            AT: 'https://youtu.be/DmkLVzfqPuo',
            US: 'https://youtu.be/DmkLVzfqPuo'
        },
        image: detoxImg
    },
    vital_3: {
        extra_title: {
            SI: '',
            EN: '',
            HR: '',
            IT: '',
            DE: '',
            AT: '',
            US: '',
        },
        name: {
            SI: 'Sistem Vital 3',
            EN: 'System Vital 3',
            HR: 'Sustav Vital 3',
            IT: 'System Vital 3',
            DE: 'System Vital 3',
            AT: 'System Vital 3',
            US: 'System Vital 3'
        },
        html_desc: {
            SI: <p> Sistem Vital 3, ki vam, s pomočjo inovativne kombinacije hranil, ki jih s
                sodobno prehrano ni več mogoče prejeti v zadostni količini, pomaga
                ohranjanjati ravnovesje med telesnimi sistemi. Vital 3 sistem treh
                izdelkov vam pomaga ohranjati dovolj energije, krepiti imunski sistem in
                sproti čistiti vaše telo, da ste lahko kos vsem življenjskim izzivom.</p>,
            EN: <p> The Vital 3 system, which helps you maintain balance between your body systems
                with the help of an innovative combination of nutrients that are no longer available
                in sufficient quantities through modern nutrition. The Vital 3 system of three products
                helps you maintain enough energy, strengthen your immune system, and continuously cleanse
                your body so you can face all life's challenges.</p>,
            HR: <p> Sistem Vital 3, koji vam pomaže održavati ravnotežu između tjelesnih sustava uz pomoć
                inovativne kombinacije hranjivih tvari koje više nisu dostupne u dovoljnim količinama suvremenom
                prehranom. Sistem Vital 3 s tri proizvoda pomaže vam održavati dovoljno energije, jačati imunološki
                sustav i kontinuirano čistiti vaše tijelo kako biste mogli suočiti se sa svim životnim izazovima.</p>,
            IT: <p> Il sistema Vital 3, che ti aiuta a mantenere l'equilibrio tra i sistemi del corpo grazie all'aiuto
                di una combinazione innovativa di nutrienti che non sono più disponibili in quantità sufficienti con
                l'alimentazione moderna. Il sistema Vital 3 di tre prodotti ti aiuta a mantenere abbastanza energia,
                rafforzare il sistema immunitario e pulire continuamente il tuo corpo in modo da poter affrontare tutte
                le sfide della vita.</p>,
            DE: <p> Das Vital 3-System hilft Ihnen, mit Hilfe einer innovativen Kombination von Nährstoffen, die
                durch moderne Ernährung nicht mehr in ausreichenden Mengen aufgenommen werden können, das Gleichgewicht
                zwischen Ihren Körpersystemen aufrechtzuerhalten. Das Vital 3-System aus drei Produkten hilft Ihnen,
                genügend Energie zu erhalten, das Immunsystem zu stärken und Ihren Körper kontinuierlich zu reinigen,
                damit Sie allen Herausforderungen des Lebens gewachsen sind.</p>,
            AT: <p> Das Vital 3-System hilft Ihnen, mit Hilfe einer innovativen Kombination von Nährstoffen, die
                durch moderne Ernährung nicht mehr in ausreichenden Mengen aufgenommen werden können, das Gleichgewicht
                zwischen Ihren Körpersystemen aufrechtzuerhalten. Das Vital 3-System aus drei Produkten hilft Ihnen,
                genügend Energie zu erhalten, das Immunsystem zu stärken und Ihren Körper kontinuierlich zu reinigen,
                damit Sie allen Herausforderungen des Lebens gewachsen sind.</p>,
            US: <p> The Vital 3 system, which helps you maintain balance between your body systems
                with the help of an innovative combination of nutrients that are no longer available
                in sufficient quantities through modern nutrition. The Vital 3 system of three products
                helps you maintain enough energy, strengthen your immune system, and continuously cleanse
                your body so you can face all life's challenges.</p>
        },
        html_p: {
            SI: 'Za več informacij o programu kliknite',
            EN: 'For more information about the program, click',
            HR: 'Za više informacija o programu kliknite',
            IT: 'Per ulteriori informazioni sul programma, fare clic su',
            DE: 'Für weitere Informationen über das Programm klicken Sie auf',
            AT: 'Für weitere Informationen über das Programm klicken Sie auf',
            US: 'For more information about the program, click'
        },
        html_desc_good: {
            SI: <p> Program za splošno zdravje in vzdrževanje dobrega imunskega sistema. </p>,
            EN: <p> Program za splošno zdravje in vzdrževanje dobrega imunskega sistema. </p>,
            HR: <p> Program za splošno zdravje in vzdrževanje dobrega imunskega sistema. </p>,
            IT: <p> Program za splošno zdravje in vzdrževanje dobrega imunskega sistema. </p>,
            DE: <p> Program za splošno zdravje in vzdrževanje dobrega imunskega sistema. </p>,
            AT: <p> Program za splošno zdravje in vzdrževanje dobrega imunskega sistema. </p>,
            US: <p> Program za splošno zdravje in vzdrževanje dobrega imunskega sistema. </p>,
        },
        url: {
            SI: 'https://new.synergyworldwide.com/SI/sl-SI/Shop/Product/v3-combination-packs',
            EN: 'https://new.synergyworldwide.com/GB/en-GB/Shop/Product/v3-combination-packs',
            HR: 'https://new.synergyworldwide.com/SI/en-SI/Shop/Product/v3-combination-packs',
            IT: 'https://new.synergyworldwide.com/IT/it-IT/Shop/Product/v3-combination-packs',
            DE: 'https://new.synergyworldwide.com/DE/de-DE/Shop/Product/v3-combination-packs',
            AT: 'https://new.synergyworldwide.com/AT/de-AT/Shop/Product/v3-combination-packs',
            US: 'https://new.synergyworldwide.com/US/en-US/Shop/Product/v3-chlorophyll-edition'
        },
        video: 'https://youtu.be/qWaOTckT4Jg',
        video_l10n: {
            SI: 'https://youtu.be/qWaOTckT4Jg',
            EN: 'https://youtu.be/AQdiQJ4vxu8',
            HR: 'https://youtu.be/r9VdSJ9neiM',
            IT: 'https://youtu.be/AQdiQJ4vxu8',
            DE: 'https://youtu.be/AQdiQJ4vxu8',
            AT: 'https://youtu.be/AQdiQJ4vxu8',
            US: 'https://youtu.be/AQdiQJ4vxu8'
        },
        image: v3Img
    },
    silverShield: {
        extra_title: {
            SI: '',
            EN: '',
            HR: '',
            IT: '',
            DE: '',
            AT: '',
            US: '',
        },
        name: {
            SI: 'SilverShield Body & Hand Cream',
            EN: 'SilverShield Body & Hand Cream',
            HR: 'SilverShield Body & Hand Cream',
            IT: 'SilverShield Body & Hand Cream',
            DE: 'SilverShield Body & Hand Cream',
            AT: 'SilverShield Body & Hand Cream',
            US: 'SilverShield (NOT YET AVAILABLE IN US)'
        },
        html_desc: {
            SI: <p>Silver Shield krema za roke in telo združuje zaščitno sposobnost koloidnega srebra, proizvedenega s
                posebno patentirano AquaSol tehnologijo in vlažilno ter negovalno sposobnost kokosovega olja, vitamina E
                in hialuronske kisline. Koloidno srebro Silver Shield (20 delcev na milijon) uničuje mikrobe, viruse in
                bakterije in tako ščiti zunanje plasti kože, hkrati pa kožo obnavlja in izboljšuje njeno strukturo.
                Vitamin E, kokosovo olje in hialuronaka kislina kožo vlažijo in spodbujajo njeno celjenje. Koža je po
                uporabi kreme Silver Shield negovana, svilnata in gladka.</p>,
            EN: <p>Silver Shield hand and body cream combines the protective ability of colloidal silver, produced with
                a special patented AquaSol technology, and the moisturizing and nourishing capabilities of coconut oil,
                vitamin E, and hyaluronic acid. Colloidal silver Silver Shield (20 particles per million) destroys microbes,
                viruses, and bacteria, thus protecting the outer layers of the skin while simultaneously rejuvenating the skin
                and improving its structure. Vitamin E, coconut oil, and hyaluronic acid moisturize the skin and promote its
                healing. After using Silver Shield cream, the skin is nourished, silky, and smooth.</p>,
            HR: <p>Silver Shield krema za ruke i tijelo kombinira zaštitnu sposobnost koloidnog srebra, proizvedenog
                posebnom patentiranom AquaSol tehnologijom, te hidratantnu i njegujuću sposobnost kokosovog ulja, vitamina E
                i hijaluronske kiseline. Koloidno srebro Silver Shield (20 čestica na milijun) uništava mikrobe, viruse i
                bakterije te tako štiti vanjske slojeve kože, dok istovremeno obnavlja kožu i poboljšava njezinu strukturu.
                Vitamin E, kokosovo ulje i hijaluronska kiselina vlaže kožu i potiču njezino zacjeljivanje. Nakon uporabe
                Silver Shield kreme, koža je njegovana, svilenkasta i glatka.</p>,
            IT: <p>La crema per mani e corpo Silver Shield combina la capacità protettiva dell'argento colloidale,
                prodotto con una speciale tecnologia brevettata AquaSol, con l'idratazione e le proprietà nutrienti dell'olio
                di cocco, della vitamina E e dell'acido ialuronico. L'argento colloidale Silver Shield (20 particelle per milione)
                distrugge microbi, virus e batteri, proteggendo gli strati esterni della pelle e, allo stesso tempo, rigenerando
                e migliorando la sua struttura. La vitamina E, l'olio di cocco e l'acido ialuronico idratano la pelle e ne
                favoriscono la guarigione. Dopo aver utilizzato la crema Silver Shield, la pelle è nutrita, setosa e liscia.</p>,
            DE: <p>Die Silver Shield Hand- und Körpercreme kombiniert die schützenden Eigenschaften von kolloidalem
                Silber, das mit der speziellen patentierten AquaSol-Technologie hergestellt wird, und die feuchtigkeitsspendenden
                und pflegenden Eigenschaften von Kokosöl, Vitamin E und Hyaluronsäure. Kolloidales Silber Silver Shield (20
                Partikel pro Million) zerstört Mikroben, Viren und Bakterien und schützt so die äußeren Schichten der Haut,
                während es gleichzeitig die Haut regeneriert und ihre Struktur verbessert. Vitamin E, Kokosöl und Hyaluronsäure
                spenden der Haut Feuchtigkeit und fördern ihre Heilung. Nach der Anwendung der Silver Shield-Creme ist die Haut
                gepflegt, seidig und glatt.</p>,
            AT: <p>Die Silver Shield Hand- und Körpercreme kombiniert die schützenden Eigenschaften von kolloidalem
                Silber, das mit der speziellen patentierten AquaSol-Technologie hergestellt wird, und die feuchtigkeitsspendenden
                und pflegenden Eigenschaften von Kokosöl, Vitamin E und Hyaluronsäure. Kolloidales Silber Silver Shield (20
                Partikel pro Million) zerstört Mikroben, Viren und Bakterien und schützt so die äußeren Schichten der Haut,
                während es gleichzeitig die Haut regeneriert und ihre Struktur verbessert. Vitamin E, Kokosöl und Hyaluronsäure
                spenden der Haut Feuchtigkeit und fördern ihre Heilung. Nach der Anwendung der Silver Shield-Creme ist die Haut
                gepflegt, seidig und glatt.</p>,
            US: <p>Silver Shield hand and body cream combines the protective ability of colloidal silver, produced with
                a special patented AquaSol technology, and the moisturizing and nourishing capabilities of coconut oil,
                vitamin E, and hyaluronic acid. Colloidal silver Silver Shield (20 particles per million) destroys microbes,
                viruses, and bacteria, thus protecting the outer layers of the skin while simultaneously rejuvenating the skin
                and improving its structure. Vitamin E, coconut oil, and hyaluronic acid moisturize the skin and promote its
                healing. After using Silver Shield cream, the skin is nourished, silky, and smooth.</p>
        },
        html_p: {
            SI: 'Za več informacij o programu kliknite',
            EN: 'For more information about the program, click',
            HR: 'Za više informacija o programu kliknite',
            IT: 'Per ulteriori informazioni sul programma, fare clic su',
            DE: 'Für weitere Informationen über das Programm klicken Sie auf',
            AT: 'Für weitere Informationen über das Programm klicken Sie auf',
            US: 'For more information about the program, click'
        },
        html_desc_good: {
            SI: <p></p>,
            EN: <p></p>,
            HR: <p></p>,
            IT: <p></p>,
            DE: <p></p>,
            AT: <p></p>,
            US: <p></p>,
        },
        url: {
            SI: 'https://new.synergyworldwide.com/SI/sl-SI/Shop/Product/silver-shield-hand--body',
            EN: 'https://new.synergyworldwide.com/GB/en-GB/Shop/Product/silver-shield-hand--body',
            HR: 'https://new.synergyworldwide.com/SI/en-SI/Shop/Product/silver-shield-hand--body',
            IT: 'https://new.synergyworldwide.com/IT/it-IT/Shop/Product/silver-shield-hand--body',
            DE: 'https://new.synergyworldwide.com/DE/de-DE/Shop/Product/silver-shield-hand--body',
            AT: 'https://new.synergyworldwide.com/AT/de-AT/Shop/Product/silver-shield-hand--body',
            US: 'https://new.synergyworldwide.com/GB/en-GB/Shop/Product/silver-shield-hand--body'
        },
        video: 'https://youtu.be/w-w0EdxTIFM',
        video_l10n: {
            SI: 'https://youtu.be/w-w0EdxTIFM',
            EN: 'https://youtu.be/K8p7EmDEWJI',
            HR: 'https://youtu.be/3XMk-ZIn0PE',
            IT: 'https://youtu.be/K8p7EmDEWJI',
            DE: 'https://youtu.be/K8p7EmDEWJI',
            AT: 'https://youtu.be/K8p7EmDEWJI',
            US: 'https://youtu.be/K8p7EmDEWJI'
        },
        image: silverImg
    },
    sleepWell: {
        extra_title: {
            SI: 'DODATNO PRIPOROČILO',
            EN: 'ADDITIONAL RECOMMENDATION',
            HR: 'DODATNA PREPORUKA',
            IT: 'RACCOMANDAZIONE AGGIUNTIVA',
            DE: 'ZUSÄTZLICHE EMPFEHLUNG',
            AT: 'ZUSÄTZLICHE EMPFEHLUNG',
            US: 'ADDITIONAL RECOMMENDATION',
        },
        name: {
            SI: 'SleepWell',
            EN: 'SleepWell',
            HR: 'SleepWell',
            IT: 'SleepWell',
            DE: 'SleepWell',
            AT: 'SleepWell',
            US: 'SleepWell (NOT YET AVAILABLE IN US)',
        },
        html_desc: {
            SI: <p>Za boljši in kvalitetnejši spanec ter umirjenost vam priporočamo naravni zeliščni pripravek
                SleepWell.<br/><br/>
                SleepWell vsebuje izvleček korenine baldrijana, pasijonko in cvetove hmelja. Pasijonka slovi kot
                rastlina, ki pomirja in spodbuja spanec, baldrijan pa se že od nekdaj uporablja za podporo naravnega in
                zdravega spanca. Izvleček korenine baldrijana slovi kot sestavina, ki nas pomirja in nam pomaga do
                prepotrebnega krepčilnega spanca in počitka.Sleepwell ne povzroča jutranje vrtoglavice in ne zasvoji. V
                telo enakomerno izloča izvleček korenine baldrijana, ki pripravi telo na sproščen spanec brez nenadne
                zaspanosti.</p>,
            EN: <p>For better quality sleep and calmness, we recommend SleepWell, a natural herbal preparation.<br/><br/>
                SleepWell contains valerian root extract, passionflower and hops flowers. Passionflower is known as a calming
                and sleep-promoting herb, while valerian has been used for ages to support natural and healthy sleep. Valerian
                root extract has a reputation as a calming ingredient that helps us get much-needed restorative sleep and rest.
                Sleepwell does not cause morning dizziness and is not addictive. It evenly releases valerian root extract into
                the body, preparing the body for a relaxed sleep without sudden drowsiness.</p>,
            HR: <p>Za bolji i kvalitetniji san i smirenost preporučujemo prirodni biljni pripravak SleepWell.<br/><br/>
                SleepWell sadrži ekstrakt korijena valerijane, pasiflore i cvjetova hmelja. Pasiflora je poznata kao umirujuće
                i poticajno sredstvo za spavanje, dok se valerijana koristi već stoljećima za potporu prirodnom i zdravom snu.
                Ekstrakt korijena valerijane ima reputaciju umirujućeg sastojka koji nam pomaže dobiti potreban obnovitveni san
                i odmor. SleepWell ne uzrokuje jutarnje vrtoglavice i nije zarazan. Ravnomjerno oslobađa ekstrakt korijena
                valerijane u tijelo, pripremajući tijelo za opušteni san bez iznenadne pospanosti.</p>,
            IT: <p>Per migliorare la qualità del sonno e la calma, consigliamo SleepWell, un preparato naturale a
                base di erbe.<br/><br/>
                SleepWell contiene estratto di radice di valeriana, passiflora e fiori di luppolo. La passiflora è nota
                come come erba calmante e promotrice del sonno, mentre la valeriana è stata usata per secoli per favorire
                un sonno naturale e sano. sonno naturale e sano. L'estratto di radice di valeriana è rinomato come ingrediente
                calmante che ci aiuta a Sleepwell non provoca vertigini mattutine e non crea dipendenza. Nel corpo, secerne
                uniformemente l'estratto di radice di valeriana, che prepara l'organismo a un sonno rilassato senza improvvise
                sonnolenza.</p>,
            DE: <p>Für eine bessere Schlafqualität und Ruhe empfehlen wir SleepWell, ein natürliches Kräuterpräparat. <br/><br/>
                SleepWell enthält Baldrianwurzelextrakt, Passionsblume und Hopfenblüten. Passionsblume ist bekannt als ein
                beruhigendes und schlafförderndes Kraut, während Baldrian seit Jahrhunderten zur Unterstützung eines natürlichen
                und gesunden Schlafs verwendet wird. Baldrianwurzelextrakt hat den Ruf, eine beruhigende Zutat zu sein, die uns
                hilft, den dringend benötigten erholsamen Schlaf und Ruhe zu bekommen. SleepWell verursacht keine morgendliche
                Schwindel und macht nicht süchtig. Es gibt den Baldrianwurzelextrakt gleichmäßig in den Körper ab und bereitet
                den Körper auf einen entspannten Schlaf ohne plötzliche Schläfrigkeit vor.</p>,
            AT: <p>Für eine bessere Schlafqualität und Ruhe empfehlen wir SleepWell, ein natürliches Kräuterpräparat. <br/><br/>
                SleepWell enthält Baldrianwurzelextrakt, Passionsblume und Hopfenblüten. Passionsblume ist bekannt als ein
                beruhigendes und schlafförderndes Kraut, während Baldrian seit Jahrhunderten zur Unterstützung eines natürlichen
                und gesunden Schlafs verwendet wird. Baldrianwurzelextrakt hat den Ruf, eine beruhigende Zutat zu sein, die uns
                hilft, den dringend benötigten erholsamen Schlaf und Ruhe zu bekommen. SleepWell verursacht keine morgendliche
                Schwindel und macht nicht süchtig. Es gibt den Baldrianwurzelextrakt gleichmäßig in den Körper ab und bereitet
                den Körper auf einen entspannten Schlaf ohne plötzliche Schläfrigkeit vor.</p>,
            US: <p>For better quality sleep and calmness, we recommend SleepWell, a natural herbal preparation.<br/><br/>
                SleepWell contains valerian root extract, passionflower and hops flowers. Passionflower is known as a calming
                and sleep-promoting herb, while valerian has been used for ages to support natural and healthy sleep. Valerian
                root extract has a reputation as a calming ingredient that helps us get much-needed restorative sleep and rest.
                Sleepwell does not cause morning dizziness and is not addictive. It evenly releases valerian root extract into
                the body, preparing the body for a relaxed sleep without sudden drowsiness.</p>,
        },
        html_p: {
            SI: 'Za več informacij o programu kliknite',
            EN: 'For more information about the program, click',
            HR: 'Za više informacija o programu kliknite',
            IT: 'Per ulteriori informazioni sul programma, fare clic su',
            DE: 'Für weitere Informationen über das Programm klicken Sie auf',
            AT: 'Für weitere Informationen über das Programm klicken Sie auf',
            US: 'For more information about the program, click'
        },
        html_desc_good: {
            SI: <p>Za boljši in kvalitetnejši spanec ter umirjenost vam priporočamo naravni zeliščni pripravek
                SleepWell.<br/><br/>
                SleepWell vsebuje izvleček korenine baldrijana, pasijonko in cvetove hmelja. Pasijonka slovi kot
                rastlina, ki pomirja in spodbuja spanec, baldrijan pa se že od nekdaj uporablja za podporo naravnega in
                zdravega spanca. Izvleček korenine baldrijana slovi kot sestavina, ki nas pomirja in nam pomaga do
                prepotrebnega krepčilnega spanca in počitka.Sleepwell ne povzroča jutranje vrtoglavice in ne zasvoji. V
                telo enakomerno izloča izvleček korenine baldrijana, ki pripravi telo na sproščen spanec brez nenadne
                zaspanosti.</p>,
            EN: <p>For better quality sleep and calmness, we recommend SleepWell, a natural herbal preparation.<br/><br/>
                SleepWell contains valerian root extract, passionflower and hops flowers. Passionflower is known as a calming
                and sleep-promoting herb, while valerian has been used for ages to support natural and healthy sleep. Valerian
                root extract has a reputation as a calming ingredient that helps us get much-needed restorative sleep and rest.
                Sleepwell does not cause morning dizziness and is not addictive. It evenly releases valerian root extract into
                the body, preparing the body for a relaxed sleep without sudden drowsiness.</p>,
            HR: <p>Za bolji i kvalitetniji san i smirenost preporučujemo prirodni biljni pripravak SleepWell.<br/><br/>
                SleepWell sadrži ekstrakt korijena valerijane, pasiflore i cvjetova hmelja. Pasiflora je poznata kao umirujuće
                i poticajno sredstvo za spavanje, dok se valerijana koristi već stoljećima za potporu prirodnom i zdravom snu.
                Ekstrakt korijena valerijane ima reputaciju umirujućeg sastojka koji nam pomaže dobiti potreban obnovitveni san
                i odmor. SleepWell ne uzrokuje jutarnje vrtoglavice i nije zarazan. Ravnomjerno oslobađa ekstrakt korijena
                valerijane u tijelo, pripremajući tijelo za opušteni san bez iznenadne pospanosti.</p>,
            IT: <p>Per migliorare la qualità del sonno e la calma, consigliamo SleepWell, un preparato naturale a
                base di erbe.<br/><br/>
                SleepWell contiene estratto di radice di valeriana, passiflora e fiori di luppolo. La passiflora è nota
                come come erba calmante e promotrice del sonno, mentre la valeriana è stata usata per secoli per favorire
                un sonno naturale e sano. sonno naturale e sano. L'estratto di radice di valeriana è rinomato come ingrediente
                calmante che ci aiuta a Sleepwell non provoca vertigini mattutine e non crea dipendenza. Nel corpo, secerne
                uniformemente l'estratto di radice di valeriana, che prepara l'organismo a un sonno rilassato senza improvvise
                sonnolenza.</p>,
            DE: <p>Für eine bessere Schlafqualität und Ruhe empfehlen wir SleepWell, ein natürliches Kräuterpräparat. <br/><br/>
                SleepWell enthält Baldrianwurzelextrakt, Passionsblume und Hopfenblüten. Passionsblume ist bekannt als ein
                beruhigendes und schlafförderndes Kraut, während Baldrian seit Jahrhunderten zur Unterstützung eines natürlichen
                und gesunden Schlafs verwendet wird. Baldrianwurzelextrakt hat den Ruf, eine beruhigende Zutat zu sein, die uns
                hilft, den dringend benötigten erholsamen Schlaf und Ruhe zu bekommen. SleepWell verursacht keine morgendliche
                Schwindel und macht nicht süchtig. Es gibt den Baldrianwurzelextrakt gleichmäßig in den Körper ab und bereitet
                den Körper auf einen entspannten Schlaf ohne plötzliche Schläfrigkeit vor.</p>,
            AT: <p>Für eine bessere Schlafqualität und Ruhe empfehlen wir SleepWell, ein natürliches Kräuterpräparat. <br/><br/>
                SleepWell enthält Baldrianwurzelextrakt, Passionsblume und Hopfenblüten. Passionsblume ist bekannt als ein
                beruhigendes und schlafförderndes Kraut, während Baldrian seit Jahrhunderten zur Unterstützung eines natürlichen
                und gesunden Schlafs verwendet wird. Baldrianwurzelextrakt hat den Ruf, eine beruhigende Zutat zu sein, die uns
                hilft, den dringend benötigten erholsamen Schlaf und Ruhe zu bekommen. SleepWell verursacht keine morgendliche
                Schwindel und macht nicht süchtig. Es gibt den Baldrianwurzelextrakt gleichmäßig in den Körper ab und bereitet
                den Körper auf einen entspannten Schlaf ohne plötzliche Schläfrigkeit vor.</p>,
            US: <p>For better quality sleep and calmness, we recommend SleepWell, a natural herbal preparation.<br/><br/>
                SleepWell contains valerian root extract, passionflower and hops flowers. Passionflower is known as a calming
                and sleep-promoting herb, while valerian has been used for ages to support natural and healthy sleep. Valerian
                root extract has a reputation as a calming ingredient that helps us get much-needed restorative sleep and rest.
                Sleepwell does not cause morning dizziness and is not addictive. It evenly releases valerian root extract into
                the body, preparing the body for a relaxed sleep without sudden drowsiness.</p>,
        },
        url: {
            SI: 'https://new.synergyworldwide.com/SI/sl-SI/Shop/Product/sleepwell',
            EN: 'https://new.synergyworldwide.com/GB/en-GB/Shop/Product/sleepwell',
            HR: 'https://new.synergyworldwide.com/SI/en-SI/Shop/Product/sleepwell',
            IT: 'https://new.synergyworldwide.com/IT/it-IT/Shop/Product/sleepwell',
            DE: 'https://new.synergyworldwide.com/DE/de-DE/Shop/Product/sleepwell',
            AT: 'https://new.synergyworldwide.com/AT/de-AT/Shop/Product/sleepwell',
            US: 'https://new.synergyworldwide.com/GB/en-GB/Shop/Product/sleepwell'
        },
        video: 'https://youtu.be/3Xo_PBnXnh8',
        video_l10n: {
            SI: 'https://youtu.be/3Xo_PBnXnh8',
            EN: 'https://youtu.be/t4ExjsKkwoE',
            HR: 'https://youtu.be/t4ExjsKkwoE',
            IT: 'https://youtu.be/t4ExjsKkwoE',
            DE: 'https://youtu.be/t4ExjsKkwoE',
            AT: 'https://youtu.be/t4ExjsKkwoE',
            US: 'https://youtu.be/t4ExjsKkwoE'
        },
        image: sleepWellImg
    }
};

export const RESULTS = {
    "Prebavni": {
        basic: [
            PRODUCTS.bodyPrime
        ],
        optimal: [
            PRODUCTS.detox,
        ]
    },
    "Črevesni": {
        basic: [
            PRODUCTS.biomeActives
        ],
        optimal: [
            PRODUCTS.purify_kit,
        ]
    },
    "Krvožilni": {
        basic: [
            PRODUCTS.proArgi
        ],
        optimal: [
            PRODUCTS.synergy,
        ]
    },
    "Živčni": {
        basic: [
            PRODUCTS.proArgi
        ],
        optimal: [
            PRODUCTS.v3,
        ]
    },
    "Imunski": {
        basic: [
            PRODUCTS.mistify,
        ],
        optimal: [
            PRODUCTS.v3
        ]
    },
    "Respiratorni": {
        basic: [
            PRODUCTS.mistify,
        ],
        optimal: [
            PRODUCTS.v3
        ]
    },
    "Urinarni": {
        basic: [
            PRODUCTS.proArgi
        ],
        optimal: [
            PRODUCTS.v3
        ]
    },
    "Hormonski": {
        basic: [
            PRODUCTS.proArgi
        ],
        optimal: [
            PRODUCTS.v3,
        ]
    },
    "Skelet/koža": {
        basic: [],
        optimal: []
    }
};


export const BIO_SYSTEMS = {
    'Prebavni': 'A',
    'Črevesni': 'B',
    'Krvožilni': 'C',
    'Živčni': 'D',
    'Imunski': 'E',
    'Respiratorni': 'F',
    'Urinarni': 'G',
    'Hormonski': 'H',
    'Skelet/koža': 'I',

    'A': 'Prebavni',
    'B': 'Črevesni',
    'C': 'Krvožilni',
    'D': 'Živčni',
    'E': 'Imunski',
    'F': 'Respiratorni',
    'G': 'Urinarni',
    'H': 'Hormonski',
    'I': 'Skelet/koža',
};

//444543243
export const BIO_SYSTEM_NORMAL_LIMITS = {
    'Prebavni': 5,
    'Črevesni': 5,
    'Krvožilni': 6,
    'Živčni': 5,
    'Imunski': 5,
    'Respiratorni': 5,
    'Urinarni': 6,
    'Hormonski': 6,
    'Skelet/koža': 5,
}

export const BIO_SYSTEMS_ARRAY = [
    'Prebavni',
    'Črevesni',
    'Krvožilni',
    'Živčni',
    'Imunski',
    'Respiratorni',
    'Urinarni',
    'Hormonski',
    'Skelet/koža',
];

export const BIO_SYSTEMS_ARRAY_SORT_BY_IMPORTANCE = [
    'Črevesni',
    'Prebavni',
    'Krvožilni',
    'Živčni',
    'Imunski',
    'Respiratorni',
    'Urinarni',
    'Hormonski',
    'Skelet/koža',
];

export let SCORE_MATRIX = {
    'Prebavni': [0, 2, 3, 9, 13, 14, 16, 23, 25, 28, 30, 34, 37, 40, 41, 42, 43],
    'Črevesni': [2, 4, 5, 6, 16, 18, 19, 25, 28, 29, 30, 32, 40, 41, 42, 44, 45, 46],
    'Krvožilni': [0, 4, 10, 11, 12, 15, 24, 25, 27, 29, 32, 33, 36, 42, 45],
    'Živčni': [0, 7, 8, 10, 11, 12, 15, 17, 19, 20, 21, 24, 26, 30, 31, 33, 37, 38, 39, 42, 43, 44, 45],
    'Imunski': [0, 1, 3, 6, 9, 13, 14, 15, 26, 35, 39, 40, 41, 42, 45],
    'Respiratorni': [2, 4, 9, 11, 18, 27, 34, 46],
    'Urinarni': [0, 2, 5, 10, 16, 22, 34],
    'Hormonski': [5, 7, 8, 12, 15, 16, 17, 19, 20, 21, 24, 26, 27, 28, 29, 31, 36, 37, 38, 39, 44, 45],
    'Skelet/koža': [16, 20, 21, 23, 24, 26, 33, 38, 39, 42, 45]
};

export const BIO_SYSTEM_SI = {
    'Prebavni': 'Prebavnega',
    'Črevesni': 'Črevesnega',
    'Krvožilni': 'Krvožilnega',
    'Živčni': 'Živčnega',
    'Imunski': 'Imunskega',
    'Respiratorni': 'Respiratornega',
    'Urinarni': 'Urinarnega',
    'Hormonski': 'Hormonskega',
    'Skelet/koža': 'Skeletnega / kožnega',
}

export const BIO_SYSTEM_HR = {
    'Prebavni': 'Probavnog',
    'Črevesni': 'Crijevnog',
    'Krvožilni': 'Krvožilnog',
    'Živčni': 'Živčanog',
    'Imunski': 'Imuno',
    'Respiratorni': 'Respiratornog',
    'Urinarni': 'Mokraćnog',
    'Hormonski': 'Endokrinološkog',
    'Skelet/koža': 'Zdravlje kosti/kože',
}

export const BIO_SYSTEM_EN = {
    'Prebavni': 'Digestive',
    'Črevesni': 'Intestinal',
    'Krvožilni': 'Circulatory',
    'Živčni': 'Nervous',
    'Imunski': 'Immune',
    'Respiratorni': 'Respiratory',
    'Urinarni': 'Urinary',
    'Hormonski': 'Hormonal',
    'Skelet/koža': 'Skeletal/Skin',
}

export const BIO_SYSTEM_HR_ROOT = {
    'Prebavni': 'Probavni',
    'Črevesni': 'Crijevni',
    'Krvožilni': 'Krvožilni',
    'Živčni': 'Živčani',
    'Imunski': 'Imuno',
    'Respiratorni': 'Respiratorni',
    'Urinarni': 'Mokraćni',
    'Hormonski': 'Endokrinološki',
    'Skelet/koža': 'Kosti/koža',
}
export const BIO_SYSTEM_HR_ROOT_REVERSED = {
    'Probavni': 'Prebavni',
    'Crijevni': 'Črevesni',
    'Krvožilni': 'Krvožilni',
    'Živčani': 'Živčni',
    'Imuno': 'Imunski',
    'Respiratorni': 'Respiratorni',
    'Mokraćni': 'Urinarni',
    'Endokrinološki': 'Hormonski',
    'Kosti/koža': 'Skelet/koža',
}

export const BIO_SYSTEM_EN_ROOT = {
    'Prebavni': 'Digestive',
    'Črevesni': 'Intestinal',
    'Krvožilni': 'Vascular',
    'Živčni': 'Nervous',
    'Imunski': 'Immune',
    'Respiratorni': 'Respiratory',
    'Urinarni': 'Urinary',
    'Hormonski': 'Hormonal',
    'Skelet/koža': 'Skeleton/Skin',
}
export const BIO_SYSTEM_EN_ROOT_REVERSED = {
    'Digestive': 'Prebavni',
    'Intestinal': 'Črevesni',
    'Vascular': 'Krvožilni',
    'Nervous': 'Živčni',
    'Immune': 'Imunski',
    'Respiratory': 'Respiratorni',
    'Urinary': 'Urinarni',
    'Hormonal': 'Hormonski',
    'Skeleton/Skin': 'Skelet/koža',
}


export const BIO_SYSTEM_DE_ROOT = {
    'Prebavni': 'Verdauungs',
    'Črevesni': 'Darm',
    'Krvožilni': 'Gefäß',
    'Živčni': 'Nerven',
    'Imunski': 'Immun',
    'Respiratorni': 'Atmung',
    'Urinarni': 'Uric',
    'Hormonski': 'Endokrinologisch',
    'Skelet/koža': 'Knochen/Haut',
}
export const BIO_SYSTEM_DE_ROOT_REVERSED = {
    'Verdauungs': 'Prebavni',
    'Darm': 'Črevesni',
    'Gefäß': 'Krvožilni',
    'Nerven': 'Živčni',
    'Immun': 'Imunski',
    'Atmung': 'Respiratorni',
    'Uric': 'Urinarni',
    'Endokrinologisch': 'Hormonski',
    'Knochen/Haut': 'Skelet/koža',
}
export const BIO_SYSTEM_IT_ROOT = {
    'Prebavni': 'Digestivo',
    'Črevesni': 'Intestinale',
    'Krvožilni': 'Vascolare',
    'Živčni': 'Nervoso',
    'Imunski': 'Immune',
    'Respiratorni': 'Respiratorio',
    'Urinarni': 'Urico',
    'Hormonski': 'Endocrinologico',
    'Skelet/koža': 'Ossa/pelle',
}
export const BIO_SYSTEM_IT_ROOT_REVERSED = {
    'Digestivo': 'Prebavni',
    'Intestinale': 'Črevesni',
    'Vascolare': 'Krvožilni',
    'Nervoso': 'Živčni',
    'Immune': 'Imunski',
    'Respiratorio': 'Respiratorni',
    'Urico': 'Urinarni',
    'Endocrinologico': 'Hormonski',
    'Skelet/Ossa/pelle': 'Skelet/koža',
}


export const QUESTIONS = {
    SI: [
        {
            name: 'Želim imeti več energije',
            gender: [GENDER.male, GENDER.female],
            description: 'Si želite več energije na splošno?',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Pogosto zbolim (enkrat/dvakrat letno)',
            gender: [GENDER.male, GENDER.female],
            description: 'Letno en ali dva prehlada, različne viroze, gripa ali podobne bolezni.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Imam neprijeten telesni vonj in/ali slab zadah',
            gender: [GENDER.male, GENDER.female],
            description: 'Močan, neprijeten vonj potu, slab zadah, vonj nog.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Imam težave s prebavljanjem določenih vrst hrane',
            gender: [GENDER.male, GENDER.female],
            description: 'Napihnjenost, napenjanje, vetrovi, pretakanje po črevesju, zgaga, refluks, želodčna kislina, izogibanje dol. vrstam hrane zaradi prebavnih težav.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Uživam rdeče meso vsaj dvakrat tedensko',
            gender: [GENDER.male, GENDER.female],
            description: 'Tudi salame in druge mesnine ter jagnjetina.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Imam težave z mesečnim ciklom (ženske)',
            gender: [GENDER.male, GENDER.female],
            description: 'PMS, boleče ovulacije, boleče in neredne menstruacije.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Uporabljam antibiotike / zdravila (zadnja 3 leta)',
            gender: [GENDER.male, GENDER.female],
            description: 'Kakršnakoli zdravila, hormonske terapije, kontracepcijske tablete.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Redno uživam alkohol',
            gender: [GENDER.male, GENDER.female],
            description: 'Večkrat na teden ali samo med vikendom.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Opažam nihanja razpoloženja',
            gender: [GENDER.male, GENDER.female],
            description: 'Tudi povezana s PMS.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Imam alergije na hrano',
            gender: [GENDER.male, GENDER.female],
            description: 'Tudi izogibanje dol. vrstam hrane zaradi težav.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Imam temne podočnjake',
            gender: [GENDER.male, GENDER.female],
            description: 'Temni kolobarji pod očmi.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Redno / občasno kadim (tudi pasivno)',
            gender: [GENDER.male, GENDER.female],
            description: 'Pogosto v družbi z ljudmi, ki kadijo.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Opažam slabši spomin in koncentracijo',
            gender: [GENDER.male, GENDER.female],
            description: 'Čutim, da ni tako kot bi moralo biti.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Opažam slabšo odpornost',
            gender: [GENDER.male, GENDER.female],
            description: 'Pozimi pogosto zbolim, nagnjenost k okužbam, na primer herpes, ...',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Po hrani se pogosto počutim slabo',
            gender: [GENDER.male, GENDER.female],
            description: 'Napihnjenost, zaspanost, utrujenost po zaužiti hrani.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Pogosto sem pod stresom',
            gender: [GENDER.male, GENDER.female],
            description: 'Veliko obveznosti, premalo počitka in sprostitve, občutek, da smo pod pritiskom.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Imam težave s kožo',
            gender: [GENDER.male, GENDER.female],
            description: 'Akne, mozolji, suha koža, luskavica, atopični dermatitis.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Pogosto občutim željo po sladkarijah in nezdravi hrani',
            gender: [GENDER.male, GENDER.female],
            description: 'Čokolada, pecivo, sladice, slani prigrizki, hitra prehrana.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Uživam mlečne izdelke',
            gender: [GENDER.male, GENDER.female],
            description: 'Mleko, jogurt, sir, tudi mleko v kavi ali čaju.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Pogosto se počutim brezvoljno in apatično',
            gender: [GENDER.male, GENDER.female],
            description: 'Občutek depresije, brez volje do življenja.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Slabo in nemirno spim',
            gender: [GENDER.male, GENDER.female],
            description: 'Težko zaspimo, nemirno spimo, se zbudimo utrujeni, čez dan smo neprespani.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Soočam se s težavami povezanimi z menopavzo (ženske)',
            gender: [GENDER.male, GENDER.female],
            description: 'Vročinski valovi, nihanje razpoloženja.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Opažam težave z uriniranjem',
            gender: [GENDER.male, GENDER.female],
            description: 'Vstajanje ponoči (WC), težave z inkotinenco, druge težave z urogenitalnim traktom.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Imam krhke nohte',
            gender: [GENDER.male, GENDER.female],
            description: 'Krhki, lomljivi nohti, nohti se cepijo.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Lasje mi pogosto izpadajo',
            gender: [GENDER.male, GENDER.female],
            description: 'Soočam se s prekomernim izpadanjem las.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Imam težave s povišanim holesterolom/trigliceridi',
            gender: [GENDER.male, GENDER.female],
            description: 'Slabše razmerje med slabim in dobrim holesterolom.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Spopadam se z ohranjanjem idealne telesne teže',
            gender: [GENDER.male, GENDER.female],
            description: 'Pridobivanje/izgubljanje kilogramov.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Občutim pomanjkanje vzdržljivosti/elana',
            gender: [GENDER.male, GENDER.female],
            description: 'Slaba kondicija, upehanost, hitro se utrudimo.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Imam slabe prehranjevalne navade',
            gender: [GENDER.male, GENDER.female],
            description: 'Nezdrava prehrana ali pa ob nepravem času - prepozno, spuščanje obrokov.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Po bolezni okrevam bolj počasi',
            gender: [GENDER.male, GENDER.female],
            description: 'Telo potrebuje več časa, da se po bolezni zopet počutimo močni in smo brez težav kos vsakdanjim obveznostim.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Imam neredno, počasno prebavo',
            gender: [GENDER.male, GENDER.female],
            description: 'Zaprtje, težave z odvajanjem.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Sem razdražljiv/a, težko se sprostim:',
            gender: [GENDER.male, GENDER.female],
            description: 'Vzkipljivost, nestrpnost, napetost, vse nam gre na živce, pretirano se razburimo.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Uživam prehrano z nizko vsebnostjo hranil',
            gender: [GENDER.male, GENDER.female],
            description: 'Premalo sadja, zelenjave, polnovrednih žit (manj kot 30g dnevno).',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Občutim nelagodje v mišicah',
            gender: [GENDER.male, GENDER.female],
            description: 'Mišični krči, "musklfiber", zategovanje, napetost v mišicah, mišični vozliči.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Imam suhe/poškodovane/oslabele lase',
            gender: [GENDER.male, GENDER.female],
            description: 'Lasje niso zdravi, sijoči in močni.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Izpostavljen /-a sem onesnaženemu zraku',
            gender: [GENDER.male, GENDER.female],
            description: 'V Sloveniji smo onesnaženemu zraku izpostavljeni praktično povsod.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Ko se umirim, postanem zaspan',
            gender: [GENDER.male, GENDER.female],
            description: 'Ko se umirimo občutimo utrujenost, zaspanost, svinčen občutek v telesu.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Občutim pomanjkanje apetita',
            gender: [GENDER.male, GENDER.female],
            description: 'Neješčnost.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Dnevno popijem vsaj 2 prava čaja, kavi ali gazirani pijači',
            gender: [GENDER.male, GENDER.female],
            description: 'Kombinacija vsaj dveh pijač na dan.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Občasno se počutim, da nimam kontrole nad seboj',
            gender: [GENDER.male, GENDER.female],
            description: 'Občutek, da nam stvari uhajajo iz rok, da ne zmoremo vsega, podležemo skušnjavi po nezdravi hrani, alkoholu, cigaretah,…',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Sem občutljiv/a na določene sestavine v hrani, na kemikalije',
            gender: [GENDER.male, GENDER.female],
            description: 'Preobčutljivost na različna čistila, pralne praške, mehčalce, mila in kozmetiko.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Imam težave z glivičnimi okužbami / plesnijo',
            gender: [GENDER.male, GENDER.female],
            description: 'Madeži in izpuščaji na koži, neprijeten, srbeč občutek.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Imam probleme z mišicami in sklepi',
            gender: [GENDER.male, GENDER.female],
            description: 'Slaba gibljivost, okorelost, občutek zategnjenosti, kalcinacije, obraba sklepov, zadebeljenost sklepov, bolečina v mišicah in/ali sklepih.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Včasih me pretirano skrbi',
            gender: [GENDER.male, GENDER.female],
            description: 'Ves čas nas nekaj skrbi, preveč se sekiramo.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Pogosto sem razdražljiv /-a in jezen /-na',
            gender: [GENDER.male, GENDER.female],
            description: 'Pogosto nas stvari spravijo iz tira, hitro se razburimo.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Sem premalo telesno aktiven /-na',
            gender: [GENDER.male, GENDER.female],
            description: 'Manj kot 3x tedensko vsaj 20 minut.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Imam težave z nosno sluznico (izločanje sluzi, sinusi)',
            gender: [GENDER.male, GENDER.female],
            description: 'Prekomerno izločanje sluzi, suha nosna sluznica, razjede na sluznici, težave s sinusi.',
            answers: [ANSWERS.yes, ANSWERS.no]
        }
    ],
    EN: [
        {
            name: 'WOULD YOU LIKE MORE ENERGY?',
            gender: [GENDER.male, GENDER.female],
            description: 'Generally, would you like more energy?',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'FREQUENT ILL HEALTH (ONCE/TWICE YEARLY)',
            gender: [GENDER.male, GENDER.female],
            description: 'One or two bad colds, flu, or illness per year',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'BODY ODOUR AND/OR BAD BREATH',
            gender: [GENDER.male, GENDER.female],
            description: 'Bad breath, body odour, smelly feet',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'DIFFICULTY DIGESTING CERTAIN FOODS',
            gender: [GENDER.male, GENDER.female],
            description: 'Even if you try to avoid certain foods as a result',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'EAT RED MEAT AT LEAST TWICE WEEKLY',
            gender: [GENDER.male, GENDER.female],
            description: 'This includes: beef, ham, bacon, pork and lamb',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'PROBLEMS WITH MONTHLY CYCLE (FEMALE)',
            gender: [GENDER.male, GENDER.female],
            description: 'Do you get PMT/PMS symptoms?.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'USE OF ANTIBIOTIC/MEDICATION (LAST 3 YEARS)',
            gender: [GENDER.male, GENDER.female],
            description: 'This includes birth control, HRT, antibiotics and prescribed drugs',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'REGULAR ALCOHOL CONSUMPTION',
            gender: [GENDER.male, GENDER.female],
            description: 'Every other day or bingeing at weekends',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'MOOD SWINGS',
            gender: [GENDER.male, GENDER.female],
            description: 'Up and down moods, even if its PMT related',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'FOOD ALLERGIES',
            gender: [GENDER.male, GENDER.female],
            description: 'Even if you try to avoid certain foods as a result ',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'DARK CIRCLES UNDER EYES',
            gender: [GENDER.male, GENDER.female],
            description: 'You will be aware that you have these',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'SMOKING (INCLUDING PASSIVE)',
            gender: [GENDER.male, GENDER.female],
            description: 'Are you around people who do smoke?',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'POOR CONCENTRATION OR MEMORY',
            gender: [GENDER.male, GENDER.female],
            description: 'Do you feel it is not as good as it should be?',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'POOR RESISTANCE TO UNHEALTHY CONDITIONS',
            gender: [GENDER.male, GENDER.female],
            description: 'If a cold or flu is going around, do you catch it?',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'DISCOMFORT AFTER EATING',
            gender: [GENDER.male, GENDER.female],
            description: 'This includes bloating',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'STRESSFUL LIFESTYLE',
            gender: [GENDER.male, GENDER.female],
            description: 'Do you generally feel stressed or always on the go?',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'SKIN PROBLEMS',
            gender: [GENDER.male, GENDER.female],
            description: '',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'CRAVE SWEETS/PROCESSED FOODS',
            gender: [GENDER.male, GENDER.female],
            description: 'Includes chocolate, sweets, biscuits and crisps',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'CONSUME DAIRY PRODUCTS',
            gender: [GENDER.male, GENDER.female],
            description: 'Milk, yoghurt, cheese, even milk in tea or coee',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'FEELING LOW, OR APATHY',
            gender: [GENDER.male, GENDER.female],
            description: 'Do you feel down, like you often can\'t be bothered?',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'INADEQUATE/RESTLESS SLEEP ',
            gender: [GENDER.male, GENDER.female],
            description: 'Very restless or not enough sleep because of your lifestyle',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'MENOPAUSAL CONCERNS (FEMALE)',
            gender: [GENDER.male, GENDER.female],
            description: 'Even if you are on HRT',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'URINATION PROBLEMS',
            gender: [GENDER.male, GENDER.female],
            description: 'Do you get up through the night to use the toilet?',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'BRITTLE FINGERNAILS',
            gender: [GENDER.male, GENDER.female],
            description: 'Do your nails shatter or break often?',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'HAIR LOSS',
            gender: [GENDER.male, GENDER.female],
            description: 'Noticeable hair loss',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'BAD FATS/CHOLESTEROL ISSUES',
            gender: [GENDER.male, GENDER.female],
            description: 'Do you regularly eat fried food/ready meals. Or do you have a poor Cholesterol ratio?',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'DIFFICULTY IN MAINTAINING IDEAL WEIGHT',
            gender: [GENDER.male, GENDER.female],
            description: 'Difficulty losing or gaining weight in order to be your ideal weight.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'LACK OF STAMINA',
            gender: [GENDER.male, GENDER.female],
            description: '',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'POOR EATING HABITS',
            gender: [GENDER.male, GENDER.female],
            description: 'Do you eat at the wrong time, or the wrong type of food?',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'SLOW RECOVERY FROM POOR HEALTH',
            gender: [GENDER.male, GENDER.female],
            description: 'Does it take a while to get rid of an illness?',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'IRREGULAR/INFREQUENT BOWEL ACTIVITY',
            gender: [GENDER.male, GENDER.female],
            description: 'Don\'t go to the loo every day or suffer from diarrhea/constipation',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'EDGY/UNABLE TO RELAX/TENSION',
            gender: [GENDER.male, GENDER.female],
            description: 'Are you a nervous character or suffer with tension?',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'LOW FIBER DIET (LESS THAN 30 GRAMS/DAY)',
            gender: [GENDER.male, GENDER.female],
            description: 'Do you generally consume less than 30 grams of fiber per day?',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'MUSCLE DISCOMFORT',
            gender: [GENDER.male, GENDER.female],
            description: 'Do you experience muscle tension or discomfort',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'DRY/DAMAGED/DULL HAIR',
            gender: [GENDER.male, GENDER.female],
            description: 'Do you have dry or damaged hair?',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'EXPOSURE TO AIR POLLUTION',
            gender: [GENDER.male, GENDER.female],
            description: 'Everyone should tick this even if you live in the country',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'SLEEPINESS WHEN SITTING ',
            gender: [GENDER.male, GENDER.female],
            description: 'When you sit down do you start to feel tired and sleepy? ',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'LACK OF APPETITE',
            gender: [GENDER.male, GENDER.female],
            description: 'Are you often riot hungry at mealtimes?',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'DRINK 2 OR MORE CUPS OF TEA, COFFEE COLA A DAY',
            gender: [GENDER.male, GENDER.female],
            description: 'Do you consume two or more of any combination',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'FEELING OUT OF CONTROL',
            gender: [GENDER.male, GENDER.female],
            description: 'Do you get the feeling you just can\'t cope?',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'FOOD/CHEMICAL SENSITIVITIES',
            gender: [GENDER.male, GENDER.female],
            description: 'This includes soap, wahing up liquid, other chemical and food sources',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'PROBLEMS WITH YEAST/FUNGUS',
            gender: [GENDER.male, GENDER.female],
            description: 'Experience discomfort or irritation',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'MUSCLE/JOINT DISCOMFORT OR WEAKNESS',
            gender: [GENDER.male, GENDER.female],
            description: 'Discomfort or weakness in muscles and joints',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'EXCESSIVE WORRY',
            gender: [GENDER.male, GENDER.female],
            description: 'Do you worry a lot? ',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'EASILY IRRITATED/ANGERED',
            gender: [GENDER.male, GENDER.female],
            description: 'Are you easily irritable or angered?',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'INSUFFICIENT EXERCISE ',
            gender: [GENDER.male, GENDER.female],
            description: 'Do you do aerobic exercise at least three times per week for 20 minutes? ',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'PROBLEMS WITH CONGESTION/MUCUS',
            gender: [GENDER.male, GENDER.female],
            description: 'Do you often experience congestion?',
            answers: [ANSWERS.yes, ANSWERS.no]
        }
    ],
    HR: [
        {
            name: 'Želim imati više energije',
            gender: [GENDER.male, GENDER.female],
            description: 'Želite više energije općenito. ',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Često se razbolim (jednom/dvaput godišnje)',
            gender: [GENDER.male, GENDER.female],
            description: 'Godišnje jedna ili dvije prehlade, razne viroze, gripe ili slične bolesti.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Imam neugodan tjelesni miris i/ili loš zadah',
            gender: [GENDER.male, GENDER.female],
            description: ' Neugodan miris, dah, neugodan miris nogu',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Imam problema s probavljanjem određenih vrsta hrane',
            gender: [GENDER.male, GENDER.female],
            description: 'Nadutost, napetost, vjetrovi, kruljenje crijeva, žgaravica, refluks, želučana kiselina, izbjegavanje određene vrste hrane zbog probavnih problema. ',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Konzumiram crveno meso više od dva puta tjedno',
            gender: [GENDER.male, GENDER.female],
            description: 'Također salama i drugo meso i janjetina.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Imam problema sa mjesečnicom (žene)',
            gender: [GENDER.male, GENDER.female],
            description: 'PMS, bolne ovulacije, bolne menstruacije. ',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Koristim antibiotike / lijekove (posljednje 3 godine)',
            gender: [GENDER.male, GENDER.female],
            description: 'Bilo koji lijek, hormonske terapije, kontracepcijske pilule.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Redovito konzumiram alkohol',
            gender: [GENDER.male, GENDER.female],
            description: 'Nekoliko puta tjedno, samo vikendom.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Primjećujem promjene raspoloženja',
            gender: [GENDER.male, GENDER.female],
            description: 'Također povezano s PMS-om.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Imam alergije na hranu',
            gender: [GENDER.male, GENDER.female],
            description: 'Čak i izbjegavanje određene vrste hrane zbog problema.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Imam tamne podočnjake',
            gender: [GENDER.male, GENDER.female],
            description: 'Tamni krugovi ispod očiju.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Redovito /povremeno pušim (i pasivno)\n',
            gender: [GENDER.male, GENDER.female],
            description: 'Često okružen ljudima koji puše.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Primjećujem slabije pamćenje i koncentraciju',
            gender: [GENDER.male, GENDER.female],
            description: 'Čini se kao da nije onako kako bi trebalo biti.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Slabiji imunitet',
            gender: [GENDER.male, GENDER.female],
            description: 'Zimi se često razbolimo.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Nakon obroka često se loše osjećam',
            gender: [GENDER.male, GENDER.female],
            description: 'Nelagoda u želucu, pospanost, umor nakon jela.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Često sam pod stresom',
            gender: [GENDER.male, GENDER.female],
            description: 'Osjećaj, da smo pod pritiskom',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Problemi sa kožom',
            gender: [GENDER.male, GENDER.female],
            description: 'Akne, prištići, inferiorna koža, psorijaza, atopijski dermatitis.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Žuditim za slatkim i nezdravom hranom',
            gender: [GENDER.male, GENDER.female],
            description: 'Čokolada, peciva,kolači, deserti, slani zalogaji, brza hrana.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Koristim mliječne proizvode',
            gender: [GENDER.male, GENDER.female],
            description: 'Mlijeko, jogurt, sir, mlijeko u kavi ili čaju.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Često se osjećam bezvoljno i apatično',
            gender: [GENDER.male, GENDER.female],
            description: 'Osjećaj depresije, bez volje za životom.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Nekvalitetan i nemiran san',
            gender: [GENDER.male, GENDER.female],
            description: 'Nesanica, nekvalitetan san, prerano se budimo, umorni smo i neispavani tijekom dana. ',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Problemi sa menopauzom (žene)',
            gender: [GENDER.male, GENDER.female],
            description: 'Toplinski valovi, promjene raspoloženja.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Primjećujem probleme sa mokrenjem',
            gender: [GENDER.male, GENDER.female],
            description: 'Ustajanje noću (WC), ostali problemi s urogenitatalnim traktom.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Imam krhke nokte',
            gender: [GENDER.male, GENDER.female],
            description: 'Krhki, lomljivi nokti.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Primjećujem ispadanje kose',
            gender: [GENDER.male, GENDER.female],
            description: 'Očitno ispadanje kose.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Problemi sa povišenim kolesterolom / trigliceridi',
            gender: [GENDER.male, GENDER.female],
            description: 'Pržena, prerađena prehrana, lošiji omjer lošeg i dobrog kolesterola.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Problemi sa održavanjem idealne tjelesne težine',
            gender: [GENDER.male, GENDER.female],
            description: 'Gubitak, debljanje',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Pomanjkanje izdržljivosti / elana\n',
            gender: [GENDER.male, GENDER.female],
            description: 'Bez kondicije.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Loše prehrambene navike',
            gender: [GENDER.male, GENDER.female],
            description: 'Nezdrava prehrana ili u pogrešno vrijeme - prekasno, ispuštanje obroka.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Spor oporavak nakon bolesti',
            gender: [GENDER.male, GENDER.female],
            description: 'Tijelu je potrebno više vremena za oporavak od bolesti.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Neuredna / spora probava',
            gender: [GENDER.male, GENDER.female],
            description: 'Zatvor, proljev.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Razdraživost / nemogućnost opuštanja',
            gender: [GENDER.male, GENDER.female],
            description: 'Uzbuđenost, napetost',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Prehrana sa malim sadržajem vlakana',
            gender: [GENDER.male, GENDER.female],
            description: 'Premalo voća, povrća, žitarica - manje od 30g dnevno.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Primjećujem nelagodu u mišićima',
            gender: [GENDER.male, GENDER.female],
            description: 'Grčevi u mišićima, zatezanje, napetost, klimanje glavom.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Suha / oštećena / oslabljena kosa',
            gender: [GENDER.male, GENDER.female],
            description: 'Stalni problemi sa kosom.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Izloženost onečišćenom zraku',
            gender: [GENDER.male, GENDER.female],
            description: 'Svi smo izloženi, čak i na selu.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Pospanost, kad se umirim',
            gender: [GENDER.male, GENDER.female],
            description: 'Umorimo se kad se smirimo.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Osjećam manjak apetita',
            gender: [GENDER.male, GENDER.female],
            description: 'Često ste siti.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Popijem više od 2 čaja, kave ili gaziranog pića',
            gender: [GENDER.male, GENDER.female],
            description: 'Kombinacija najmanje dva pića.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Osjećam se, kao da nemam kontrolu nad sobom',
            gender: [GENDER.male, GENDER.female],
            description: 'Ne možete sve učiniti.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Osjetljivost na određene sastojke hrane / kemikalije',
            gender: [GENDER.male, GENDER.female],
            description: 'Hrana, sapun, prašci za pranje, sredstva za čišćenje.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Imam problema sa gljivicama / plijesni',
            gender: [GENDER.male, GENDER.female],
            description: 'Neugodan svrbež na koži',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Imam problema sa mišićima i kostima',
            gender: [GENDER.male, GENDER.female],
            description: 'Problemi s mišićima, zglobovi, kalcinacija, istrošenost sklepov.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Pretjerano sam zabrinut-a',
            gender: [GENDER.male, GENDER.female],
            description: 'Stalno smo zabrinuti.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Često sam razdražljiv i ljut-a',
            gender: [GENDER.male, GENDER.female],
            description: 'Brzo se naljutimo.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Premalo tjelesne aktivnosti',
            gender: [GENDER.male, GENDER.female],
            description: 'Manje od 3x tjedno za najmanje 20 minuta.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Poteškoće sa sluznicom nosa / izbacivanje sluzi, sinusi',
            gender: [GENDER.male, GENDER.female],
            description: 'Prekomjerno izlučivanje sluzi, problemi sa sinusima.',
            answers: [ANSWERS.yes, ANSWERS.no]
        }
    ],
    US: [
        {
            name: 'WOULD YOU LIKE MORE ENERGY?',
            gender: [GENDER.male, GENDER.female],
            description: 'Generally, would you like more energy?',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'FREQUENT ILL HEALTH (ONCE/TWICE YEARLY)',
            gender: [GENDER.male, GENDER.female],
            description: 'One or two bad colds, flu, or illness per year',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'BODY ODOUR AND/OR BAD BREATH',
            gender: [GENDER.male, GENDER.female],
            description: 'Bad breath, body odour, smelly feet',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'DIFFICULTY DIGESTING CERTAIN FOODS',
            gender: [GENDER.male, GENDER.female],
            description: 'Even if you try to avoid certain foods as a result',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'EAT RED MEAT AT LEAST TWICE WEEKLY',
            gender: [GENDER.male, GENDER.female],
            description: 'This includes: beef, ham, bacon, pork and lamb',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'PROBLEMS WITH MONTHLY CYCLE (FEMALE)',
            gender: [GENDER.male, GENDER.female],
            description: 'Do you get PMT/PMS symptoms?.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'USE OF ANTIBIOTIC/MEDICATION (LAST 3 YEARS)',
            gender: [GENDER.male, GENDER.female],
            description: 'This includes birth control, HRT, antibiotics and prescribed drugs',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'REGULAR ALCOHOL CONSUMPTION',
            gender: [GENDER.male, GENDER.female],
            description: 'Every other day or bingeing at weekends',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'MOOD SWINGS',
            gender: [GENDER.male, GENDER.female],
            description: 'Up and down moods, even if its PMT related',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'FOOD ALLERGIES',
            gender: [GENDER.male, GENDER.female],
            description: 'Even if you try to avoid certain foods as a result ',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'DARK CIRCLES UNDER EYES',
            gender: [GENDER.male, GENDER.female],
            description: 'You will be aware that you have these',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'SMOKING (INCLUDING PASSIVE)',
            gender: [GENDER.male, GENDER.female],
            description: 'Are you around people who do smoke?',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'POOR CONCENTRATION OR MEMORY',
            gender: [GENDER.male, GENDER.female],
            description: 'Do you feel it is not as good as it should be?',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'POOR RESISTANCE TO UNHEALTHY CONDITIONS',
            gender: [GENDER.male, GENDER.female],
            description: 'If a cold or flu is going around, do you catch it?',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'DISCOMFORT AFTER EATING',
            gender: [GENDER.male, GENDER.female],
            description: 'This includes bloating',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'STRESSFUL LIFESTYLE',
            gender: [GENDER.male, GENDER.female],
            description: 'Do you generally feel stressed or always on the go?',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'SKIN PROBLEMS',
            gender: [GENDER.male, GENDER.female],
            description: '',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'CRAVE SWEETS/PROCESSED FOODS',
            gender: [GENDER.male, GENDER.female],
            description: 'Includes chocolate, sweets, biscuits and crisps',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'CONSUME DAIRY PRODUCTS',
            gender: [GENDER.male, GENDER.female],
            description: 'Milk, yoghurt, cheese, even milk in tea or coee',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'FEELING LOW, OR APATHY',
            gender: [GENDER.male, GENDER.female],
            description: 'Do you feel down, like you often can\'t be bothered?',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'INADEQUATE/RESTLESS SLEEP ',
            gender: [GENDER.male, GENDER.female],
            description: 'Very restless or not enough sleep because of your lifestyle',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'MENOPAUSAL CONCERNS (FEMALE)',
            gender: [GENDER.male, GENDER.female],
            description: 'Even if you are on HRT',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'URINATION PROBLEMS',
            gender: [GENDER.male, GENDER.female],
            description: 'Do you get up through the night to use the toilet?',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'BRITTLE FINGERNAILS',
            gender: [GENDER.male, GENDER.female],
            description: 'Do your nails shatter or break often?',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'HAIR LOSS',
            gender: [GENDER.male, GENDER.female],
            description: 'Noticeable hair loss',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'BAD FATS/CHOLESTEROL ISSUES',
            gender: [GENDER.male, GENDER.female],
            description: 'Do you regularly eat fried food/ready meals. Or do you have a poor Cholesterol ratio?',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'DIFFICULTY IN MAINTAINING IDEAL WEIGHT',
            gender: [GENDER.male, GENDER.female],
            description: 'Difficulty losing or gaining weight in order to be your ideal weight.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'LACK OF STAMINA',
            gender: [GENDER.male, GENDER.female],
            description: '',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'POOR EATING HABITS',
            gender: [GENDER.male, GENDER.female],
            description: 'Do you eat at the wrong time, or the wrong type of food?',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'SLOW RECOVERY FROM POOR HEALTH',
            gender: [GENDER.male, GENDER.female],
            description: 'Does it take a while to get rid of an illness?',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'IRREGULAR/INFREQUENT BOWEL ACTIVITY',
            gender: [GENDER.male, GENDER.female],
            description: 'Don\'t go to the loo every day or suffer from diarrhea/constipation',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'EDGY/UNABLE TO RELAX/TENSION',
            gender: [GENDER.male, GENDER.female],
            description: 'Are you a nervous character or suffer with tension?',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'LOW FIBER DIET (LESS THAN 30 GRAMS/DAY)',
            gender: [GENDER.male, GENDER.female],
            description: 'Do you generally consume less than 30 grams of fiber per day?',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'MUSCLE DISCOMFORT',
            gender: [GENDER.male, GENDER.female],
            description: 'Do you experience muscle tension or discomfort',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'DRY/DAMAGED/DULL HAIR',
            gender: [GENDER.male, GENDER.female],
            description: 'Do you have dry or damaged hair?',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'EXPOSURE TO AIR POLLUTION',
            gender: [GENDER.male, GENDER.female],
            description: 'Everyone should tick this even if you live in the country',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'SLEEPINESS WHEN SITTING ',
            gender: [GENDER.male, GENDER.female],
            description: 'When you sit down do you start to feel tired and sleepy? ',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'LACK OF APPETITE',
            gender: [GENDER.male, GENDER.female],
            description: 'Are you often riot hungry at mealtimes?',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'DRINK 2 OR MORE CUPS OF TEA, COFFEE COLA A DAY',
            gender: [GENDER.male, GENDER.female],
            description: 'Do you consume two or more of any combination',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'FEELING OUT OF CONTROL',
            gender: [GENDER.male, GENDER.female],
            description: 'Do you get the feeling you just can\'t cope?',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'FOOD/CHEMICAL SENSITIVITIES',
            gender: [GENDER.male, GENDER.female],
            description: 'This includes soap, wahing up liquid, other chemical and food sources',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'PROBLEMS WITH YEAST/FUNGUS',
            gender: [GENDER.male, GENDER.female],
            description: 'Experience discomfort or irritation',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'MUSCLE/JOINT DISCOMFORT OR WEAKNESS',
            gender: [GENDER.male, GENDER.female],
            description: 'Discomfort or weakness in muscles and joints',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'EXCESSIVE WORRY',
            gender: [GENDER.male, GENDER.female],
            description: 'Do you worry a lot? ',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'EASILY IRRITATED/ANGERED',
            gender: [GENDER.male, GENDER.female],
            description: 'Are you easily irritable or angered?',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'INSUFFICIENT EXERCISE ',
            gender: [GENDER.male, GENDER.female],
            description: 'Do you do aerobic exercise at least three times per week for 20 minutes? ',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'PROBLEMS WITH CONGESTION/MUCUS',
            gender: [GENDER.male, GENDER.female],
            description: 'Do you often experience congestion?',
            answers: [ANSWERS.yes, ANSWERS.no]
        }
    ],
    IT: [
        {
            name: 'Voglio avere più energia',
            gender: [GENDER.male, GENDER.female],
            description: 'Vuoi avere più energia in generale?',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Mi ammalo spesso (una/due volte l\'anno)',
            gender: [GENDER.male, GENDER.female],
            description: 'Uno o due raffreddori, virus vari, influenza o malattie simili all\'anno.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Ho un odore corporeo sgradevole e/o un alito cattivo',
            gender: [GENDER.male, GENDER.female],
            description: 'Odore forte e sgradevole di sudore, alito cattivo, odore di piedi.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Ho difficoltà a digerire alcuni alimenti',
            gender: [GENDER.male, GENDER.female],
            description: 'Gonfiore, flatulenza, movimenti intestinali, bruciore di stomaco, reflusso, acidità di stomaco, evitare di abbattersi. Tipi di alimenti per problemi digestivi.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Mangiare carne rossa almeno due volte alla settimana.',
            gender: [GENDER.male, GENDER.female],
            description: 'Tutte le carni e gli insaccati',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Ho problemi con il ciclo mestruale (donne)',
            gender: [GENDER.male, GENDER.female],
            description: 'Sindrome premestruale, ovulazione dolorosa, mestruazioni dolorose e irregolari.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Uso antibiotici / farmaci (ultimi 3 anni)',
            gender: [GENDER.male, GENDER.female],
            description: 'Tutti i farmaci, la terapia ormonale, la pillola anticoncezionale.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Bevo regolarmente alcolici',
            gender: [GENDER.male, GENDER.female],
            description: 'Più volte alla settimana o solo nei fine settimana.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Tendo ad avere sbalzi d\'umore',
            gender: [GENDER.male, GENDER.female],
            description: 'Anche in relazione alla sindrome premestruale.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Ho allergie alimentari',
            gender: [GENDER.male, GENDER.female],
            description: 'Evito certi alimenti',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Ho le occhiaie',
            gender: [GENDER.male, GENDER.female],
            description: 'Sarete consapevoli di avere queste',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Fumo regolarmente / occasionalmente (anche passivamente)',
            gender: [GENDER.male, GENDER.female],
            description: 'Spesso in compagnia di fumatori',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Noto una diminuzione della memoria e della concentrazione',
            gender: [GENDER.male, GENDER.female],
            description: 'Sento che le cose non sono come dovrebbero essere.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Scarsa resistenza a condizioni malsane',
            gender: [GENDER.male, GENDER.female],
            description: 'Mi ammalo spesso in inverno, sono soggetto a infezioni come l\'herpes.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Mi sento spesso male dopo aver mangiato',
            gender: [GENDER.male, GENDER.female],
            description: 'Gonfiore, sonnolenza, stanchezza dopo aver mangiato.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Sono spesso stressato',
            gender: [GENDER.male, GENDER.female],
            description: 'Molti impegni, troppo poco riposo e relax, mi sento sotto pressione.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Ho problemi di pelle',
            gender: [GENDER.male, GENDER.female],
            description: 'Acne, brufoli, pelle secca, psoriasi, dermatite atopica.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Ho spesso voglia di dolci e fast food',
            gender: [GENDER.male, GENDER.female],
            description: 'Cioccolato, torte, snack salati, hamburger.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Consumo prodotti caseari',
            gender: [GENDER.male, GENDER.female],
            description: 'Latte, yogurt, formaggio, anche latte nel caffè o nel tè',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Mi sento spesso svogliato e stanco',
            gender: [GENDER.male, GENDER.female],
            description: 'Una sensazione di depressione, senza voglia di vivere.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Dormo male e senza riposo',
            gender: [GENDER.male, GENDER.female],
            description: 'Difficoltà ad addormentarsi, sonno agitato, stanchezza al risveglio, stanchezza durante il giorno.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Ho i sintomi della menopausa (donne)',
            gender: [GENDER.male, GENDER.female],
            description: "Vampate di calore, sbalzi d'umore.",
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Ho problemi di minzione',
            gender: [GENDER.male, GENDER.female],
            description: 'Alzarsi di notte (andare in bagno), problemi di incontinenza, altri problemi del tratto urogenitale.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Ho unghie fragili',
            gender: [GENDER.male, GENDER.female],
            description: 'Unghie fragili, unghie che si spezzano.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'I miei capelli cadono spesso',
            gender: [GENDER.male, GENDER.female],
            description: 'Perdita di capelli.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Ho problemi di livelli elevati di colesterolo/trigliceridi',
            gender: [GENDER.male, GENDER.female],
            description: 'Il rapporto tra colesterolo cattivo e colesterolo buono è più basso.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Fatico a mantenere il mio peso ideale',
            gender: [GENDER.male, GENDER.female],
            description: 'Aumento e perdo chili.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Sento una mancanza di resistenza/forza',
            gender: [GENDER.male, GENDER.female],
            description: 'Scarsa forma fisica, letargia, spossatezza.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Ho cattive abitudini alimentari',
            gender: [GENDER.male, GENDER.female],
            description: 'Mangiate in modo non sano o al momento sbagliato - troppo tardi, saltando i pasti.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Recupero più lentamente dopo una malattia',
            gender: [GENDER.male, GENDER.female],
            description: 'Il corpo ha bisogno di più tempo per sentirsi di nuovo forte dopo una malattia e per affrontare senza problemi le responsabilità quotidiane',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Ho una digestione irregolare / lenta',
            gender: [GENDER.male, GENDER.female],
            description: 'Stitichezza, problemi intestinali.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Sono irritabile, ho difficoltà a rilassarmi',
            gender: [GENDER.male, GENDER.female],
            description: 'Irritabilità, impazienza, tensione, tutto ci dà sui nervi, ci agitiamo troppo.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Mangio una dieta povera di nutrienti',
            gender: [GENDER.male, GENDER.female],
            description: 'Troppo poca frutta, verdura, prodotti integrali (meno di 30 g al giorno).',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Ho disturbi muscolari',
            gender: [GENDER.male, GENDER.female],
            description: 'Crampi muscolari, fibre muscolari, tensione, nodi muscolari.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Ho i capelli secchi / danneggiati / indeboliti',
            gender: [GENDER.male, GENDER.female],
            description: 'I capelli non sono sani, lucidi e forti.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Sono esposto all\'aria inquinata',
            gender: [GENDER.male, GENDER.female],
            description: 'Siamo esposti all\'aria inquinata praticamente ovunque.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Quando mi calmo, ho sonno',
            gender: [GENDER.male, GENDER.female],
            description: 'Non appena mi calmo, mi sento stanco, sonnolento, con il corpo di piombo.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Perdo l\'appetito',
            gender: [GENDER.male, GENDER.female],
            description: 'Hai spesso fame durante i pasti?',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Bevo almeno 2 tè neri, caffè o bibite al giorno',
            gender: [GENDER.male, GENDER.female],
            description: 'Una combinazione di almeno due bevande al giorno.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'A volte ho la sensazione di non avere alcun controllo su me stesso',
            gender: [GENDER.male, GENDER.female],
            description: 'Sento che le cose mi stanno sfuggendo di mano, che tutto sta diventando troppo per me. Cedo alla tentazione del cibo spazzatura, dell\'alcol, delle sigarette, ecc.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Sono sensibile ad alcuni ingredienti alimentari, alle sostanze chimiche',
            gender: [GENDER.male, GENDER.female],
            description: ' Ipersensibilità a vari detergenti, detersivi, ammorbidenti, saponi e cosmetici.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Ho problemi di infezioni fungine/muffa',
            gender: [GENDER.male, GENDER.female],
            description: 'Macchie ed eruzioni cutanee, sensazione di disagio e prurito ...',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Ho problemi con i muscoli e le articolazioni',
            gender: [GENDER.male, GENDER.female],
            description: 'Scarsa mobilità, rigidità, tensione, calcificazione, usura delle articolazioni, ispessimento delle articolazioni, dolore muscolare e/o articolare.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'A volte mi preoccupo troppo',
            gender: [GENDER.male, GENDER.female],
            description: 'Ci preoccupiamo sempre di qualcosa, ci preoccupiamo troppo.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Sono spesso irritabile e arrabbiato',
            gender: [GENDER.male, GENDER.female],
            description: 'Le cose mi mandano spesso fuori strada, mi arrabbio facilmente.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Non sono abbastanza attivo fisicamente',
            gender: [GENDER.male, GENDER.female],
            description: 'Meno di 3 volte alla settimana per almeno 20 minuti.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Ho problemi alla mucosa nasale',
            gender: [GENDER.male, GENDER.female],
            description: 'Eccessiva secrezione mucosa, secchezza della mucosa nasale, ulcere della mucosa, problemi ai seni paranasali.',
            answers: [ANSWERS.yes, ANSWERS.no]
        }
    ],
    DE: [
        {
            name: 'Ich möchte mehr Energie haben',
            gender: [GENDER.male, GENDER.female],
            description: 'Willst du allgemein mehr Energie?',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Ich werde oft krank (ein-/zweimal im Jahr)',
            gender: [GENDER.male, GENDER.female],
            description: 'Ein bis zwei Erkältungen, diverse Viren, Grippe oder ähnliche Erkrankungen pro Jahr.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Ich habe unangenehmen Körpergeruch und/oder Mundgeruch',
            gender: [GENDER.male, GENDER.female],
            description: 'Starker, unangenehmer Schweißgeruch, Mundgeruch, Fußgeruch.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Ich habe Schwierigkeiten, bestimmte Nahrungsmittel zu verdauen',
            gender: [GENDER.male, GENDER.female],
            description: 'Völlegefühl, Blähungen, Stuhlgang, Sodbrennen, Reflux, Magensäure, Vermeidung von Daunen. Arten von Lebensmitteln aufgrund von Verdauungsproblemen.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Ich esse mindestens zweimal pro Woche rotes Fleisch',
            gender: [GENDER.male, GENDER.female],
            description: 'Alle Fleisch- und Wurstwaren',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Ich habe Probleme mit meinem Monatszyklus (Frauen)',
            gender: [GENDER.male, GENDER.female],
            description: 'PMS, schmerzhafter Eisprung, schmerzhafte und unregelmäßige Perioden.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Ich benutze Antibiotika / Medikamente (letzte 3 Jahre)',
            gender: [GENDER.male, GENDER.female],
            description: 'Alle Medikamente, Hormontherapie, Antibabypillen.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Ich trinke regelmäßig Alkohol',
            gender: [GENDER.male, GENDER.female],
            description: 'Mehrmals die Woche oder nur am Wochenende.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Ich neige zu Stimmungsschwankungen',
            gender: [GENDER.male, GENDER.female],
            description: 'Auch im Zusammenhang mit PMS.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Ich habe Nahrungsmittelallergien',
            gender: [GENDER.male, GENDER.female],
            description: 'Ich meide bestimmte Nahrungsmittel',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Ich habe Augenringe',
            gender: [GENDER.male, GENDER.female],
            description: 'Dunkle Ringe unter den Augen.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Ich rauche regelmäßig / gelegentlich (auch passiv)',
            gender: [GENDER.male, GENDER.female],
            description: 'Oft in Gesellschaft von Rauchern.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Ich bemerke ein schlechteres Gedächtnis und Konzentration',
            gender: [GENDER.male, GENDER.female],
            description: 'Ich habe das Gefühl, dass es nicht so ist, wie es sein sollte.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Ich bin nicht widerstandsfähig',
            gender: [GENDER.male, GENDER.female],
            description: 'Im Winter werde ich oft krank, anfällig für Infektionen wie z.B. Herpes.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Mir ist nach dem Essen oft unwohl',
            gender: [GENDER.male, GENDER.female],
            description: 'Blähungen, Schläfrigkeit, Müdigkeit nach dem Essen.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Ich bin oft gestresst',
            gender: [GENDER.male, GENDER.female],
            description: 'Viele Verpflichtungen, zu wenig Ruhe und Entspannung, das Gefühl, unter Druck zu stehen.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Ich habe Hautprobleme',
            gender: [GENDER.male, GENDER.female],
            description: 'Akne, Pickel, trockene Haut, Psoriasis, atopische Dermatitis.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Ich habe oft Heißhunger auf Süßigkeiten und Fast Food',
            gender: [GENDER.male, GENDER.female],
            description: 'Schokolade, Kuchen, Desserts, salzige Snacks, Fast Food.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Ich konsumiere Milchprodukte',
            gender: [GENDER.male, GENDER.female],
            description: 'Milch, Joghurt, Käse, auch Milch in Kaffee oder Tee.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Ich fühle mich oft schlapp und müde',
            gender: [GENDER.male, GENDER.female],
            description: 'Ein Gefühl der Depression, ohne Lebenswillen.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Ich schlafe schlecht und unruhig',
            gender: [GENDER.male, GENDER.female],
            description: 'Schlechtes Einschlafen, unruhiger Schlaf, müde nach dem Aufwachen, tagsüber müde',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Ich habe Wechseljahresbeschwerden (Frauen)',
            gender: [GENDER.male, GENDER.female],
            description: 'Hitzewallungen, Stimmungsschwankungen.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Ich habe Probleme beim Wasserlassen',
            gender: [GENDER.male, GENDER.female],
            description: 'Nachts aufstehen (Toilette), Probleme mit Inkontinenz, andere Probleme mit dem Urogenitaltrakt.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Ich habe brüchige Nägel',
            gender: [GENDER.male, GENDER.female],
            description: 'Brüchige Nägel, Nägel splittern.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Meine Haare fallen oft aus',
            gender: [GENDER.male, GENDER.female],
            description: 'Ich habe Haarausfall.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Ich habe Probleme mit hohen Cholesterin-/Triglyceridwerten',
            gender: [GENDER.male, GENDER.female],
            description: 'Ein schlechteres Verhältnis zwischen schlechtem und gutem Cholesterin.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Ich kämpfe damit, mein Idealgewicht zu halten',
            gender: [GENDER.male, GENDER.female],
            description: 'Pfunde zunehmen, verlieren',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Ich spüre einen Mangel an Ausdauer / Kraft',
            gender: [GENDER.male, GENDER.female],
            description: 'Schlechte Kondition, Lethargie, Erschöpfung',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Ich habe schlechte Essgewohnheiten',
            gender: [GENDER.male, GENDER.female],
            description: 'Ungesunde Ernährung oder zur falschen Zeit – zu spät, Mahlzeiten auslassen.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Nach einer Krankheit erhole ich mich langsamer',
            gender: [GENDER.male, GENDER.female],
            description: 'Der Körper braucht mehr Zeit, um sich nach einer Krankheit wieder stark zu fühlen und die alltäglichen Verpflichtungen problemlos zu bewältigen',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Ich habe eine unregelmäßige / langsame Verdauung',
            gender: [GENDER.male, GENDER.female],
            description: 'Verstopfung, Darmprobleme.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Ich bin gereizt, es fällt mir schwer, mich zu entspannen',
            gender: [GENDER.male, GENDER.female],
            description: 'Gereiztheit, Ungeduld, Anspannung, alles geht uns auf die Nerven, wir werden übermäßig aufgeregt.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Ich ernähre mich nährstoffarm',
            gender: [GENDER.male, GENDER.female],
            description: 'Zu wenig Obst, Gemüse, Vollkornprodukte (weniger als 30 g pro Tag).',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Ich habe Muskelbeschwerden',
            gender: [GENDER.male, GENDER.female],
            description: 'Muskelkrämpfe, Muskelfaser, Straffung, Muskelverspannungen, Muskelknoten.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Ich habe trockenes / geschädigtes / geschwächtes Haar',
            gender: [GENDER.male, GENDER.female],
            description: 'Das Haar ist nicht gesund, glänzend und stark.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Ich bin verschmutzter Luft ausgesetzt',
            gender: [GENDER.male, GENDER.female],
            description: 'In Slowenien sind wir praktisch überall verschmutzter Luft ausgesetzt.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Wenn ich mich beruhige, werde ich schläfrig',
            gender: [GENDER.male, GENDER.female],
            description: 'Sobald ich ich beruhige, fühle ich mich müde, schläfrig, bleiern im Körper.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Ich spüre Appetitlosigkeit',
            gender: [GENDER.male, GENDER.female],
            description: 'Verdauungsstörungen.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Ich trinke mindestens 2 Schwarztees, Kaffees oder Limonaden am Tag',
            gender: [GENDER.male, GENDER.female],
            description: 'Eine Kombination aus mindestens zwei Getränken pro Tag.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Manchmal habe ich das Gefühl, ich habe keine Kontrolle über mich selbst.',
            gender: [GENDER.male, GENDER.female],
            description: 'Das Gefühl, dass die Dinge aus dem Ruder laufen, dass mir alles zu viel wird. Ich erliege der Versuchung von Junk Food, Alkohol, Zigaretten usw.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Ich reagiere empfindlich auf bestimmte Lebensmittelinhaltsstoffe, auf Chemikalien.',
            gender: [GENDER.male, GENDER.female],
            description: 'Überempfindlichkeit auf verschiedene Reiniger, Waschpulver, Weichspüler, Seifen und Kosmetika.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Ich habe Probleme mit Pilzinfektionen / Schimmel',
            gender: [GENDER.male, GENDER.female],
            description: 'Flecken und Ausschläge auf der Haut, unangenehmes, juckendes Gefühl..',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Ich habe Probleme mit meinen Muskeln und Gelenken',
            gender: [GENDER.male, GENDER.female],
            description: 'Schlechte Beweglichkeit, Steifheit, Verspannungen, Verkalkungen, Gelenkverschleiß, Gelenkverdickung, Muskel- und/oder Gelenkschmerzen.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Manchmal mache ich mir zu viele Sorgen',
            gender: [GENDER.male, GENDER.female],
            description: 'Wir machen uns die ganze Zeit über etwas Sorgen, wir machen uns zu viele Sorgen.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Ich bin oft gereizt und wütend',
            gender: [GENDER.male, GENDER.female],
            description: 'Dinge werfen mich oft aus der Bahn, ich regen mich schnell auf.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Ich bin körperlich nicht aktiv genug',
            gender: [GENDER.male, GENDER.female],
            description: 'Weniger als 3 Mal pro Woche für mindestens 20 Minuten.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Ich habe Probleme mit der Nasenschleimhaut (Schleimabsonderung, Nasennebenhöhlen)',
            gender: [GENDER.male, GENDER.female],
            description: 'Übermäßige Schleimabsonderung, trockene Nasenschleimhaut, Schleimhautgeschwüre, Nebenhöhlenprobleme.',
            answers: [ANSWERS.yes, ANSWERS.no]
        }
    ],
    AT: [
        {
            name: 'Ich möchte mehr Energie haben',
            gender: [GENDER.male, GENDER.female],
            description: 'Willst du allgemein mehr Energie?',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Ich werde oft krank (ein-/zweimal im Jahr)',
            gender: [GENDER.male, GENDER.female],
            description: 'Ein bis zwei Erkältungen, diverse Viren, Grippe oder ähnliche Erkrankungen pro Jahr.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Ich habe unangenehmen Körpergeruch und/oder Mundgeruch',
            gender: [GENDER.male, GENDER.female],
            description: 'Starker, unangenehmer Schweißgeruch, Mundgeruch, Fußgeruch.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Ich habe Schwierigkeiten, bestimmte Nahrungsmittel zu verdauen',
            gender: [GENDER.male, GENDER.female],
            description: 'Völlegefühl, Blähungen, Stuhlgang, Sodbrennen, Reflux, Magensäure, Vermeidung von Daunen. Arten von Lebensmitteln aufgrund von Verdauungsproblemen.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Ich esse mindestens zweimal pro Woche rotes Fleisch',
            gender: [GENDER.male, GENDER.female],
            description: 'Alle Fleisch- und Wurstwaren',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Ich habe Probleme mit meinem Monatszyklus (Frauen)',
            gender: [GENDER.male, GENDER.female],
            description: 'PMS, schmerzhafter Eisprung, schmerzhafte und unregelmäßige Perioden.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Ich benutze Antibiotika / Medikamente (letzte 3 Jahre)',
            gender: [GENDER.male, GENDER.female],
            description: 'Alle Medikamente, Hormontherapie, Antibabypillen.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Ich trinke regelmäßig Alkohol',
            gender: [GENDER.male, GENDER.female],
            description: 'Mehrmals die Woche oder nur am Wochenende.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Ich neige zu Stimmungsschwankungen',
            gender: [GENDER.male, GENDER.female],
            description: 'Auch im Zusammenhang mit PMS.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Ich habe Nahrungsmittelallergien',
            gender: [GENDER.male, GENDER.female],
            description: 'Ich meide bestimmte Nahrungsmittel',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Ich habe Augenringe',
            gender: [GENDER.male, GENDER.female],
            description: 'Dunkle Ringe unter den Augen.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Ich rauche regelmäßig / gelegentlich (auch passiv)',
            gender: [GENDER.male, GENDER.female],
            description: 'Oft in Gesellschaft von Rauchern.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Ich bemerke ein schlechteres Gedächtnis und Konzentration',
            gender: [GENDER.male, GENDER.female],
            description: 'Ich habe das Gefühl, dass es nicht so ist, wie es sein sollte.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Ich bin nicht widerstandsfähig',
            gender: [GENDER.male, GENDER.female],
            description: 'Im Winter werde ich oft krank, anfällig für Infektionen wie z.B. Herpes.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Mir ist nach dem Essen oft unwohl',
            gender: [GENDER.male, GENDER.female],
            description: 'Blähungen, Schläfrigkeit, Müdigkeit nach dem Essen.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Ich bin oft gestresst',
            gender: [GENDER.male, GENDER.female],
            description: 'Viele Verpflichtungen, zu wenig Ruhe und Entspannung, das Gefühl, unter Druck zu stehen.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Ich habe Hautprobleme',
            gender: [GENDER.male, GENDER.female],
            description: 'Akne, Pickel, trockene Haut, Psoriasis, atopische Dermatitis.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Ich habe oft Heißhunger auf Süßigkeiten und Fast Food',
            gender: [GENDER.male, GENDER.female],
            description: 'Schokolade, Kuchen, Desserts, salzige Snacks, Fast Food.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Ich konsumiere Milchprodukte',
            gender: [GENDER.male, GENDER.female],
            description: 'Milch, Joghurt, Käse, auch Milch in Kaffee oder Tee.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Ich fühle mich oft schlapp und müde',
            gender: [GENDER.male, GENDER.female],
            description: 'Ein Gefühl der Depression, ohne Lebenswillen.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Ich schlafe schlecht und unruhig',
            gender: [GENDER.male, GENDER.female],
            description: 'Schlechtes Einschlafen, unruhiger Schlaf, müde nach dem Aufwachen, tagsüber müde',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Ich habe Wechseljahresbeschwerden (Frauen)',
            gender: [GENDER.male, GENDER.female],
            description: 'Hitzewallungen, Stimmungsschwankungen.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Ich habe Probleme beim Wasserlassen',
            gender: [GENDER.male, GENDER.female],
            description: 'Nachts aufstehen (Toilette), Probleme mit Inkontinenz, andere Probleme mit dem Urogenitaltrakt.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Ich habe brüchige Nägel',
            gender: [GENDER.male, GENDER.female],
            description: 'Brüchige Nägel, Nägel splittern.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Meine Haare fallen oft aus',
            gender: [GENDER.male, GENDER.female],
            description: 'Ich habe Haarausfall.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Ich habe Probleme mit hohen Cholesterin-/Triglyceridwerten',
            gender: [GENDER.male, GENDER.female],
            description: 'Ein schlechteres Verhältnis zwischen schlechtem und gutem Cholesterin.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Ich kämpfe damit, mein Idealgewicht zu halten',
            gender: [GENDER.male, GENDER.female],
            description: 'Pfunde zunehmen, verlieren',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Ich spüre einen Mangel an Ausdauer / Kraft',
            gender: [GENDER.male, GENDER.female],
            description: 'Schlechte Kondition, Lethargie, Erschöpfung',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Ich habe schlechte Essgewohnheiten',
            gender: [GENDER.male, GENDER.female],
            description: 'Ungesunde Ernährung oder zur falschen Zeit – zu spät, Mahlzeiten auslassen.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Nach einer Krankheit erhole ich mich langsamer',
            gender: [GENDER.male, GENDER.female],
            description: 'Der Körper braucht mehr Zeit, um sich nach einer Krankheit wieder stark zu fühlen und die alltäglichen Verpflichtungen problemlos zu bewältigen',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Ich habe eine unregelmäßige / langsame Verdauung',
            gender: [GENDER.male, GENDER.female],
            description: 'Verstopfung, Darmprobleme.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Ich bin gereizt, es fällt mir schwer, mich zu entspannen',
            gender: [GENDER.male, GENDER.female],
            description: 'Gereiztheit, Ungeduld, Anspannung, alles geht uns auf die Nerven, wir werden übermäßig aufgeregt.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Ich ernähre mich nährstoffarm',
            gender: [GENDER.male, GENDER.female],
            description: 'Zu wenig Obst, Gemüse, Vollkornprodukte (weniger als 30 g pro Tag).',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Ich habe Muskelbeschwerden',
            gender: [GENDER.male, GENDER.female],
            description: 'Muskelkrämpfe, Muskelfaser, Straffung, Muskelverspannungen, Muskelknoten.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Ich habe trockenes / geschädigtes / geschwächtes Haar',
            gender: [GENDER.male, GENDER.female],
            description: 'Das Haar ist nicht gesund, glänzend und stark.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Ich bin verschmutzter Luft ausgesetzt',
            gender: [GENDER.male, GENDER.female],
            description: 'Wir sind praktisch überall verschmutzter Luft ausgesetzt.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Wenn ich mich beruhige, werde ich schläfrig',
            gender: [GENDER.male, GENDER.female],
            description: 'Sobald ich ich beruhige, fühle ich mich müde, schläfrig, bleiern im Körper.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Ich spüre Appetitlosigkeit',
            gender: [GENDER.male, GENDER.female],
            description: 'Haben Sie beim Essen oft großen Hunger?',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Ich trinke mindestens 2 Schwarztees, Kaffees oder Limonaden am Tag',
            gender: [GENDER.male, GENDER.female],
            description: 'Eine Kombination aus mindestens zwei Getränken pro Tag.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Manchmal habe ich das Gefühl, ich habe keine Kontrolle über mich selbst.',
            gender: [GENDER.male, GENDER.female],
            description: 'Das Gefühl, dass die Dinge aus dem Ruder laufen, dass mir alles zu viel wird. Ich erliege der Versuchung von Junk Food, Alkohol, Zigaretten usw.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Ich reagiere empfindlich auf bestimmte Lebensmittelinhaltsstoffe, auf Chemikalien.',
            gender: [GENDER.male, GENDER.female],
            description: 'Überempfindlichkeit auf verschiedene Reiniger, Waschpulver, Weichspüler, Seifen und Kosmetika.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Ich habe Probleme mit Pilzinfektionen / Schimmel',
            gender: [GENDER.male, GENDER.female],
            description: 'Flecken und Ausschläge auf der Haut, unangenehmes, juckendes Gefühl..',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Ich habe Probleme mit meinen Muskeln und Gelenken',
            gender: [GENDER.male, GENDER.female],
            description: 'Schlechte Beweglichkeit, Steifheit, Verspannungen, Verkalkungen, Gelenkverschleiß, Gelenkverdickung, Muskel- und/oder Gelenkschmerzen.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Manchmal mache ich mir zu viele Sorgen',
            gender: [GENDER.male, GENDER.female],
            description: 'Wir machen uns die ganze Zeit über etwas Sorgen, wir machen uns zu viele Sorgen.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Ich bin oft gereizt und wütend',
            gender: [GENDER.male, GENDER.female],
            description: 'Dinge werfen mich oft aus der Bahn, ich regen mich schnell auf.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Ich bin körperlich nicht aktiv genug',
            gender: [GENDER.male, GENDER.female],
            description: 'Weniger als 3 Mal pro Woche für mindestens 20 Minuten.',
            answers: [ANSWERS.yes, ANSWERS.no]
        },
        {
            name: 'Ich habe Probleme mit der Nasenschleimhaut',
            gender: [GENDER.male, GENDER.female],
            description: 'Übermäßige Schleimabsonderung, trockene Nasenschleimhaut, Schleimhautgeschwüre, Nebenhöhlenprobleme.',
            answers: [ANSWERS.yes, ANSWERS.no]
        }
    ],
}


