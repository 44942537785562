import {useSpring, animated} from 'react-spring'
import {createRef, useCallback, useEffect, useState} from "react";
import {Redirect} from 'react-router'
import {useHistory} from "react-router-dom";
import {TailSpin} from "react-loader-spinner";
import {useDispatch, useSelector} from "react-redux";

import logo from '../assets/logo.png';

import '../App.css';

import {ANSWERS, getStateHeaderColor, L10N_APP_TEXTS, STATE} from "../data/data";
import {
    isRememberMeEnabled,
    loadCredentials,
    setRememberMe,
    setSignInCredentials,
    userRegister
} from "../store/actions/actions";
import Input from "../components/js/Input";
import Message, {ErrorType} from "../components/js/Message";
import colors from "../constants/colors";
import {PAGES} from "../App";
import {displayAlert, validateEmail} from "../helpers/helpers";
import {L10N} from "../constants/enums";

const base64 = require('base-64');


function Register() {

    let dispatch = useDispatch();

    const user = useSelector(state => state.reducer.user);
    const token = useSelector(state => state.reducer.userToken);

    const isRememberMeSet = useSelector(state => state.reducer.rememberMe);
    const savedCredentials = useSelector(state => state.reducer.savedCredentials);

    const l10n = useSelector(state => state.reducer.l10n);

    const [email, setEmail] = useState(null);
    const [name, setName] = useState(null);

    const [password, setPassword] = useState();
    const [_rememberMe, _setRememberMe] = useState();

    const [loginSuccessful, setLoginSuccessful] = useState();
    const [loadingSpinner, setLoadingSpinner] = useState(false);
    const [error, setError] = useState(null);

    const [selectedLanguage, setSelectedLanguage] = useState(l10n ? l10n : L10N.si.value);


    const [slideTopDown, setSlideTopDown] = useState(useSpring(
        {
            from: {transform: 'translateY(-90%)', opacity: 0},
            to: {transform: 'translateY(0)', opacity: 1},
            config: {duration: 900},
            delay: 150,
        }))

    const [fadeIn, setFadeIn] = useState(useSpring(
        {
            from: {opacity: 0},
            to: {opacity: 1},
            config: {duration: 1000},
            delay: 500,
        }))

    let history = useHistory();
    const onNavigateTo = useCallback(((path) => {
        history.push(path);
    }), [history]);


    useEffect(() => {
        dispatch(loadCredentials());
        dispatch(isRememberMeEnabled());
    }, []);


    useEffect(() => {
        setEmail(savedCredentials.email);
        setPassword(savedCredentials.password)
    }, [savedCredentials])


    useEffect(() => {
        if (isRememberMeSet) {
            _setRememberMe(isRememberMeSet);
        }
    }, [isRememberMeSet])

    useEffect(() => {
        if (loginSuccessful) {
            if (_rememberMe) {
                dispatch(setSignInCredentials(email, password, true));
            } else {
                dispatch(setSignInCredentials('', '', true));
            }
            dispatch(setRememberMe(_rememberMe));
        }
    }, [loginSuccessful]);


    const onInputChange = (value, onInputAction) => {
        setError(null);
        onInputAction(value);
    }

    const onRegister = () => {
        setLoadingSpinner(true);

        const onErrorEmailVerification = () => {
            console.log("Unauthorized");
            setError(ErrorType.EMAIL_VERIFICATION_FAIL);
            setLoadingSpinner(false);
        };
        const onErrorEmailEmpty = () => {
            console.log("Unauthorized");
            setError(ErrorType.EMAIL_VERIFICATION_EMPTY);
            setLoadingSpinner(false);
        };
        const onErrorNameEmpty = () => {
            console.log("Unauthorized");
            setError(ErrorType.NAME_VERIFICATION_EMPTY);
            setLoadingSpinner(false);
        };
        const UserAlreadyExists = () => {
            console.log("UserAlreadyExists");
            setError(ErrorType.USER_ALREADY_EXISTS);
            setLoadingSpinner(false);
        };


        // email verification, if not, return error message below input
        if (!email) {
            onErrorEmailEmpty();
            return;
        }

        let emailVerified = validateEmail(email);
        if (!emailVerified) {
            onErrorEmailVerification();
            return;
        }
        if (!name) {
            onErrorNameEmpty();
            return;
        }

        dispatch(userRegister(email, name, selectedLanguage,(res) => {
            console.log("Registering new user successfully!", res);

            onNavigateTo(PAGES.HOME);

        }, (err) => {
            console.warn("Error registering new user..", err);

            if (err.error === 'User already exists.') {
                UserAlreadyExists();
            }

        }, onErrorEmailVerification));

    };

    const renderErrorMessage = () => {
        switch (error) {
            case ErrorType.EMAIL_VERIFICATION_FAIL:
                return <Message error l10n={selectedLanguage} type={ErrorType.EMAIL_VERIFICATION_FAIL}/>
            case ErrorType.EMAIL_VERIFICATION_EMPTY:
                return <Message error l10n={selectedLanguage} type={ErrorType.EMAIL_VERIFICATION_EMPTY}/>
            case ErrorType.NAME_VERIFICATION_EMPTY:
                return <Message error l10n={selectedLanguage} type={ErrorType.NAME_VERIFICATION_EMPTY}/>
            case ErrorType.USER_ALREADY_EXISTS:
                return <Message error l10n={selectedLanguage} type={ErrorType.USER_ALREADY_EXISTS}/>
            default:
                return null;
        }
    };


    if (loginSuccessful) {
        console.log('redirect');
        return <Redirect to={PAGES.HOME} push={false}/>
    }


    const renderHeader = () => {
        return <div className={'header'} style={{backgroundColor: getStateHeaderColor(STATE.end)}}>
            <div className={'header-container-padding'}>
                <p className={'header-text'} style={{fontWeight: 'normal'}}>{L10N_APP_TEXTS[selectedLanguage].APP_TITLE_1}</p>
                <p className={'header-text'}>{L10N_APP_TEXTS[selectedLanguage].APP_TITLE_2}</p>
            </div>
            <img src={logo} alt="logo" className={'header-logo small'}/>
        </div>
    }

    const renderFooter = () => {

        return <div className={'footer'}>
            {
                <div className={'footer'}>
                    <div className={'button default'} onClick={() => {
                        onRegister()
                    }}>
                        {L10N_APP_TEXTS[selectedLanguage].REGISTER_BUTTON}
                    </div>
                </div>
            }
        </div>
    }


    const renderBody = () => {
        return (
            <div className={'flex-page-container'} style={{width: 'inherit'}}>
                <p className={'p-title fixed transparent'}>{L10N_APP_TEXTS[selectedLanguage].REGISTER_TITLE}</p>
                <div className={'form-container'}>
                    <Input register email
                           onChange={(value) => onInputChange(value, setEmail)}
                           onSubmit={() => {
                           }}
                           placeholder={L10N_APP_TEXTS[selectedLanguage].PLACEHOLDER_EMAIL} label={L10N_APP_TEXTS[selectedLanguage].LOGIN_EMAIL}
                           style={{marginBottom: '10px'}}/>
                    <Input register name
                           onChange={(value) => onInputChange(value, setName)}
                           onSubmit={() => {
                           }}
                           placeholder={L10N_APP_TEXTS[selectedLanguage].NAME} label={L10N_APP_TEXTS[selectedLanguage].REGISTER_NAME}
                           style={{marginBottom: '20px'}}/>
                    {renderErrorMessage()}
                    <p className={'p-small'} onClick={() => {
                        onNavigateTo(PAGES.LOGIN)
                    }}>{L10N_APP_TEXTS[selectedLanguage].REGISTER_ALREADY_HAVE_ACCOUNT}</p>
                    {loadingSpinner ?
                        <div style={{position: 'fixed', bottom: '50pt'}}>
                            <TailSpin
                                type="TailSpin"
                                color={colors.secondary}
                                height={42}
                                width={42}
                                style={{paddingTop: 52, paddingBottom: 45}}
                            />
                        </div>
                        :
                        renderFooter()
                    }
                </div>
            </div>
        )
    }

    const renderRegisterPage = () => {
        return <animated.div style={fadeIn} className={'listings-page'}>
            {renderHeader()}
            {renderBody()}
        </animated.div>
    }


    return (
        renderRegisterPage()
    );
}


export default Register;