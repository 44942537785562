export const USER_ROLES = {
    ADMIN: 0,
};

export const USER_GROUP_TYPE = {
    ALL: 'ALL',
    ADMIN: 'ADMIN',
};

export const SESSION_STORAGE_KEYS = {
    USER: '_synergy_user',
    USER_TOKEN: '_synergy_user_token',
    REMEMBER_EMAIL: '_synergy_saved_email',
    REMEMBER_PASSWORD: '_synergy_saved_password',
    REMEMBER: 'synergy_remember_me_sign_in',
    L10N: 'synergy_l10n_key',
};

export const PRINT_EMAIL_RECIPIENTS = {
    PRINT_ADDRESS: 'hiti.tadej@gmail.com'
}

export const LOCAL_STORAGE_KEYS = {
    CREDENTIALS: 'CREDENTIALS'
}

export const L10N = {
    si: {label: 'Slovenščina', value: 'SI'},
    hr: {label: 'Hrvaščina', value: 'HR'},
    en: {label: 'Angleščina (UK)', value: 'EN'},
    at: {label: 'Nemščina (AUT)', value: 'AT'},
    de: {label: 'Nemščina (DE)', value: 'DE'},
    it: {label: 'Italijanščina', value: 'IT'},
    us: {label: 'Angleščina (USA)', value: 'US'},
}
