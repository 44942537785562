import {useSpring, animated} from 'react-spring'
import React, {createRef, useCallback, useEffect, useState} from "react";
import Collapsible from 'react-collapsible';
import {Redirect} from 'react-router'
import {useHistory} from "react-router-dom";
import {TailSpin} from "react-loader-spinner";
import {useDispatch, useSelector} from "react-redux";

import logo from '../assets/logo.png';

import '../App.css';

import {ANSWERS, getStateHeaderColor, L10N_APP_TEXTS, STATE} from "../data/data";
import {
    setSignInCredentials, updateUserPassword, updateUserData,
    userRegister, setUserToken, setUserData, setL10N, loadLocalL10N, getStatsForUser
} from "../store/actions/actions";
import Input from "../components/js/Input";
import Message, {ErrorType} from "../components/js/Message";
import colors from "../constants/colors";
import {PAGES} from "../App";
import {FiCopy} from 'react-icons/fi';
import {displayAlert} from "../helpers/helpers";
import {L10N} from "../constants/enums";

import countryIT from "../assets/img/country_it.png";
import countryUS from "../assets/img/country_us.png";
import countryDE from "../assets/img/country_de.png";
import countryAT from "../assets/img/country_at.png";
import countryEN from "../assets/img/country_en.png";
import countrySI from "../assets/img/country_si.png";
import countryHR from "../assets/img/country_hr.png";
import {IoIosArrowUp, IoIosArrowDown} from 'react-icons/io';
import {BsQuestionCircleFill} from "react-icons/bs";

const base64 = require('base-64');

export const parseSelectedCountryLabel = (country, selectAction, onActionCollapseL10NOptions) => {
    const action = () => {
        selectAction(country)
        onActionCollapseL10NOptions()
    }

    switch (country) {
        case L10N.en.value:
            return <div onClick={() => action()}
                        className={'country-label-container'}
                        style={{alignSelf: 'center'}}>
                <img src={countryEN} alt="EN" width={'27px'}/>
            </div>
        case L10N.us.value:
            return <div onClick={() => action()}
                        className={'country-label-container'}
                        style={{alignSelf: 'center'}}>
                <img src={countryUS} alt="US" width={'27px'}/>
            </div>
        case L10N.it.value:
            return <div onClick={() => action()}
                        className={'country-label-container'}
                        style={{alignSelf: 'center'}}>
                <img src={countryIT} alt="US" width={'27px'}/>
            </div>
        case L10N.de.value:
            return <div onClick={() => action()}
                        className={'country-label-container'}
                        style={{alignSelf: 'center'}}>
                <img src={countryDE} alt="US" width={'27px'}/>
            </div>
        case L10N.at.value:
            return <div onClick={() => action()}
                        className={'country-label-container'}
                        style={{alignSelf: 'center'}}>
                <img src={countryAT} alt="US" width={'27px'}/>
            </div>
        case L10N.si.value:
            return <div onClick={() => action()}
                        className={'country-label-container'}
                        style={{alignSelf: 'center'}}>
                <img src={countrySI} alt="SI" width={'27px'}/>
            </div>
        case L10N.hr.value:
            return <div onClick={() => action()}
                        className={'country-label-container'}
                        style={{alignSelf: 'center'}}>
                <img src={countryHR} alt="HR" width={'27px'}/>
            </div>
    }
}

export const parseCountryImg = (l10n) => {
    switch (l10n) {
        case L10N.en.value:
            return countryEN;
        case L10N.us.value:
            return countryUS;
        case L10N.it.value:
            return countryIT;
        case L10N.de.value:
            return countryDE;
        case L10N.at.value:
            return countryAT;
        case L10N.si.value:
            return countrySI;
        case L10N.hr.value:
            return countryHR;
    }
}

function Home() {

    let dispatch = useDispatch();

    const user = useSelector(state => state.reducer.user);
    const token = useSelector(state => state.reducer.userToken);

    const l10n = useSelector(state => state.reducer.l10n);

    const [email, setEmail] = useState();
    const [referralID, setReferralID] = useState();
    const [storeLocale, setStoreLocale] = useState();
    const [promoUrl, setPromoUrl] = useState(window.location.host?.toString());
    const [copied, setCopied] = useState(false);
    const [saveData, setSaveData] = useState(false);

    const [loginSuccessful, setLoginSuccessful] = useState();
    const [loadingSpinner, setLoadingSpinner] = useState(false);
    const [error, setError] = useState(null);

    const [isL10NOptionsOpen, setIsL10NOptionsOpen] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState(l10n ? l10n : L10N.si.value);

    const [stats, setStats] = useState([]);
    const [onClickSupport, setOnClickSupport] = useState(false);
    const [onClickSupportHide, setOnClickSupportHide] = useState(true);

    const [fadeIn, setFadeIn] = useState(useSpring(
        {
            from: {opacity: 0},
            to: {opacity: 1},
            config: {duration: 1000},
            delay: 500,
        }))

    const [slideLeft, setSlideLeft] = useState(useSpring(
        {
            from: {transform: onClickSupport ? 'translateX(-90%)' : 'translateX(-90%)', width: onClickSupport ? 0 : 0, opacity: onClickSupport ? 0 : 0},
            to: {transform: onClickSupport ? 'translateX(0%)' : 'translateX(0%)', width: onClickSupport ? L10N_APP_TEXTS[selectedLanguage].NEED_SUPPORT.length * 8.5 < 130 ? 130 : L10N_APP_TEXTS[selectedLanguage].NEED_SUPPORT.length * 8.5 : 0, opacity: onClickSupport ? 1 : 0},
            config: {duration: 220},
        }))


    let history = useHistory();
    const onNavigateTo = useCallback(((path) => {
        history.push(path);
    }), [history]);

    useEffect(() => {
        if (l10n) {
            setSelectedLanguage(l10n ? l10n : L10N.si.value);
        }
    }, [l10n])

    useEffect(() => {
        setLoadingSpinner(true);
        dispatch(loadLocalL10N(true));

        if (user) {
            setEmail(user.username);

            if (user.referral_id) {
                setReferralID(user.referral_id);
                setPromoUrl(window.location?.host.toString() + PAGES.ANALYSIS + "/referralID=" + user.referral_id + "/store=" + selectedLanguage)
            }
            if (user.store_locale) {
                setStoreLocale(user.store_locale);
            }
            console.log("USER:", user);

            dispatch(getStatsForUser(user.user_id, (res) => {
                console.log("stats data:", res);
                setStats(res);

                setLoadingSpinner(false);

            }, () => {
                console.warn("Error registering new user..")
                setLoadingSpinner(false);
            }, () => {
                console.warn("Error registering new user..")
                setLoadingSpinner(false);
            }));


        }
    }, [user])


    useEffect(() => {
        if (user) {
            if (saveData) {
                //setSaveData(false);

                // onSaveUserData();
            }
            console.log("save data trigger:", false);
        }
    }, [saveData])


    useEffect(() => {
        if (copied) {
            setTimeout(() => {
                setCopied(false)
            }, 3300)
        }
    }, [copied]);

    useEffect(() => {
        if (!onClickSupport) {
            setTimeout(() => {
                setOnClickSupportHide(true);
            }, 100)
        } else {
            setOnClickSupportHide(false);
        }
    }, [onClickSupport]);

    const displayCollapsibleL10NHeader = (status) => {
        let arrowStyle = {width: '24px', height: '24px', color: colors.secondary};
        return (
            <div className={'collapsible-header l10n-options'} style={{justifyContent: 'flex-end'}}>
                {parseSelectedCountryLabel(selectedLanguage, setSelectedLanguage, () => onActionCollapseL10NOptions(false))}
                {status ? <IoIosArrowUp style={arrowStyle}/> : <IoIosArrowDown style={arrowStyle}/>}
            </div>
        );
    }

    const onActionCollapseL10NOptions = (state) => {
        setIsL10NOptionsOpen(state);
    }

    const openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }

    const onInputChange = (value, onInputAction) => {
        setError(null);
        onInputAction(value);
    }

    const onSaveUserData = () => {
        setLoadingSpinner(true);
        const onSuccessLogin = () => {
            console.log('redirect success');
            setLoginSuccessful(true);
            setLoadingSpinner(false);
        };
        const UserAccessNotGranted = () => {
            console.log("Error fetching data");
            setError(ErrorType.USER_ACCESS_NOT_GRANTED);
            setLoadingSpinner(false);
        };
        const onErrorUnauthorized = () => {
            console.log("Unauthorized");
            setError(ErrorType.UNAUTHORIZED);
            setLoadingSpinner(false);
        };

        dispatch(updateUserData(user.user_id, user.name, user.username, referralID, storeLocale, (res) => {
            console.log("Updating user successfully!", res);

            displayAlert(L10N_APP_TEXTS[selectedLanguage].DATA_UPDATED)

            setSaveData(false);
            setLoadingSpinner(false);

        }, () => {
            console.warn("Error registering new user..")
            setLoadingSpinner(false);
        }, onErrorUnauthorized));

        //dispatch(userLogin(email, password, onSuccessLogin, UserAccessNotGranted, onErrorUnauthorized));

    };

    const renderErrorMessage = () => {
        switch (error) {
            case ErrorType.UNAUTHORIZED:
                return <Message error l10n={selectedLanguage} type={ErrorType.UNAUTHORIZED}/>
            /*case ErrorType.ERROR_FETCHING_DATA:
                return <Message error type={ErrorType.ERROR_FETCHING_DATA}/>

             */
            case ErrorType.USER_ACCESS_NOT_GRANTED:
                return <Message error l10n={selectedLanguage} type={ErrorType.USER_ACCESS_NOT_GRANTED}/>
            default:
                return null;
        }
    };


    const onCopyPromoUrl = () => {
        setCopied(true);

        navigator.clipboard.writeText(promoUrl);
        console.log("copied", promoUrl);
    };


    const onResetPassword = () => {

        dispatch(updateUserPassword(user.user_id, (res) => {
            console.log("Updating user's password successfully!", res);

            displayAlert(L10N_APP_TEXTS[selectedLanguage].DATA_UPDATED)

            dispatch(setUserToken(null));
            dispatch(setUserData(null));
            dispatch(setSignInCredentials(null));
            setLoadingSpinner(false);


            onNavigateTo(PAGES.LOGIN);


        }, () => {
            console.warn("Error registering new user..")
            setLoadingSpinner(false);
        }, () => {
            setLoadingSpinner(false);
        }));
    };

    const renderL10NOption = (l10nKey, imgData) => {
        return (
            <div onClick={() => {
                onActionCollapseL10NOptions(false)
                setSelectedLanguage(l10nKey)
                dispatch(setL10N(l10nKey, true))

                setPromoUrl(window.location?.host.toString() + PAGES.ANALYSIS + "/referralID=" + user.referral_id + "/store=" + l10nKey)
            }}
                 className={'country-label-container'}
                 style={{alignSelf: 'center'}}>
                <img src={imgData} alt={l10nKey} width={'27px'}/>
            </div>
        );
    }

    const renderL10NOptions = () => {
        const showOtherAvailableL10N = (selectedLanguage) => {

            renderL10NOption(L10N.us.value, countryUS);
            renderL10NOption(L10N.en.value, countryEN);
            renderL10NOption(L10N.de.value, countryDE);
            renderL10NOption(L10N.at.value, countryAT);
            renderL10NOption(L10N.it.value, countryIT);
            renderL10NOption(L10N.si.value, countrySI);
            renderL10NOption(L10N.hr.value, countryHR);

            switch (selectedLanguage) {
                case L10N.hr:
                    renderL10NOption(L10N.us.value, countryUS);
                    renderL10NOption(L10N.en.value, countryEN);
                    renderL10NOption(L10N.de.value, countryDE);
                    renderL10NOption(L10N.at.value, countryAT);
                    renderL10NOption(L10N.it.value, countryIT);
                    renderL10NOption(L10N.si.value, countrySI);
                    break;

                case L10N.si:
                    renderL10NOption(L10N.us.value, countryUS);
                    renderL10NOption(L10N.en.value, countryEN);
                    renderL10NOption(L10N.de.value, countryDE);
                    renderL10NOption(L10N.at.value, countryAT);
                    renderL10NOption(L10N.it.value, countryIT);
                    renderL10NOption(L10N.hr.value, countryHR);
                    break;
                case L10N.it:
                    renderL10NOption(L10N.us.value, countryUS);
                    renderL10NOption(L10N.en.value, countryEN);
                    renderL10NOption(L10N.de.value, countryDE);
                    renderL10NOption(L10N.at.value, countryAT);
                    renderL10NOption(L10N.si.value, countrySI);
                    renderL10NOption(L10N.hr.value, countryHR);
                    break;
                case L10N.de:
                    renderL10NOption(L10N.us.value, countryUS);
                    renderL10NOption(L10N.en.value, countryEN);
                    renderL10NOption(L10N.at.value, countryAT);
                    renderL10NOption(L10N.it.value, countryIT);
                    renderL10NOption(L10N.si.value, countrySI);
                    renderL10NOption(L10N.hr.value, countryHR);
                    break;
                case L10N.at:
                    renderL10NOption(L10N.us.value, countryUS);
                    renderL10NOption(L10N.en.value, countryEN);
                    renderL10NOption(L10N.de.value, countryDE);
                    renderL10NOption(L10N.it.value, countryIT);
                    renderL10NOption(L10N.si.value, countrySI);
                    renderL10NOption(L10N.hr.value, countryHR);
                    break;
                case L10N.us:
                    renderL10NOption(L10N.en.value, countryEN);
                    renderL10NOption(L10N.de.value, countryDE);
                    renderL10NOption(L10N.at.value, countryAT);
                    renderL10NOption(L10N.it.value, countryIT);
                    renderL10NOption(L10N.si.value, countrySI);
                    renderL10NOption(L10N.hr.value, countryHR);
                    break;
                case L10N.en:
                default:
                    renderL10NOption(L10N.us.value, countryUS);
                    renderL10NOption(L10N.de.value, countryDE);
                    renderL10NOption(L10N.at.value, countryAT);
                    renderL10NOption(L10N.it.value, countryIT);
                    renderL10NOption(L10N.si.value, countrySI);
                    renderL10NOption(L10N.hr.value, countryHR);
                    break;
            }
        };

        return (
            <Collapsible className={'l10n-options'}
                         transitionTime={400}
                         onClosing={() => onActionCollapseL10NOptions(false)}
                         onOpening={() => onActionCollapseL10NOptions(true)}
                         open={isL10NOptionsOpen}
                         easing={'cubic-bezier(0.2, 0.94, 0.86, 1.1) 0s'}
                         trigger={displayCollapsibleL10NHeader(false)}
                         triggerWhenOpen={displayCollapsibleL10NHeader(true)}>
                <div className={'l10n-options-content'}>
                    {showOtherAvailableL10N(selectedLanguage)}
                    {selectedLanguage !== L10N.us.value && renderL10NOption(L10N.us.value, countryUS)}
                    {selectedLanguage !== L10N.en.value && renderL10NOption(L10N.en.value, countryEN)}
                    {selectedLanguage !== L10N.de.value && renderL10NOption(L10N.de.value, countryDE)}
                    {selectedLanguage !== L10N.at.value && renderL10NOption(L10N.at.value, countryAT)}
                    {selectedLanguage !== L10N.it.value && renderL10NOption(L10N.it.value, countryIT)}
                    {selectedLanguage !== L10N.si.value && renderL10NOption(L10N.si.value, countrySI)}
                    {selectedLanguage !== L10N.hr.value && renderL10NOption(L10N.hr.value, countryHR)}
                </div>
            </Collapsible>
        );
    }

    const renderStatsSection = () => {
        const renderStatHeader = () => {
            return (
                <p className={'p-stats-header'}>
                    {L10N_APP_TEXTS[selectedLanguage].STATS}
                </p>
            );
        }
        const StatsRow = (props) => {
            let item = props.value;

            return (
                <div className={'stats-row'} style={{opacity: !item?.completed ? 0.2 : 1}}>
                    <div className={'stats-col'} style={{marginTop: '5px'}}>
                        <img src={parseCountryImg(item.l10n)} width={'27px'} alt={'country_img'}/>
                    </div>
                    <div className={'stats-col'}>
                        <p className={'p-stats'}>{item?.completed}</p>
                    </div>
                    <div className={'stats-col'}>
                        <p className={'p-stats'}>{item?.shared_back}</p>
                    </div>
                    <div className={'stats-col'}>
                        <p className={'p-stats'}>{!item?.completed ? 0 : (item?.shared_back / item?.completed) * 100}%</p>
                    </div>
                </div>
            );
        }
        const renderStatsLegend = () => {
            return (
                <div className={'stats-row legend'}>
                    <div className={'stats-col'}>

                    </div>
                    <div className={'stats-col'}>
                        <p className={'p-stats'}>{L10N_APP_TEXTS[selectedLanguage].COMPLETED}</p>
                    </div>
                    <div className={'stats-col'}>
                        <p className={'p-stats'}>{L10N_APP_TEXTS[selectedLanguage].SHARED_BACK}</p>
                    </div>
                    <div className={'stats-col'}>
                        <p className={'p-stats'}>{L10N_APP_TEXTS[selectedLanguage].SHARED_PERCENTAGE} %</p>
                    </div>
                </div>
            );
        };

        if (!stats || stats?.length <= 0) {
            return <></>;
        }
        return (
            <div className={'stats-container'}>
                {renderStatHeader()}
                {renderStatsLegend()}
                {stats.map((item, index) => <StatsRow key={index} index={index} value={item}/>)}
            </div>
        );
    }

    const renderHeader = () => {
        return <div className={'header'} style={{backgroundColor: getStateHeaderColor(STATE.end)}}>
            <div className={'header-container-padding'}>
                <p className={'header-text'}
                   style={{fontWeight: 'normal'}}>{L10N_APP_TEXTS[selectedLanguage].APP_TITLE_1}</p>
                <p className={'header-text'}>{L10N_APP_TEXTS[selectedLanguage].APP_TITLE_2}</p>
            </div>
            <img src={logo} alt="logo" className={'header-logo small'}/>
        </div>
    }

    const renderFooter = () => {
        if (!saveData) {
            return (
                <>
                    <div className={'footer'} style={{
                        justifyContent: 'flex-start',
                        bottom: '3vh',
                        left: '3vw'
                    }}>
                        <div className={'support-btn'}>
                            <div onClick={() => {
                                console.log("idiot");
                                setOnClickSupport(!onClickSupport);
                            }}>
                                <BsQuestionCircleFill className={'icon'} style={{
                                    color: 'rgb(87 87 87 / 20%)',
                                    width: '34px',
                                    height: '34px',
                                    position: 'absolute',
                                    bottom: '2px',
                                    left: '-1px',
                                    zIndex: '998'
                                }} title={'icon copy'}/>
                                <BsQuestionCircleFill className={'icon'} style={{
                                    color: colors.primary,
                                    width: '32px',
                                    height: '32px',
                                    position: 'sticky',
                                    zIndex: 999
                                }} title={'icon copy'}/>
                            </div>

                            <animated.div style={slideLeft} className={'support-animated'}>
                                    <a href={'mailto:info@checklifestyle.online'} style={{
                                        textDecoration: 'none',
                                        display: onClickSupportHide ? 'none' : 'flex'
                                    }}>
                                        <p className={'p-small'} style={{
                                            fontSize: '0.85em',
                                            margin: 0,
                                            paddingLeft: '20px',
                                            paddingRight: '15px',
                                            paddingTop: '2px',
                                            minWidth: 'max-content',
                                            color: "white",
                                            textDecoration: 'none'
                                        }}>{L10N_APP_TEXTS[selectedLanguage].NEED_SUPPORT}</p>
                                    </a>
                            </animated.div>
                        </div>
                    </div>
                </>
            )
        }
        return <div className={'footer'}>
            {
                <div className={'footer'}>
                    <div className={'button default'} onClick={() => {
                        onSaveUserData()
                    }}>
                        {L10N_APP_TEXTS[selectedLanguage].SAVE}
                    </div>
                </div>
            }
        </div>
    }


    const renderBody = () => {
        return (
            <>
                <div className={'flex-page-container'} style={{width: 'inherit', paddingBottom: '10vh'}}>
                    {renderL10NOptions()}

                    <p className={'p-title fixed'}>{L10N_APP_TEXTS[selectedLanguage].HOME_WELCOME} {user.name}</p>
                    <div className={'form-container'}>
                        <Input user email={email}
                               disabled
                               onChange={(value) => onInputChange(value, setEmail)}
                               onSubmit={() => {
                               }}
                               placeholder={L10N_APP_TEXTS[selectedLanguage].PLACEHOLDER_EMAIL}
                               label={L10N_APP_TEXTS[selectedLanguage].YOUR_USERNAME_TITLE}
                               style={{marginBottom: '25px'}}/>
                        <Input user referralId={referralID}
                               onChange={(value) => {
                                   onInputChange(value, setReferralID);
                                   setSaveData(true);
                                   //onSaveUserData();
                               }} onSubmit={onSaveUserData}

                               onBlur={(value) => {
                                   setSaveData(true);
                               }}
                               placeholder={'Referral ID'} label={L10N_APP_TEXTS[selectedLanguage].YOUR_REFERRAL_ID}
                               style={{marginBottom: '0px'}}/>

                        <div style={{flexDirection: 'row', display: 'flex', width: '100%', alignItems: 'flex-end'}}>
                            <Input user promoUrl={promoUrl}
                                   disabled
                                   value={promoUrl}
                                   placeholder={'Promo url'} label={L10N_APP_TEXTS[selectedLanguage].YOUR_ANALYSIS_URL}
                                   style={{
                                       marginBottom: '0px',
                                       fontWeight: 'bold',
                                       fontSize: '0.88em'
                                   }}/>
                            <div style={{display: 'flex'}} onClick={() => {
                                onCopyPromoUrl()
                            }}>
                                <FiCopy className={'icon'} style={{
                                    color: colors.primary,
                                    width: '32px',
                                    height: '55px',
                                    position: 'sticky',
                                    right: '25px',
                                    marginLeft: '25px'
                                }} title={'icon copy'}/>
                            </div>

                        </div>
                        {copied && <p className={'p-alert'} onClick={() => {
                            console.log('reset password')
                        }}>{L10N_APP_TEXTS[selectedLanguage].COPIED}</p>}
                        {renderErrorMessage()}

                        {renderStatsSection()}

                        {loadingSpinner ?
                            <div style={{position: 'fixed', bottom: '50pt'}}>
                                <TailSpin
                                    type="TailSpin"
                                    color={colors.secondary}
                                    height={42}
                                    width={42}
                                    style={{paddingTop: 52, paddingBottom: 45}}
                                />
                            </div>
                            :
                            renderFooter()
                        }
                    </div>
                </div>

            </>
        )
    }

    const renderRegisterPage = () => {
        return <animated.div style={fadeIn} className={'listings-page'}>
            {renderHeader()}
            {renderBody()}
        </animated.div>
    }

    if (!user) {
        return onNavigateTo(PAGES.LOGIN);
    }

    return (
        renderRegisterPage()
    );
}


export default Home;