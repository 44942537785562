import React, {createRef, useCallback, useEffect, useState} from "react";
import {useSpring, animated} from 'react-spring'
import {Redirect} from 'react-router'
import {useHistory} from "react-router-dom";
import {TailSpin} from "react-loader-spinner";
import {useDispatch, useSelector} from "react-redux";

import logo from '../assets/logo.png';

import '../App.css';

import {ANSWERS, getStateHeaderColor, L10N_APP_TEXTS, STATE} from "../data/data";
import {
    isRememberMeEnabled,
    loadCredentials, loadLocalL10N, setL10N,
    setRememberMe,
    setSignInCredentials, setUserData, setUserToken, updateUserPassword, updateUserPasswordByEmail, userLogin,
    userRegister
} from "../store/actions/actions";
import Input from "../components/js/Input";
import Message, {ErrorType} from "../components/js/Message";
import colors from "../constants/colors";
import {PAGES} from "../App";
import {L10N} from "../constants/enums";
import {displayAlert} from "../helpers/helpers";
import countryEN from "../assets/img/country_en.png";
import countrySI from "../assets/img/country_si.png";
import countryHR from "../assets/img/country_hr.png";
import Collapsible from "react-collapsible";
import {IoIosArrowDown, IoIosArrowUp} from "react-icons/io";
import {parseSelectedCountryLabel} from "./Home";
import countryUS from "../assets/img/country_us.png";
import countryIT from "../assets/img/country_it.png";
import countryDE from "../assets/img/country_de.png";
import countryAT from "../assets/img/country_at.png";

const base64 = require('base-64');


function ResetPasswordLogin() {

    let dispatch = useDispatch();

    const l10n = useSelector(state => state.reducer.l10n);

    const isRememberMeSet = useSelector(state => state.reducer.rememberMe);
    const savedCredentials = useSelector(state => state.reducer.savedCredentials);

    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [_rememberMe, _setRememberMe] = useState();

    const [loginSuccessful, setLoginSuccessful] = useState();
    const [loadingSpinner, setLoadingSpinner] = useState(false);
    const [error, setError] = useState(null);

    const [selectedLanguage, setSelectedLanguage] = useState(l10n ? l10n : L10N.en.value);
    const [isL10NOptionsOpen, setIsL10NOptionsOpen] = useState(false);

    const [fadeIn, setFadeIn] = useState(useSpring(
        {
            from: {opacity: 0},
            to: {opacity: 1},
            config: {duration: 1000},
            delay: 500,
        }))

    let history = useHistory();
    const onNavigateTo = useCallback(((path) => {
        history.push(path);
    }), [history]);

    useEffect(() => {
        dispatch(loadLocalL10N(true));

        dispatch(loadCredentials());
        dispatch(isRememberMeEnabled());
    }, []);


    useEffect(() => {
        if (savedCredentials.email && savedCredentials.password) {
            setEmail(savedCredentials.email);
            setPassword(savedCredentials.password)
        }

    }, [savedCredentials])


    useEffect(() => {
        if (isRememberMeSet) {
            _setRememberMe(isRememberMeSet);
        }
    }, [isRememberMeSet])

    useEffect(() => {
        if (loginSuccessful) {
            if (_rememberMe) {
                dispatch(setSignInCredentials(email, password, true));
            } else {
                dispatch(setSignInCredentials('', '', true));
            }
            dispatch(setRememberMe(_rememberMe));
        }
    }, [loginSuccessful]);


    const onInputChange = (value, onInputAction) => {
        setError(null);
        onInputAction(value);
    }


    const renderErrorMessage = () => {
        switch (error) {
            case ErrorType.USER_DOESNT_EXISTS:
                return <Message error l10n={selectedLanguage} type={ErrorType.USER_DOESNT_EXISTS}/>
            /*case ErrorType.ERROR_FETCHING_DATA:
                return <Message error type={ErrorType.ERROR_FETCHING_DATA}/>

             */
            case ErrorType.USER_ACCESS_NOT_GRANTED:
                return <Message error l10n={selectedLanguage} type={ErrorType.USER_ACCESS_NOT_GRANTED}/>
            default:
                return null;
        }
    };

    const onResetPassword = () => {
        const onSuccess = () => {
            console.log('redirect success');
            setLoadingSpinner(false);

            displayAlert(L10N_APP_TEXTS[selectedLanguage].PASSWORD_RESET_SUCCESS)

            dispatch(setUserToken(null));
            dispatch(setUserData(null));
            dispatch(setSignInCredentials(null));
            setLoadingSpinner(false);

            onNavigateTo(PAGES.LOGIN);
        };
        const UserAccessNotGranted = () => {
            console.log("Error fetching data");
            setError(ErrorType.USER_DOESNT_EXISTS);
            setLoadingSpinner(false);
        };

        dispatch(updateUserPasswordByEmail(email, selectedLanguage,(res) => {
            onSuccess();

        }, () => {
            UserAccessNotGranted()
        }, () => {
            UserAccessNotGranted()
        }));
    };


    const displayCollapsibleL10NHeader = (status) => {
        let arrowStyle = {width: '24px', height: '24px', color: colors.secondary};
        return (
            <div className={'collapsible-header l10n-options'} style={{justifyContent: 'flex-end'}}>
                {parseSelectedCountryLabel(selectedLanguage, setSelectedLanguage, () => onActionCollapseL10NOptions(false))}
                {status ? <IoIosArrowUp style={arrowStyle}/> : <IoIosArrowDown style={arrowStyle}/>}
            </div>
        );
    }

    const onActionCollapseL10NOptions = (state) => {
        setIsL10NOptionsOpen(state);
    }

    const renderL10NOption = (l10nKey, imgData) => {
        return (
            <div onClick={() => {
                onActionCollapseL10NOptions(false)
                setSelectedLanguage(l10nKey)
                dispatch(setL10N(l10nKey, true))

                //   setPromoUrl(window.location?.host.toString() + PAGES.ANALYSIS + "/referralID=" + user.referral_id + "/store=" + l10nKey)
            }}
                 className={'country-label-container'}
                 style={{alignSelf: 'center'}}>
                <img src={imgData} alt={l10nKey} width={'27px'}/>
            </div>
        );
    }

    const renderL10NOptions = () => {
        const showOtherAvailableL10N = (selectedLanguage) => {

            renderL10NOption(L10N.us.value, countryUS);
            renderL10NOption(L10N.en.value, countryEN);
            renderL10NOption(L10N.de.value, countryDE);
            renderL10NOption(L10N.at.value, countryAT);
            renderL10NOption(L10N.it.value, countryIT);
            renderL10NOption(L10N.si.value, countrySI);
            renderL10NOption(L10N.hr.value, countryHR);

            switch (selectedLanguage) {
                case L10N.hr:
                    renderL10NOption(L10N.us.value, countryUS);
                    renderL10NOption(L10N.en.value, countryEN);
                    renderL10NOption(L10N.de.value, countryDE);
                    renderL10NOption(L10N.at.value, countryAT);
                    renderL10NOption(L10N.it.value, countryIT);
                    renderL10NOption(L10N.si.value, countrySI);
                    break;

                case L10N.si:
                    renderL10NOption(L10N.us.value, countryUS);
                    renderL10NOption(L10N.en.value, countryEN);
                    renderL10NOption(L10N.de.value, countryDE);
                    renderL10NOption(L10N.at.value, countryAT);
                    renderL10NOption(L10N.it.value, countryIT);
                    renderL10NOption(L10N.hr.value, countryHR);
                    break;
                case L10N.it:
                    renderL10NOption(L10N.us.value, countryUS);
                    renderL10NOption(L10N.en.value, countryEN);
                    renderL10NOption(L10N.de.value, countryDE);
                    renderL10NOption(L10N.at.value, countryAT);
                    renderL10NOption(L10N.si.value, countrySI);
                    renderL10NOption(L10N.hr.value, countryHR);
                    break;
                case L10N.de:
                    renderL10NOption(L10N.us.value, countryUS);
                    renderL10NOption(L10N.en.value, countryEN);
                    renderL10NOption(L10N.at.value, countryAT);
                    renderL10NOption(L10N.it.value, countryIT);
                    renderL10NOption(L10N.si.value, countrySI);
                    renderL10NOption(L10N.hr.value, countryHR);
                    break;
                case L10N.at:
                    renderL10NOption(L10N.us.value, countryUS);
                    renderL10NOption(L10N.en.value, countryEN);
                    renderL10NOption(L10N.de.value, countryDE);
                    renderL10NOption(L10N.it.value, countryIT);
                    renderL10NOption(L10N.si.value, countrySI);
                    renderL10NOption(L10N.hr.value, countryHR);
                    break;
                case L10N.us:
                    renderL10NOption(L10N.en.value, countryEN);
                    renderL10NOption(L10N.de.value, countryDE);
                    renderL10NOption(L10N.at.value, countryAT);
                    renderL10NOption(L10N.it.value, countryIT);
                    renderL10NOption(L10N.si.value, countrySI);
                    renderL10NOption(L10N.hr.value, countryHR);
                    break;
                case L10N.en:
                default:
                    renderL10NOption(L10N.us.value, countryUS);
                    renderL10NOption(L10N.de.value, countryDE);
                    renderL10NOption(L10N.at.value, countryAT);
                    renderL10NOption(L10N.it.value, countryIT);
                    renderL10NOption(L10N.si.value, countrySI);
                    renderL10NOption(L10N.hr.value, countryHR);
                    break;
            }
        };

        return (
            <Collapsible className={'l10n-options'}
                         transitionTime={400}
                         onClosing={() => onActionCollapseL10NOptions(false)}
                         onOpening={() => onActionCollapseL10NOptions(true)}
                         open={isL10NOptionsOpen}
                         easing={'cubic-bezier(0.2, 0.94, 0.86, 1.1) 0s'}
                         trigger={displayCollapsibleL10NHeader(false)}
                         triggerWhenOpen={displayCollapsibleL10NHeader(true)}>
                <div className={'l10n-options-content'}>
                    {showOtherAvailableL10N(selectedLanguage)}
                    {selectedLanguage !== L10N.us.value && renderL10NOption(L10N.us.value, countryUS)}
                    {selectedLanguage !== L10N.en.value && renderL10NOption(L10N.en.value, countryEN)}
                    {selectedLanguage !== L10N.de.value && renderL10NOption(L10N.de.value, countryDE)}
                    {selectedLanguage !== L10N.at.value && renderL10NOption(L10N.at.value, countryAT)}
                    {selectedLanguage !== L10N.it.value && renderL10NOption(L10N.it.value, countryIT)}
                    {selectedLanguage !== L10N.si.value && renderL10NOption(L10N.si.value, countrySI)}
                    {selectedLanguage !== L10N.hr.value && renderL10NOption(L10N.hr.value, countryHR)}
                </div>
            </Collapsible>
        );
    }

    const renderHeader = () => {
        return <div className={'header'} style={{backgroundColor: getStateHeaderColor(STATE.end)}}>
            <div className={'header-container-padding'}>
                <p className={'header-text'}
                   style={{fontWeight: 'normal'}}>{L10N_APP_TEXTS[selectedLanguage].APP_TITLE_1}</p>
                <p className={'header-text'}>{L10N_APP_TEXTS[selectedLanguage].APP_TITLE_2}</p>
            </div>
            <img src={logo} alt="logo" className={'header-logo small'}/>
        </div>
    }


    const renderFooter = () => {

        return <div className={'footer'}>
            {
                <div className={'footer'}>
                    <div className={'button default'} onClick={() => {
                        onResetPassword()
                    }}>
                        {L10N_APP_TEXTS[selectedLanguage].RESET_PASSWORD}
                    </div>
                </div>
            }
        </div>
    }

    const renderBody = () => {
        return (
            <div className={'flex-page-container'} style={{width: 'inherit'}}>
                {renderL10NOptions()}

                <p className={'p-title fixed transparent'}>{L10N_APP_TEXTS[selectedLanguage].RESET_PASSWORD_TITLE}</p>
                <div className={'form-container'}>
                    <Input login email={email}
                           loadLoginCredentials
                           onChange={(value) => onInputChange(value, setEmail)}
                           onSubmit={() => {
                           }}
                           placeholder={L10N_APP_TEXTS[selectedLanguage].PLACEHOLDER_EMAIL} label={L10N_APP_TEXTS[selectedLanguage].LOGIN_EMAIL}
                           style={{marginBottom: '10px'}}/>

                    {renderErrorMessage()}

                    <p className={'p-small'} onClick={() => {
                        onNavigateTo(PAGES.LOGIN)
                    }}>{L10N_APP_TEXTS[selectedLanguage].REGISTER_ALREADY_HAVE_ACCOUNT}</p>

                    {loadingSpinner ?
                        <div style={{position: 'fixed', bottom: '50pt'}}>
                            <TailSpin
                                type="TailSpin"
                                color={colors.secondary}
                                height={42}
                                width={42}
                                style={{paddingTop: 52, paddingBottom: 45}}
                            />
                        </div>
                        :
                        renderFooter()
                    }
                </div>
            </div>
        )
    }

    const renderPage = () => {
        return <animated.div style={fadeIn} className={'listings-page'}>
            {renderHeader()}
            {renderBody()}
        </animated.div>
    }

    if (loginSuccessful) {
        console.log('redirect');

        onNavigateTo(PAGES.HOME);
        return <></>
    }

    return (
        renderPage()
    );
}


export default ResetPasswordLogin;