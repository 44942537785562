import React, {useEffect, useState} from "react";

import '../css/Input.css';


function Input(props) {


    const [input, setInput] = useState('');

    useEffect(() => {
        if (props.user && props.email) {
            setInput(props.email);
        }
        if (props.user && props.referralId) {
            setInput(props.referralId);
        }
        if (props.user && props.storeLocale) {
            setInput(props.storeLocale);
        }
        if (props.user && props.promoUrl) {
            setInput(props.promoUrl);
        }
    }, [props.user, props.email, props.storeLocale, props.referralId, props.promoUrl])

    useEffect(() => {
        if (props.login && props.email) {
            setInput(props.email);
        }
        if (props.login && props.password) {
            setInput(props.password);
        }
    }, [props.login, props.email, props.password])

    const onActionSubmit = (event) => {
        if (props.onSubmit) {
            event.preventDefault();
            props.onSubmit();
        }
    };

    const onInputChange = (value) => {
        const {onChange} = props;

        setInput(value);
        onChange(value);
    }

    let className = 'input-default';
    if (props.white) {
        className += ' input-white';
    }
    if (props.login) {
        className += ' input-login';
    }
    if (props.loadUserActivation) {
        className += ' input-loadUserActivation'
    }
    if (props.shadow) {
        className += ' input-shadow';
    }
    if (props.textarea) {
        className += ' input-textarea';
    }
    if (props.price) {
        className += ' input-price';
    }

    let type = 'text';
    if (props.password) {
        type = 'password';
    } else if (props.email) {
        type = 'email';
    }
    let inputContent = () => {
        if (props.textarea) {
            return <textarea
                disabled={props.disabled}
                className={className}
                placeholder={props.placeholder}
                style={props.style}
                value={input ? input : ''} onBlur={props.onBlur}
                onChange={(event) => onInputChange(event.target.value)}/>
        } else {
            return <input disabled={props.disabled} type={type} className={className}
                          placeholder={props.placeholder}
                          style={props.style}
                          value={input ? input : ''}
                          onBlur={props.onBlur}
                          name={props.login && props.email ? "email" : ""}
                          autoComplete={props.login && props.password ? "new-password" : props.login && props.email ? "email" : "off"}
                          onChange={(event) => onInputChange(event.target.value)}/>
        }
    };

    return (
        <form onSubmit={onActionSubmit}>
            <label className={'input-label'}>
                {props.label &&
                <p className={'p-title-small'}>{props.label}</p>
                }
                {inputContent()}
            </label>
        </form>
    );
}

export default Input;